/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Account
// ====================================================

export interface Account_account_entity_config {
  __typename: "EntityConfig";
  /**
   * The inspection_start_time of the entity
   */
  inspectionStartTime: string | null;
  /**
   * The snapshot_import_group_required of the entity
   */
  snapshotImportGroupRequired: boolean | null;
  /**
   * The dealer_unique_identifier of the entity
   */
  dealerUniqueIdentifier: string | null;
  /**
   * The asset_unique_identifier of the entity
   */
  assetUniqueIdentifier: string | null;
  /**
   * The snapshot_import_make_type_required of the entity
   */
  snapshotImportMakeTypeRequired: boolean | null;
  /**
   * The snapshot_importer_version of the entity
   */
  snapshotImporterVersion: string | null;
  /**
   * The snapshot_searchable_meta_fields of the entity
   */
  snapshotSearchableMetaFields: (string | null)[] | null;
  /**
   * The snapshot_importer_inspection_type of the entity
   */
  snapshotImporterInspectionType: number | null;
  /**
   * The default_account_manager_filter of the entity
   */
  defaultAccountManagerFilter: boolean | null;
}

export interface Account_account_entity_availableRoles {
  __typename: "Role";
  /**
   * The id of the role
   */
  id: string;
  /**
   * The name of the role
   */
  name: string;
}

export interface Account_account_entity {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
  /**
   * The config of the entity
   */
  config: Account_account_entity_config | null;
  /**
   * The available roles of an entity
   */
  availableRoles: Account_account_entity_availableRoles[] | null;
}

export interface Account_account_canSwitchToEntities {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
}

export interface Account_account_culture_currency {
  __typename: "Currency";
  /**
   * The ISO 4217 currency code
   */
  code: string;
  /**
   * The conversion rate from base currency EUR
   */
  rateFromEUR: number;
}

export interface Account_account_culture {
  __typename: "Culture";
  /**
   * The thousand separator used in numeric notations
   */
  thousandSeparator: string;
  /**
   * The decimal separator used in numeric notations
   */
  decimalSeparator: string;
  /**
   * The desired date notation
   */
  dateNotation: string;
  /**
   * The desired time notation
   */
  timeNotation: string;
  /**
   * The system of measurement
   */
  systemOfMeasurement: SystemOfMeasurementEnum;
  /**
   * The currency
   */
  currency: Account_account_culture_currency;
}

export interface Account_account {
  __typename: "Account";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The entity of the account
   */
  isAccountManager: boolean | null;
  /**
   * The entity of the account
   */
  entity: Account_account_entity | null;
  /**
   * The permissions of the account
   */
  rights: (string | null)[] | null;
  /**
   * The entities this user can switch to
   */
  canSwitchToEntities: (Account_account_canSwitchToEntities | null)[] | null;
  /**
   * The culture settings specific to the user
   */
  culture: Account_account_culture;
}

export interface Account {
  /**
   * Get account details of the user logged in
   */
  account: Account_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AddressByPlaceId
// ====================================================

export interface AddressByPlaceId_addressByPlaceId {
  __typename: "GooglePlaces";
  id: string;
  name: string | null;
  addressLine1: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
}

export interface AddressByPlaceId {
  /**
   * Address by place id
   */
  addressByPlaceId: AddressByPlaceId_addressByPlaceId | null;
}

export interface AddressByPlaceIdVariables {
  placeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchAddresses
// ====================================================

export interface SearchAddresses_searchAddresses_Address_contacts {
  __typename: "Contact";
  /**
   * The id of the contact
   */
  id: string;
  /**
   * The email address of the contact
   */
  email: string | null;
  /**
   * The first name of the contact
   */
  firstName: string | null;
  /**
   * The last name of the contact
   */
  lastName: string | null;
}

export interface SearchAddresses_searchAddresses_Address {
  __typename: "Address";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The contacts the address is attached to
   */
  contacts: (SearchAddresses_searchAddresses_Address_contacts | null)[] | null;
}

export interface SearchAddresses_searchAddresses_GooglePlaces {
  __typename: "GooglePlaces";
  id: string;
  name: string | null;
  line: string | null;
  addressLine1: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
}

export type SearchAddresses_searchAddresses = SearchAddresses_searchAddresses_Address | SearchAddresses_searchAddresses_GooglePlaces;

export interface SearchAddresses {
  /**
   * Paginate all addresses
   */
  searchAddresses: (SearchAddresses_searchAddresses | null)[] | null;
}

export interface SearchAddressesVariables {
  search?: string | null;
  type?: AddressType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllInspectionApprovalReasons
// ====================================================

export interface AllInspectionApprovalReasons_allInspectionApprovalReasons {
  __typename: "InspectionApprovalReason";
  /**
   * The id of the approval reason
   */
  id: string;
  /**
   * The approval reason
   */
  name: string | null;
}

export interface AllInspectionApprovalReasons {
  /**
   * All inspection approval reasons
   */
  allInspectionApprovalReasons: (AllInspectionApprovalReasons_allInspectionApprovalReasons | null)[] | null;
}

export interface AllInspectionApprovalReasonsVariables {
  state?: InspectionApprovalState | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Audit
// ====================================================

export interface Audit_audit_closedBy {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface Audit_audit_inspectionStatusCount {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface Audit_audit_inspections_type {
  __typename: "InspectionType";
  /**
   * The id of the inspection type
   */
  id: string;
  /**
   * The name of the inspection type
   */
  name: string;
}

export interface Audit_audit_inspections_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
}

export interface Audit_audit_inspections_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface Audit_audit_inspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The type (sort) of the inspection, for example a full inspection, commercial inspection, etc.
   */
  type: Audit_audit_inspections_type | null;
  /**
   * The asset the inspection belongs to
   */
  asset: Audit_audit_inspections_asset | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: Audit_audit_inspections_inspector | null;
}

export interface Audit_audit_dealer_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface Audit_audit_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
  /**
   * The name of the dealer
   */
  name: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (Audit_audit_dealer_meta | null)[] | null;
}

export interface Audit_audit_branch_endedAudits_closedBy {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
}

export interface Audit_audit_branch_endedAudits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The model postponed_to date
   */
  postponedTo: string | null;
  /**
   * The message of the audit
   */
  message: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The amount of accepted inspections in the audit
   */
  acceptedInspections: number | null;
  /**
   * The amount of declined inspections in the audit
   */
  declinedInspections: number | null;
  /**
   * The amount of expired inspections in the audit
   */
  expiredInspections: number | null;
  /**
   * The user who closed the audit
   */
  closedBy: Audit_audit_branch_endedAudits_closedBy | null;
}

export interface Audit_audit_branch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The external id of the branch
   */
  externalId: string | null;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The last three previous audits for this branch
   */
  endedAudits: Audit_audit_branch_endedAudits[];
}

export interface Audit_audit_notes_creator {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface Audit_audit_notes {
  __typename: "Note";
  /**
   * The id of the note
   */
  id: string;
  /**
   * The text of the note
   */
  note: string;
  /**
   * If the note is an internal note
   */
  internal: boolean;
  /**
   * The creator of the note
   */
  creator: Audit_audit_notes_creator;
  /**
   * The model created at date
   */
  createdAt: string | null;
}

export interface Audit_audit {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The status of the audit
   */
  status: AuditStatus | null;
  /**
   * Whether the audit is from a trusted party or not
   */
  trusted: boolean | null;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model postponed_to date
   */
  postponedTo: string | null;
  /**
   * The reason for postponing the audit
   */
  postponeReason: AuditPostponeReason | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The model automatically_closed_at date
   */
  automaticallyClosedAt: string | null;
  /**
   * The score of the audit
   */
  score: number | null;
  /**
   * The message of the audit
   */
  message: string | null;
  /**
   * If one of the inspections is on hold
   */
  isOnHold: boolean | null;
  /**
   * The user who closed the audit
   */
  closedBy: Audit_audit_closedBy | null;
  /**
   * The amount of inspections with distance anomalies
   */
  distanceAnomalies: number | null;
  /**
   * The amount of inspections with inconsistency anomalies
   */
  inconsistencyAnomalies: number | null;
  /**
   * The amount of inspections with picture in picture anomalies
   */
  pictureInPictureAnomalies: number | null;
  /**
   * The amount of inspections with serial number anomalies
   */
  serialNumberAnomalies: number | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
  /**
   * The amount of inspections with anomalies in the audit
   */
  inspectionsWithAnomaliesAmount: number | null;
  /**
   * The amount of submitted inspections in the audit
   */
  submittedInspections: number | null;
  /**
   * The amount of open inspections in the audit
   */
  openInspections: number | null;
  /**
   * The amount of accepted inspections in the audit
   */
  acceptedInspections: number | null;
  /**
   * The amount of declined inspections in the audit
   */
  declinedInspections: number | null;
  /**
   * The amount of expired inspections in the audit
   */
  expiredInspections: number | null;
  /**
   * The amount of inspections for a certain status
   */
  inspectionStatusCount: Audit_audit_inspectionStatusCount[];
  /**
   * The inspections of the audit
   */
  inspections: Audit_audit_inspections[];
  /**
   * The dealer of the audit
   */
  dealer: Audit_audit_dealer;
  /**
   * The branch of the audit
   */
  branch: Audit_audit_branch;
  /**
   * The notes of an audit
   */
  notes: (Audit_audit_notes | null)[] | null;
}

export interface Audit {
  /**
   * Returns a single audit
   */
  audit: Audit_audit | null;
}

export interface AuditVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AuditInspections
// ====================================================

export interface AuditInspections_paginateAuditInspections_data_checks {
  __typename: "InspectionCheck";
  /**
   * The id of the inspection warning
   */
  id: string;
  /**
   * The serialised (JSON) information about this warning
   */
  attributes: string | null;
  /**
   * The score of the inspection check
   */
  code: string | null;
  /**
   * The warning severity namespace
   */
  namespace: string;
  /**
   * The warning type
   */
  message: string;
  /**
   * The score of the inspection check
   */
  score: number | null;
  /**
   * The warning severity level
   */
  level: string;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The model updated_at date
   */
  updatedAt: string | null;
}

export interface AuditInspections_paginateAuditInspections_data_parameterValues_images_checks {
  __typename: "InspectionCheck";
  /**
   * The id of the inspection warning
   */
  id: string;
  /**
   * The warning severity namespace
   */
  namespace: string;
  /**
   * The warning severity level
   */
  level: string;
  /**
   * The warning type
   */
  message: string;
  /**
   * The serialised (JSON) information about this warning
   */
  attributes: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
}

export interface AuditInspections_paginateAuditInspections_data_parameterValues_images {
  __typename: "InspectionImage";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The URL to the thumbnail image
   */
  thumb_url: string | null;
  /**
   * The URL to the original image
   */
  original_url: string | null;
  checks: (AuditInspections_paginateAuditInspections_data_parameterValues_images_checks | null)[] | null;
}

export interface AuditInspections_paginateAuditInspections_data_parameterValues_parameter_parameterOptions {
  __typename: "ParameterOption";
  /**
   * The id of the parameter option
   */
  id: string;
  /**
   * The name of the parameter option
   */
  value: string | null;
}

export interface AuditInspections_paginateAuditInspections_data_parameterValues_parameter {
  __typename: "Parameter";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The name of the parameter
   */
  name: string | null;
  /**
   * The type of the parameter
   */
  type: string | null;
  /**
   * The metric of the parameter (KM, KG)
   */
  metric: string | null;
  /**
   * The available options for this parameter
   */
  parameterOptions: (AuditInspections_paginateAuditInspections_data_parameterValues_parameter_parameterOptions | null)[] | null;
}

export interface AuditInspections_paginateAuditInspections_data_parameterValues {
  __typename: "InspectionParameterValue";
  /**
   * The id of the parameter value
   */
  id: string;
  /**
   * The value for the parameter (attribute)
   */
  value: string | null;
  /**
   * Images attached to the parameter value
   */
  images: (AuditInspections_paginateAuditInspections_data_parameterValues_images | null)[] | null;
  /**
   * The parameter definition this value is provided for
   */
  parameter: AuditInspections_paginateAuditInspections_data_parameterValues_parameter | null;
}

export interface AuditInspections_paginateAuditInspections_data_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface AuditInspections_paginateAuditInspections_data_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (AuditInspections_paginateAuditInspections_data_asset_meta | null)[] | null;
}

export interface AuditInspections_paginateAuditInspections_data_address_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
}

export interface AuditInspections_paginateAuditInspections_data_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The entity this address is attached to
   */
  dealer: AuditInspections_paginateAuditInspections_data_address_dealer | null;
}

export interface AuditInspections_paginateAuditInspections_data_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface AuditInspections_paginateAuditInspections_data_relatedInspections_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface AuditInspections_paginateAuditInspections_data_relatedInspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The thumbnail of the inspection
   */
  thumb: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The date the inspection got assigned the status
   */
  statusDate: string | null;
  /**
   * The inspection equipment situation
   */
  equipmentSituation: EquipmentSituation | null;
  /**
   * The amount of inconsistency warnings for this inspection
   */
  warningsAmount: number | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: AuditInspections_paginateAuditInspections_data_relatedInspections_inspector | null;
}

export interface AuditInspections_paginateAuditInspections_data {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The thumbnail of the inspection
   */
  thumb: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * Shows if the inspection is trusted
   */
  trusted: boolean | null;
  /**
   * The inspection equipment situation
   */
  equipmentSituation: EquipmentSituation | null;
  /**
   * Whether the inspection has an approval remark or not
   */
  hasApprovalRemark: boolean;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model started_at date
   */
  startedAt: string | null;
  /**
   * The model finished_at date
   */
  finishedAt: string | null;
  /**
   * The model submitted_at date
   */
  submittedAt: string | null;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * The score of the inspection
   */
  score: number | null;
  /**
   * The amount of anomalies for this inspection
   */
  anomaliesAmount: number | null;
  /**
   * Inconsistency checks for this inspection
   */
  checks: AuditInspections_paginateAuditInspections_data_checks[] | null;
  /**
   * The inspection filled parameters (attributes)
   */
  parameterValues: (AuditInspections_paginateAuditInspections_data_parameterValues | null)[] | null;
  /**
   * The asset the inspection belongs to
   */
  asset: AuditInspections_paginateAuditInspections_data_asset | null;
  /**
   * The address/location of the inspection
   */
  address: AuditInspections_paginateAuditInspections_data_address | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: AuditInspections_paginateAuditInspections_data_inspector | null;
  relatedInspections: AuditInspections_paginateAuditInspections_data_relatedInspections[] | null;
}

export interface AuditInspections_paginateAuditInspections {
  __typename: "inspectionPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (AuditInspections_paginateAuditInspections_data | null)[] | null;
}

export interface AuditInspections {
  /**
   * Returns all inspections for a specific audit
   */
  paginateAuditInspections: AuditInspections_paginateAuditInspections | null;
}

export interface AuditInspectionsVariables {
  auditId: string;
  page?: number | null;
  limit?: number | null;
  status?: (InspectionStatus | null)[] | null;
  equipmentSituation?: (EquipmentSituation | null)[] | null;
  anomaly?: boolean | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AuditInspectionIds
// ====================================================

export interface AuditInspectionIds_audit_inspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
}

export interface AuditInspectionIds_audit {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The inspections of the audit
   */
  inspections: AuditInspectionIds_audit_inspections[];
}

export interface AuditInspectionIds {
  /**
   * Returns a single audit
   */
  audit: AuditInspectionIds_audit | null;
}

export interface AuditInspectionIdsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Audits
// ====================================================

export interface Audits_paginateBranches_data_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface Audits_paginateBranches_data_dealer_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface Audits_paginateBranches_data_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The name of the dealer
   */
  name: string | null;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (Audits_paginateBranches_data_dealer_meta | null)[] | null;
}

export interface Audits_paginateBranches_data_currentAudit {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The amount of open inspections in the audit
   */
  openInspections: number | null;
  /**
   * The amount of expired inspections in the audit
   */
  expiredInspections: number | null;
  /**
   * The amount of submitted inspections in the audit
   */
  submittedInspections: number | null;
}

export interface Audits_paginateBranches_data_endedAudits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The status of the audit
   */
  status: AuditStatus | null;
  /**
   * The model postponed_to date
   */
  postponedTo: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The amount of accepted inspections in the audit
   */
  acceptedInspections: number | null;
  /**
   * The amount of declined inspections in the audit
   */
  declinedInspections: number | null;
}

export interface Audits_paginateBranches_data {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The external id of the branch
   */
  externalId: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * Arbitrary information added to the address
   */
  meta: (Audits_paginateBranches_data_meta | null)[] | null;
  /**
   * The entity this address is attached to
   */
  dealer: Audits_paginateBranches_data_dealer | null;
  /**
   * Audits this year
   */
  auditsThisYearAmount: number | null;
  /**
   * Audits postponed this year
   */
  auditsPostponedThisYearAmount: number | null;
  /**
   * Audits closed this year
   */
  auditsClosedThisYearAmount: number | null;
  /**
   * Audits closed with anomalies this year
   */
  auditsClosedWithAnomaliesThisYearAmount: number | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The date of the trusted next audit
   */
  nextTrustedAudit: string | null;
  /**
   * The time in days between each trusted audit
   */
  trustedAuditFrequency: number | null;
  /**
   * The current audit for this branch
   */
  currentAudit: Audits_paginateBranches_data_currentAudit | null;
  /**
   * The last three previous audits for this branch
   */
  endedAudits: Audits_paginateBranches_data_endedAudits[];
}

export interface Audits_paginateBranches {
  __typename: "branchPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (Audits_paginateBranches_data | null)[] | null;
}

export interface Audits {
  /**
   * Returns all branches of dealers
   */
  paginateBranches: Audits_paginateBranches | null;
}

export interface AuditsVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  accountManagerIds?: (string | null)[] | null;
  hasCurrentAudit?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AuditStatistics
// ====================================================

export interface AuditStatistics_auditStatistics_byStatusByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface AuditStatistics_auditStatistics_byStatusByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (AuditStatistics_auditStatistics_byStatusByMonth_partitions | null)[];
}

export interface AuditStatistics_auditStatistics_byStatus {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface AuditStatistics_auditStatistics {
  __typename: "AuditStatisticsType";
  /**
   * The amount of branches to plan
   */
  toPlan: number;
  /**
   * The amount of audits by status by month
   */
  byStatusByMonth: (AuditStatistics_auditStatistics_byStatusByMonth | null)[];
  /**
   * The amount of audits by status
   */
  byStatus: (AuditStatistics_auditStatistics_byStatus | null)[];
}

export interface AuditStatistics_inspectionStatistics_bySituation {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface AuditStatistics_inspectionStatistics {
  __typename: "InspectionStatisticsType";
  /**
   * The amount of inspection by situation
   */
  bySituation: (AuditStatistics_inspectionStatistics_bySituation | null)[];
}

export interface AuditStatistics_paginateBranches {
  __typename: "branchPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
}

export interface AuditStatistics {
  /**
   * Returns a single audit
   */
  auditStatistics: AuditStatistics_auditStatistics | null;
  /**
   * Get paged dealers
   */
  inspectionStatistics: AuditStatistics_inspectionStatistics | null;
  /**
   * Returns all branches of dealers
   */
  paginateBranches: AuditStatistics_paginateBranches | null;
}

export interface AuditStatisticsVariables {
  accountManagerIds?: (string | null)[] | null;
  date?: DateRangeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Branch
// ====================================================

export interface Branch_dealer_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface Branch_dealer_branches {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
}

export interface Branch_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
  /**
   * The name of the dealer
   */
  name: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (Branch_dealer_meta | null)[] | null;
  /**
   * The branches of the dealer
   */
  branches: Branch_dealer_branches[];
}

export interface Branch_branch_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface Branch_branch_audits_inspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
}

export interface Branch_branch_audits_import_inspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
}

export interface Branch_branch_audits_import {
  __typename: "Import";
  /**
   * The id of the import
   */
  id: string;
  /**
   * The filename of the import
   */
  filename: string;
  /**
   * Inspections created with the import
   */
  inspections: (Branch_branch_audits_import_inspections | null)[];
}

export interface Branch_branch_audits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The inspections of the audit
   */
  inspections: Branch_branch_audits_inspections[];
  /**
   * The import this audit was created from
   */
  import: Branch_branch_audits_import | null;
}

export interface Branch_branch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The external id of the branch
   */
  externalId: string | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The amount of assets that are covered to the dealer
   */
  assetsCoveredAmount: number;
  /**
   * The amount of assets attached to the dealer
   */
  assetsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  futureInspectionsAmount: number;
  /**
   * The amount of inspections attached to the branch
   */
  openInspectionsAmount: number;
  /**
   * The amount of inspections attached to the branch
   */
  closedInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  inspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  foundInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  notFoundInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  expiredInspectionsAmount: number;
  /**
   * The amount of inspections with anomalies
   */
  inspectionsWithAnomaliesAmount: number;
  /**
   * Arbitrary information added to the address
   */
  meta: (Branch_branch_meta | null)[] | null;
  /**
   * The audits attached to the branch
   */
  audits: Branch_branch_audits[];
}

export interface Branch_dealerStatistics_branchStatistics {
  __typename: "DealerBranchStatisticsType";
  /**
   * The id of the branch
   */
  id: string;
  /**
   * The amount of inspections attached to the dealer
   */
  openInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  closedInspectionsAmount: number;
}

export interface Branch_dealerStatistics {
  __typename: "DealerStatisticsType";
  /**
   * The dealer branch statistics
   */
  branchStatistics: Branch_dealerStatistics_branchStatistics[];
}

export interface Branch_inspectionStatistics_statusByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface Branch_inspectionStatistics_statusByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (Branch_inspectionStatistics_statusByMonth_partitions | null)[];
}

export interface Branch_inspectionStatistics_situationByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface Branch_inspectionStatistics_situationByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (Branch_inspectionStatistics_situationByMonth_partitions | null)[];
}

export interface Branch_inspectionStatistics {
  __typename: "InspectionStatisticsType";
  /**
   * The dealer equipment situation partitions
   */
  statusByMonth: (Branch_inspectionStatistics_statusByMonth | null)[];
  /**
   * The dealer inspection status partitions
   */
  situationByMonth: (Branch_inspectionStatistics_situationByMonth | null)[];
}

export interface Branch {
  /**
   * Query a specific dealer identified by a dealer ID
   */
  dealer: Branch_dealer | null;
  /**
   * Query a specific branch identified by a Branch ID
   */
  branch: Branch_branch | null;
  /**
   * Delivers statistics for a certain dealer
   */
  dealerStatistics: Branch_dealerStatistics | null;
  /**
   * Get paged dealers
   */
  inspectionStatistics: Branch_inspectionStatistics | null;
}

export interface BranchVariables {
  dealerId: string;
  branchId: string;
  inspectionDate?: DateRangeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DashboardStatistics
// ====================================================

export interface DashboardStatistics_paginateInspections {
  __typename: "inspectionPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
}

export interface DashboardStatistics_inspectionStatistics_statusByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_inspectionStatistics_statusByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (DashboardStatistics_inspectionStatistics_statusByMonth_partitions | null)[];
}

export interface DashboardStatistics_inspectionStatistics_situationByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_inspectionStatistics_situationByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (DashboardStatistics_inspectionStatistics_situationByMonth_partitions | null)[];
}

export interface DashboardStatistics_inspectionStatistics {
  __typename: "InspectionStatisticsType";
  /**
   * The dealer equipment situation partitions
   */
  statusByMonth: (DashboardStatistics_inspectionStatistics_statusByMonth | null)[];
  /**
   * The dealer inspection status partitions
   */
  situationByMonth: (DashboardStatistics_inspectionStatistics_situationByMonth | null)[];
}

export interface DashboardStatistics_auditStatistics_toPlanByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_dealersByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_addressesByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_inspectionsByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_inspectionsWithAnomaliesByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_anomaliesByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_withAnomaliesByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_byStatus {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_byRating {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_byStatusThisMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_byRatingThisMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_byStatusByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_byStatusByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (DashboardStatistics_auditStatistics_byStatusByMonth_partitions | null)[];
}

export interface DashboardStatistics_auditStatistics_byRatingByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface DashboardStatistics_auditStatistics_byRatingByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (DashboardStatistics_auditStatistics_byRatingByMonth_partitions | null)[];
}

export interface DashboardStatistics_auditStatistics {
  __typename: "AuditStatisticsType";
  /**
   * The amount of branches to plan
   */
  toPlan: number;
  /**
   * The amount of branches to plan by month
   */
  toPlanByMonth: (DashboardStatistics_auditStatistics_toPlanByMonth | null)[];
  /**
   * The amount of dealers audited month
   */
  dealersByMonth: (DashboardStatistics_auditStatistics_dealersByMonth | null)[];
  /**
   * The amount of addresses audited month
   */
  addressesByMonth: (DashboardStatistics_auditStatistics_addressesByMonth | null)[];
  /**
   * The amount of inspection by month
   */
  inspectionsByMonth: (DashboardStatistics_auditStatistics_inspectionsByMonth | null)[];
  /**
   * The amount of inspection with anomalies by month
   */
  inspectionsWithAnomaliesByMonth: (DashboardStatistics_auditStatistics_inspectionsWithAnomaliesByMonth | null)[];
  /**
   * The amount of anomalies month
   */
  anomaliesByMonth: (DashboardStatistics_auditStatistics_anomaliesByMonth | null)[];
  /**
   * The amount of audits with anomalies by month
   */
  withAnomaliesByMonth: (DashboardStatistics_auditStatistics_withAnomaliesByMonth | null)[];
  /**
   * The amount of audits by status
   */
  byStatus: (DashboardStatistics_auditStatistics_byStatus | null)[];
  /**
   * The amount of audits by rating
   */
  byRating: (DashboardStatistics_auditStatistics_byRating | null)[];
  /**
   * The amount of audits by status this month
   */
  byStatusThisMonth: (DashboardStatistics_auditStatistics_byStatusThisMonth | null)[];
  /**
   * The amount of audits by rating this month
   */
  byRatingThisMonth: (DashboardStatistics_auditStatistics_byRatingThisMonth | null)[];
  /**
   * The amount of audits by status by month
   */
  byStatusByMonth: (DashboardStatistics_auditStatistics_byStatusByMonth | null)[];
  /**
   * The amount of audits by rating by month
   */
  byRatingByMonth: (DashboardStatistics_auditStatistics_byRatingByMonth | null)[];
}

export interface DashboardStatistics_paginateBranches {
  __typename: "branchPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
}

export interface DashboardStatistics {
  /**
   * Get paged inspections
   */
  paginateInspections: DashboardStatistics_paginateInspections | null;
  /**
   * Get paged dealers
   */
  inspectionStatistics: DashboardStatistics_inspectionStatistics | null;
  /**
   * Returns a single audit
   */
  auditStatistics: DashboardStatistics_auditStatistics | null;
  /**
   * Returns all branches of dealers
   */
  paginateBranches: DashboardStatistics_paginateBranches | null;
}

export interface DashboardStatisticsVariables {
  dateMonth?: DateRangeInput | null;
  dateYear?: DateRangeInput | null;
  accountManagerIds?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Dealer
// ====================================================

export interface Dealer_dealer_branches_audits_inspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
}

export interface Dealer_dealer_branches_audits_import_inspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
}

export interface Dealer_dealer_branches_audits_import {
  __typename: "Import";
  /**
   * The id of the import
   */
  id: string;
  /**
   * The filename of the import
   */
  filename: string;
  /**
   * Inspections created with the import
   */
  inspections: (Dealer_dealer_branches_audits_import_inspections | null)[];
}

export interface Dealer_dealer_branches_audits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The inspections of the audit
   */
  inspections: Dealer_dealer_branches_audits_inspections[];
  /**
   * The import this audit was created from
   */
  import: Dealer_dealer_branches_audits_import | null;
}

export interface Dealer_dealer_branches {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The audits attached to the branch
   */
  audits: Dealer_dealer_branches_audits[];
}

export interface Dealer_dealer_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface Dealer_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
  /**
   * The name of the dealer
   */
  name: string | null;
  /**
   * The amount of assets that are covered to the dealer
   */
  assetsCoveredAmount: number;
  /**
   * The amount of assets attached to the dealer
   */
  assetsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  futureInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  openInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  closedInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  inspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  foundInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  notFoundInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  expiredInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer with anomalies
   */
  inspectionsWithAnomaliesAmount: number;
  /**
   * The branches of the dealer
   */
  branches: Dealer_dealer_branches[];
  /**
   * Arbitrary information added to the inspection
   */
  meta: (Dealer_dealer_meta | null)[] | null;
}

export interface Dealer_dealerStatistics_branchStatistics {
  __typename: "DealerBranchStatisticsType";
  /**
   * The id of the branch
   */
  id: string;
  /**
   * The amount of inspections attached to the dealer
   */
  openInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  closedInspectionsAmount: number;
}

export interface Dealer_dealerStatistics {
  __typename: "DealerStatisticsType";
  /**
   * The dealer branch statistics
   */
  branchStatistics: Dealer_dealerStatistics_branchStatistics[];
}

export interface Dealer_inspectionStatistics_statusByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface Dealer_inspectionStatistics_statusByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (Dealer_inspectionStatistics_statusByMonth_partitions | null)[];
}

export interface Dealer_inspectionStatistics_situationByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface Dealer_inspectionStatistics_situationByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (Dealer_inspectionStatistics_situationByMonth_partitions | null)[];
}

export interface Dealer_inspectionStatistics {
  __typename: "InspectionStatisticsType";
  /**
   * The dealer equipment situation partitions
   */
  statusByMonth: (Dealer_inspectionStatistics_statusByMonth | null)[];
  /**
   * The dealer inspection status partitions
   */
  situationByMonth: (Dealer_inspectionStatistics_situationByMonth | null)[];
}

export interface Dealer {
  /**
   * Query a specific dealer identified by a dealer ID
   */
  dealer: Dealer_dealer | null;
  /**
   * Delivers statistics for a certain dealer
   */
  dealerStatistics: Dealer_dealerStatistics | null;
  /**
   * Get paged dealers
   */
  inspectionStatistics: Dealer_inspectionStatistics | null;
}

export interface DealerVariables {
  dealerId: string;
  inspectionDate?: DateRangeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DealerDashboard
// ====================================================

export interface DealerDashboard_inspectionStatistics {
  __typename: "InspectionStatisticsType";
  /**
   * The dealer equipment situation partitions
   */
  planned: number;
  /**
   * The dealer equipment situation partitions
   */
  busy: number;
}

export interface DealerDashboard {
  /**
   * Get paged dealers
   */
  inspectionStatistics: DealerDashboard_inspectionStatistics | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DealerMapBranches
// ====================================================

export interface DealerMapBranches_allBranches_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
}

export interface DealerMapBranches_allBranches {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The entity this address is attached to
   */
  dealer: DealerMapBranches_allBranches_dealer | null;
}

export interface DealerMapBranches {
  /**
   * Returns all branches of dealers
   */
  allBranches: (DealerMapBranches_allBranches | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateGroups
// ====================================================

export interface PaginateGroups_paginateGroups_data {
  __typename: "Group";
  /**
   * The id of the group
   */
  id: string;
  /**
   * The name of the productgroup
   */
  name: string | null;
}

export interface PaginateGroups_paginateGroups {
  __typename: "groupPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateGroups_paginateGroups_data | null)[] | null;
}

export interface PaginateGroups {
  /**
   * Get paged makes
   */
  paginateGroups: PaginateGroups_paginateGroups | null;
}

export interface PaginateGroupsVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  withFlow?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InspectionOld
// ====================================================

export interface InspectionOld_inspection_inspectionType {
  __typename: "InspectionType";
  /**
   * The id of the inspection type
   */
  id: string;
}

export interface InspectionOld_inspection_asset_group {
  __typename: "Group";
  /**
   * The id of the group
   */
  id: string;
  /**
   * The name of the productgroup
   */
  name: string | null;
}

export interface InspectionOld_inspection_asset_makeModel_make {
  __typename: "Make";
  /**
   * The id of the make
   */
  id: string;
  /**
   * The name of the make
   */
  name: string | null;
}

export interface InspectionOld_inspection_asset_makeModel {
  __typename: "MakeModel";
  /**
   * The id of the makeModel
   */
  id: string;
  /**
   * The name of the makeModel
   */
  name: string | null;
  /**
   * The make of this makeModel
   */
  make: InspectionOld_inspection_asset_makeModel_make | null;
}

export interface InspectionOld_inspection_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface InspectionOld_inspection_asset_images {
  __typename: "InspectionImage";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The filename of the image
   */
  filename: string | null;
  /**
   * The URL to the thumbnail image
   */
  thumb_url: string | null;
  /**
   * The URL to the original image
   */
  original_url: string | null;
  /**
   * Show Recapture InspectionChecks in the front-end
   */
  showRecaptureCheck: boolean | null;
}

export interface InspectionOld_inspection_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The year of manufacture of the asset
   */
  yearOfManufacture: number | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * The license plate of the asset
   */
  licensePlate: string | null;
  /**
   * The product group of this asset
   */
  group: InspectionOld_inspection_asset_group | null;
  /**
   * The makeModel of this asset
   */
  makeModel: InspectionOld_inspection_asset_makeModel | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (InspectionOld_inspection_asset_meta | null)[] | null;
  /**
   * The asset inspection images
   */
  images: (InspectionOld_inspection_asset_images | null)[] | null;
}

export interface InspectionOld_inspection_parameterValues_address {
  __typename: "Address";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The second line of the address
   */
  addressLine2: string | null;
}

export interface InspectionOld_inspection_parameterValues_remarks {
  __typename: "InspectionParameterValueRemark";
  /**
   * The id of the inspection parameter value remark
   */
  id: string;
  /**
   * The content of the remark
   */
  message: string | null;
}

export interface InspectionOld_inspection_parameterValues_images_checks {
  __typename: "InspectionCheck";
  /**
   * The id of the inspection warning
   */
  id: string;
  /**
   * The warning severity namespace
   */
  namespace: string;
  /**
   * The warning severity level
   */
  level: string;
  /**
   * The warning type
   */
  message: string;
  /**
   * The serialised (JSON) information about this warning
   */
  attributes: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
}

export interface InspectionOld_inspection_parameterValues_images {
  __typename: "InspectionImage";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The URL to the thumbnail image
   */
  thumb_url: string | null;
  /**
   * The URL to the original image
   */
  original_url: string | null;
  checks: (InspectionOld_inspection_parameterValues_images_checks | null)[] | null;
}

export interface InspectionOld_inspection_parameterValues_damages_image {
  __typename: "Image";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The URL to the thumbnail image
   */
  thumb_url: string | null;
  /**
   * The URL to the original image
   */
  original_url: string | null;
}

export interface InspectionOld_inspection_parameterValues_damages {
  __typename: "InspectionParameterValueDamage";
  /**
   * The id of the inspection parameter value remark
   */
  id: string;
  /**
   * The content of the remark
   */
  message: string | null;
  /**
   * Indicated costs for the specified damage
   */
  costs: number | null;
  /**
   * Boolean to indicate if the damage costs should be passed on to the lessee
   */
  costsForLessee: boolean | null;
  /**
   * An image containing the damage
   */
  image: InspectionOld_inspection_parameterValues_damages_image | null;
}

export interface InspectionOld_inspection_parameterValues_parameterGroup {
  __typename: "ParameterGroup";
  /**
   * The id of the parameter group
   */
  id: string;
}

export interface InspectionOld_inspection_parameterValues_parameter {
  __typename: "Parameter";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The name of the parameter
   */
  name: string | null;
}

export interface InspectionOld_inspection_parameterValues {
  __typename: "InspectionParameterValue";
  /**
   * The id of the parameter value
   */
  id: string;
  /**
   * The value for the parameter (attribute)
   */
  value: string | null;
  /**
   * Address attached to the parameter value
   */
  address: InspectionOld_inspection_parameterValues_address | null;
  /**
   * Additional remarks added to the parameter value
   */
  remarks: (InspectionOld_inspection_parameterValues_remarks | null)[] | null;
  /**
   * Images attached to the parameter value
   */
  images: (InspectionOld_inspection_parameterValues_images | null)[] | null;
  /**
   * Additional damages added to the parameter value
   */
  damages: (InspectionOld_inspection_parameterValues_damages | null)[] | null;
  /**
   * The parameter group this definition belongs to. The same parameter can have
   * multiple values based on a group. For example parameter 'Tyre Profile' can
   * have a value for parameter group 'Left front' or 'Right back' and so on.
   */
  parameterGroup: InspectionOld_inspection_parameterValues_parameterGroup | null;
  /**
   * The parameter definition this value is provided for
   */
  parameter: InspectionOld_inspection_parameterValues_parameter | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
}

export interface InspectionOld_inspection_flow_parameterCategories_parameterGroups_parameters_options {
  __typename: "ParameterOption";
  /**
   * The id of the parameter option
   */
  id: string;
  /**
   * The name of the parameter option
   */
  value: string | null;
}

export interface InspectionOld_inspection_flow_parameterCategories_parameterGroups_parameters {
  __typename: "FlowParameter";
  /**
   * The id of the flow parameter
   */
  id: string;
  /**
   * The name of the flow parameter
   */
  name: string | null;
  /**
   * The type of the flow parameter
   */
  type: string | null;
  /**
   * The min of the flow parameter
   */
  min: number | null;
  /**
   * The max of the flow parameter
   */
  max: number | null;
  /**
   * The metric of the flow parameter
   */
  metric: string | null;
  /**
   * The description of the flow parameter
   */
  description: string | null;
  /**
   * The available options for this parameter
   */
  options: (InspectionOld_inspection_flow_parameterCategories_parameterGroups_parameters_options | null)[] | null;
  /**
   * Whether this parameter is required
   */
  required: boolean | null;
  /**
   * The parameter pid of the flow parameter
   */
  parameterPid: string | null;
}

export interface InspectionOld_inspection_flow_parameterCategories_parameterGroups {
  __typename: "FlowParameterGroup";
  /**
   * The id of the flow parameter group
   */
  id: string;
  /**
   * The name of the flow parameter group
   */
  name: string | null;
  /**
   * The view type of the flow parameter group
   */
  viewType: string | null;
  /**
   * The parameter group pgid of the flow parameter group
   */
  parameterGroupPgid: string | null;
  /**
   * The parameters of the flow, e.g. Colour, Size, Airconditioning
   */
  parameters: (InspectionOld_inspection_flow_parameterCategories_parameterGroups_parameters | null)[] | null;
}

export interface InspectionOld_inspection_flow_parameterCategories {
  __typename: "FlowParameterCategory";
  /**
   * The id of the flow parameter category
   */
  id: string;
  /**
   * The name of the flow parameter category
   */
  name: string | null;
  /**
   * The parameter category pcid of the flow parameter category
   */
  parameterCategoryPcid: string | null;
  /**
   * The view type of the flow parameter category
   */
  viewType: string | null;
  /**
   * The parameter groups of the flow, e.g. Tyres Left, Tyres Right
   */
  parameterGroups: (InspectionOld_inspection_flow_parameterCategories_parameterGroups | null)[] | null;
}

export interface InspectionOld_inspection_flow {
  __typename: "InspectionFlow";
  /**
   * The id of the inspection flow
   */
  id: string;
  /**
   * The parameter categories of the flow
   */
  parameterCategories: (InspectionOld_inspection_flow_parameterCategories | null)[] | null;
}

export interface InspectionOld_inspection_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The phone number of the account
   */
  phone: string | null;
}

export interface InspectionOld_inspection_address_entity {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
}

export interface InspectionOld_inspection_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The state/province of the address
   */
  state: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The country of the address in a readable format
   */
  countryName: string | null;
  /**
   * The remark of the address
   */
  remark: string | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The entity this address is attached to
   */
  entity: InspectionOld_inspection_address_entity | null;
}

export interface InspectionOld_inspection_contact {
  __typename: "Contact";
  /**
   * The id of the contact
   */
  id: string;
  /**
   * The first name of the contact
   */
  name: string | null;
  /**
   * The first name of the contact
   */
  firstName: string | null;
  /**
   * The last name of the contact
   */
  lastName: string | null;
  /**
   * The email address of the contact
   */
  email: string | null;
  /**
   * The land line phone number of the contact
   */
  phone: string | null;
  /**
   * The mobile phone number of the contact
   */
  mobile: string | null;
}

export interface InspectionOld_inspection_logs_device {
  __typename: "Device";
  /**
   * The device identifier
   */
  id: string;
  /**
   * The name of the device
   */
  name: string | null;
  /**
   * The device brand
   */
  brand: string | null;
  /**
   * The device model
   */
  model: string | null;
}

export interface InspectionOld_inspection_logs {
  __typename: "InspectionLog";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * Accuracy of the coordinates
   */
  accuracy: number;
  /**
   * Latitude of the event
   */
  latitude: number;
  /**
   * Longitude of the event
   */
  longitude: number;
  /**
   * Distance of the coordinates to the address
   */
  distance: number | null;
  /**
   * The device that produced the log
   */
  device: InspectionOld_inspection_logs_device | null;
}

export interface InspectionOld_inspection_syncJob_statistics_device {
  __typename: "Device";
  /**
   * The device identifier
   */
  id: string;
  /**
   * The name of the device
   */
  name: string | null;
  /**
   * The os on the device
   */
  systemOs: string | null;
}

export interface InspectionOld_inspection_syncJob_statistics {
  __typename: "SyncJobStatistics";
  /**
   * The id of the sync job statistics
   */
  id: string;
  /**
   * The IP Address used to report statistics from
   */
  ipAddress: string | null;
  /**
   * The network state of the device
   */
  networkState: NetworkStateEnum | null;
  /**
   * The network carrier used
   */
  networkCarrier: string | null;
  /**
   * The generation of cellular connection used
   */
  networkCellularGeneration: string | null;
  /**
   * The device battery state
   */
  batteryState: BatteryStateEnum | null;
  /**
   * The device battery level
   */
  batteryLevel: number | null;
  /**
   * The amount of free disk space in megabytes
   */
  freeDiskStorage: number | null;
  /**
   * The amount of used memory in megabytes
   */
  usedMemory: number | null;
  /**
   * The device the statistics are reported from
   */
  device: InspectionOld_inspection_syncJob_statistics_device;
}

export interface InspectionOld_inspection_syncJob {
  __typename: "SyncJob";
  /**
   * The id of the sync job
   */
  id: string;
  /**
   * The status of a sync job
   */
  status: SyncJobStatusEnum | null;
  /**
   * The total number of media in the sync job
   */
  numberOfMedia: number | null;
  /**
   * The total number of requests in the sync job
   */
  numberOfRequests: number | null;
  /**
   * How many requests are done in the sync job
   */
  requestsDone: number | null;
  /**
   * The estimated time (based on previous upload speed) remaining to finish sync, in seconds
   */
  estimatedTimeRemaining: number | null;
  /**
   * The model updated_at date
   */
  updatedAt: string | null;
  /**
   * The last known statistics of the sync job
   */
  statistics: InspectionOld_inspection_syncJob_statistics | null;
}

export interface InspectionOld_inspection {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * Shows if the inspection is trusted
   */
  trusted: boolean | null;
  /**
   * The inspection equipment situation
   */
  equipmentSituation: EquipmentSituation | null;
  /**
   * The external ID, a reference used by the customer to identify the inspection
   */
  externalId: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model started_at date
   */
  startedAt: string | null;
  /**
   * The model finished_at date
   */
  finishedAt: string | null;
  /**
   * The model submitted_at date
   */
  submittedAt: string | null;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * A message containing global information about this inspection e.g. please phone the contact at the gate
   */
  remark: string | null;
  /**
   * The inspection equipment situation
   */
  inspectionType: InspectionOld_inspection_inspectionType | null;
  /**
   * The asset the inspection belongs to
   */
  asset: InspectionOld_inspection_asset | null;
  /**
   * The inspection filled parameters (attributes)
   */
  parameterValues: (InspectionOld_inspection_parameterValues | null)[] | null;
  /**
   * The inspection flow of the inspection
   */
  flow: InspectionOld_inspection_flow | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: InspectionOld_inspection_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: InspectionOld_inspection_address | null;
  /**
   * The contact of the inspection
   */
  contact: InspectionOld_inspection_contact | null;
  /**
   * The inspection logs for this inspection
   */
  logs: (InspectionOld_inspection_logs | null)[] | null;
  /**
   * The last sync job of the inspection
   */
  syncJob: InspectionOld_inspection_syncJob | null;
}

export interface InspectionOld {
  /**
   * Get a specific inspection identified by an InspectionID
   */
  inspection: InspectionOld_inspection | null;
}

export interface InspectionOldVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InspectionCreateAutoFill
// ====================================================

export interface InspectionCreateAutoFill_paginateInspections_data_asset_group {
  __typename: "Group";
  /**
   * The id of the group
   */
  id: string;
  /**
   * The name of the productgroup
   */
  name: string | null;
}

export interface InspectionCreateAutoFill_paginateInspections_data_asset_makeModel_make {
  __typename: "Make";
  /**
   * The id of the make
   */
  id: string;
  /**
   * The name of the make
   */
  name: string | null;
}

export interface InspectionCreateAutoFill_paginateInspections_data_asset_makeModel {
  __typename: "MakeModel";
  /**
   * The id of the makeModel
   */
  id: string;
  /**
   * The name of the makeModel
   */
  name: string | null;
  /**
   * The make of this makeModel
   */
  make: InspectionCreateAutoFill_paginateInspections_data_asset_makeModel_make | null;
}

export interface InspectionCreateAutoFill_paginateInspections_data_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The year of manufacture of the asset
   */
  yearOfManufacture: number | null;
  /**
   * The thumbnail of the asset
   */
  thumb: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * The product group of this asset
   */
  group: InspectionCreateAutoFill_paginateInspections_data_asset_group | null;
  /**
   * The makeModel of this asset
   */
  makeModel: InspectionCreateAutoFill_paginateInspections_data_asset_makeModel | null;
}

export interface InspectionCreateAutoFill_paginateInspections_data_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The remark of the address
   */
  remark: string | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
}

export interface InspectionCreateAutoFill_paginateInspections_data_contact {
  __typename: "Contact";
  /**
   * The id of the contact
   */
  id: string;
  /**
   * The first name of the contact
   */
  firstName: string | null;
  /**
   * The last name of the contact
   */
  lastName: string | null;
  /**
   * The email address of the contact
   */
  email: string | null;
  /**
   * The land line phone number of the contact
   */
  phone: string | null;
  /**
   * The mobile phone number of the contact
   */
  mobile: string | null;
}

export interface InspectionCreateAutoFill_paginateInspections_data {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The asset the inspection belongs to
   */
  asset: InspectionCreateAutoFill_paginateInspections_data_asset | null;
  /**
   * The address/location of the inspection
   */
  address: InspectionCreateAutoFill_paginateInspections_data_address | null;
  /**
   * The contact of the inspection
   */
  contact: InspectionCreateAutoFill_paginateInspections_data_contact | null;
}

export interface InspectionCreateAutoFill_paginateInspections {
  __typename: "inspectionPagination";
  /**
   * List of items on the current page
   */
  data: (InspectionCreateAutoFill_paginateInspections_data | null)[] | null;
}

export interface InspectionCreateAutoFill {
  /**
   * Get paged inspections
   */
  paginateInspections: InspectionCreateAutoFill_paginateInspections | null;
}

export interface InspectionCreateAutoFillVariables {
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Inspections
// ====================================================

export interface Inspections_paginateInspections_data_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface Inspections_paginateInspections_data_asset_images {
  __typename: "InspectionImage";
  /**
   * The id of the parameter
   */
  id: string;
}

export interface Inspections_paginateInspections_data_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (Inspections_paginateInspections_data_asset_meta | null)[] | null;
  /**
   * The asset inspection images
   */
  images: (Inspections_paginateInspections_data_asset_images | null)[] | null;
}

export interface Inspections_paginateInspections_data_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface Inspections_paginateInspections_data_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
}

export interface Inspections_paginateInspections_data {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The thumbnail of the inspection
   */
  thumb: string | null;
  /**
   * The external ID, a reference used by the customer to identify the inspection
   */
  externalId: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The inspection equipment situation
   */
  equipmentSituation: EquipmentSituation | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model started_at date
   */
  startedAt: string | null;
  /**
   * The model finished_at date
   */
  finishedAt: string | null;
  /**
   * The model submitted_at date
   */
  submittedAt: string | null;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * The date the inspection got assigned the status
   */
  statusDate: string | null;
  /**
   * The asset the inspection belongs to
   */
  asset: Inspections_paginateInspections_data_asset | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: Inspections_paginateInspections_data_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: Inspections_paginateInspections_data_address | null;
}

export interface Inspections_paginateInspections {
  __typename: "inspectionPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (Inspections_paginateInspections_data | null)[] | null;
}

export interface Inspections {
  /**
   * Get paged inspections
   */
  paginateInspections: Inspections_paginateInspections | null;
}

export interface InspectionsVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  status?: (InspectionStatus | null)[] | null;
  trusted?: boolean | null;
  inspectionType?: InspectionTypeEnum | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllLanguages
// ====================================================

export interface AllLanguages_allLanguages {
  __typename: "Language";
  /**
   * The id of the product category
   */
  languageCode: string;
  /**
   * The id of the product category
   */
  name: string | null;
  /**
   * The id of the product category
   */
  localTranslation: string | null;
}

export interface AllLanguages {
  /**
   * Get all language
   */
  allLanguages: (AllLanguages_allLanguages | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateMakes
// ====================================================

export interface PaginateMakes_paginateMakes_data {
  __typename: "Make";
  /**
   * The id of the make
   */
  id: string;
  /**
   * The name of the make
   */
  name: string | null;
}

export interface PaginateMakes_paginateMakes {
  __typename: "makePagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateMakes_paginateMakes_data | null)[] | null;
}

export interface PaginateMakes {
  /**
   * Get paged makes
   */
  paginateMakes: PaginateMakes_paginateMakes | null;
}

export interface PaginateMakesVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  validated?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptInspections
// ====================================================

export interface AcceptInspections_acceptInspections_approvals_creator {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface AcceptInspections_acceptInspections_approvals_reasons {
  __typename: "InspectionApprovalReason";
  /**
   * The id of the approval reason
   */
  id: string;
  /**
   * The approval reason
   */
  name: string | null;
}

export interface AcceptInspections_acceptInspections_approvals {
  __typename: "InspectionApproval";
  /**
   * The id of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  state: InspectionApprovalState;
  /**
   * The remark attached to the approval
   */
  remark: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The user responsible for the approval
   */
  creator: AcceptInspections_acceptInspections_approvals_creator;
  /**
   * The reasons for this approval
   */
  reasons: AcceptInspections_acceptInspections_approvals_reasons[];
}

export interface AcceptInspections_acceptInspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * Indicates whether the inspection has been declined or not
   */
  declined: boolean | null;
  /**
   * Whether the inspection has an approval remark or not
   */
  hasApprovalRemark: boolean;
  /**
   * The accept of decline history of the inspection
   */
  approvals: AcceptInspections_acceptInspections_approvals[];
}

export interface AcceptInspections {
  /**
   * Accept an inspection
   */
  acceptInspections: AcceptInspections_acceptInspections[];
}

export interface AcceptInspectionsVariables {
  ids: string[];
  remark?: string | null;
  reasonIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AttachDealerUser
// ====================================================

export interface AttachDealerUser_attachDealerUser {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
}

export interface AttachDealerUser {
  /**
   * Attach a dealer user
   */
  attachDealerUser: AttachDealerUser_attachDealerUser | null;
}

export interface AttachDealerUserVariables {
  user: UserInput;
  dealerId: string;
  branchIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelInspections
// ====================================================

export interface CancelInspections_cancelInspections {
  __typename: "ProcessingErrors";
  /**
   * List of errors
   */
  errors: (string | null)[];
}

export interface CancelInspections {
  /**
   * Cancel inspections
   */
  cancelInspections: CancelInspections_cancelInspections | null;
}

export interface CancelInspectionsVariables {
  ids: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CloseAudit
// ====================================================

export interface CloseAudit_closeAudit_closedBy {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface CloseAudit_closeAudit {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The status of the audit
   */
  status: AuditStatus | null;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The user who closed the audit
   */
  closedBy: CloseAudit_closeAudit_closedBy | null;
}

export interface CloseAudit {
  /**
   * Close an audit
   */
  closeAudit: CloseAudit_closeAudit;
}

export interface CloseAuditVariables {
  id: string;
  rating: AuditRating;
  message?: string | null;
  acceptNotClosed?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAuditNote
// ====================================================

export interface CreateAuditNote_createAuditNote_notes_creator {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface CreateAuditNote_createAuditNote_notes {
  __typename: "Note";
  /**
   * The id of the note
   */
  id: string;
  /**
   * The text of the note
   */
  note: string;
  /**
   * If the note is an internal note
   */
  internal: boolean;
  /**
   * The creator of the note
   */
  creator: CreateAuditNote_createAuditNote_notes_creator;
  /**
   * The model created at date
   */
  createdAt: string | null;
}

export interface CreateAuditNote_createAuditNote {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The notes of an audit
   */
  notes: (CreateAuditNote_createAuditNote_notes | null)[] | null;
}

export interface CreateAuditNote {
  /**
   * Hold an inspection
   */
  createAuditNote: CreateAuditNote_createAuditNote;
}

export interface CreateAuditNoteVariables {
  id: string;
  note: string;
  internal?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBranch
// ====================================================

export interface CreateBranch_createBranch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
}

export interface CreateBranch {
  /**
   * Create a branch for a dealer
   */
  createBranch: CreateBranch_createBranch | null;
}

export interface CreateBranchVariables {
  dealerId: string;
  externalId: string;
  branch: BranchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDealer
// ====================================================

export interface CreateDealer_createDealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The name of the dealer
   */
  name: string | null;
}

export interface CreateDealer {
  /**
   * Create a dealer in the system
   */
  createDealer: CreateDealer_createDealer | null;
}

export interface CreateDealerVariables {
  dealer: DealerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInspection
// ====================================================

export interface CreateInspection_createInspection_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface CreateInspection_createInspection_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (CreateInspection_createInspection_asset_meta | null)[] | null;
}

export interface CreateInspection_createInspection_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface CreateInspection_createInspection_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
}

export interface CreateInspection_createInspection {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The external ID, a reference used by the customer to identify the inspection
   */
  externalId: string | null;
  /**
   * The asset the inspection belongs to
   */
  asset: CreateInspection_createInspection_asset | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: CreateInspection_createInspection_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: CreateInspection_createInspection_address | null;
}

export interface CreateInspection {
  /**
   * Create a new inspection of a piece of equipment
   */
  createInspection: CreateInspection_createInspection | null;
}

export interface CreateInspectionVariables {
  inspection: InspectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInspectionNote
// ====================================================

export interface CreateInspectionNote_createInspectionNote_notes_creator {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface CreateInspectionNote_createInspectionNote_notes_file {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * File name is an internal note
   */
  fileName: string;
  /**
   * The url of the file
   */
  url: string;
}

export interface CreateInspectionNote_createInspectionNote_notes {
  __typename: "Note";
  /**
   * The id of the note
   */
  id: string;
  /**
   * The text of the note
   */
  note: string;
  /**
   * If the note is an internal note
   */
  internal: boolean;
  /**
   * The creator of the note
   */
  creator: CreateInspectionNote_createInspectionNote_notes_creator;
  /**
   * The file added to the media
   */
  file: CreateInspectionNote_createInspectionNote_notes_file | null;
  /**
   * The model created at date
   */
  createdAt: string | null;
}

export interface CreateInspectionNote_createInspectionNote {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The notes of an inspection
   */
  notes: (CreateInspectionNote_createInspectionNote_notes | null)[] | null;
}

export interface CreateInspectionNote {
  /**
   * Hold an inspection
   */
  createInspectionNote: CreateInspectionNote_createInspectionNote;
}

export interface CreateInspectionNoteVariables {
  id: string;
  note: string;
  internal?: boolean | null;
  file?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInspections
// ====================================================

export interface CreateInspections_createInspections_data_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface CreateInspections_createInspections_data_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (CreateInspections_createInspections_data_asset_meta | null)[] | null;
}

export interface CreateInspections_createInspections_data_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface CreateInspections_createInspections_data_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
}

export interface CreateInspections_createInspections_data {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The asset the inspection belongs to
   */
  asset: CreateInspections_createInspections_data_asset | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: CreateInspections_createInspections_data_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: CreateInspections_createInspections_data_address | null;
}

export interface CreateInspections_createInspections {
  __typename: "ProcessingErrors";
  /**
   * List of items
   */
  data: (CreateInspections_createInspections_data | null)[];
  /**
   * List of errors
   */
  errors: (string | null)[];
}

export interface CreateInspections {
  /**
   * Create a new inspection to start gathering information about an asset
   */
  createInspections: CreateInspections_createInspections | null;
}

export interface CreateInspectionsVariables {
  inspections: (InspectionInput | null)[];
  filename?: string | null;
  percentageOfInspections?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInspectionsFromBranch
// ====================================================

export interface CreateInspectionsFromBranch_createInspectionsFromBranch_branch_users {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface CreateInspectionsFromBranch_createInspectionsFromBranch_branch_lastInspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface CreateInspectionsFromBranch_createInspectionsFromBranch_branch {
  __typename: "BranchType";
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The users the address is attached to
   */
  users: (CreateInspectionsFromBranch_createInspectionsFromBranch_branch_users | null)[] | null;
  /**
   * The last inspector of the branch
   */
  lastInspector: CreateInspectionsFromBranch_createInspectionsFromBranch_branch_lastInspector | null;
}

export interface CreateInspectionsFromBranch_createInspectionsFromBranch {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The branch of the audit
   */
  branch: CreateInspectionsFromBranch_createInspectionsFromBranch_branch;
}

export interface CreateInspectionsFromBranch {
  /**
   * Create inspection from branch
   */
  createInspectionsFromBranch: CreateInspectionsFromBranch_createInspectionsFromBranch | null;
}

export interface CreateInspectionsFromBranchVariables {
  id: string;
  plannedAt: string;
  dueAt: string;
  inspector: BranchInspectorInput;
  percentageOfInspections: number;
  branch?: BranchInput | null;
  inspectionType?: InspectionTypeInput | null;
  trusted?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInspectionsFromImport
// ====================================================

export interface CreateInspectionsFromImport_createInspectionsFromImport_data_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface CreateInspectionsFromImport_createInspectionsFromImport_data_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (CreateInspectionsFromImport_createInspectionsFromImport_data_asset_meta | null)[] | null;
}

export interface CreateInspectionsFromImport_createInspectionsFromImport_data_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface CreateInspectionsFromImport_createInspectionsFromImport_data_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
}

export interface CreateInspectionsFromImport_createInspectionsFromImport_data {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The asset the inspection belongs to
   */
  asset: CreateInspectionsFromImport_createInspectionsFromImport_data_asset | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: CreateInspectionsFromImport_createInspectionsFromImport_data_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: CreateInspectionsFromImport_createInspectionsFromImport_data_address | null;
}

export interface CreateInspectionsFromImport_createInspectionsFromImport {
  __typename: "ProcessingErrors";
  /**
   * List of items
   */
  data: (CreateInspectionsFromImport_createInspectionsFromImport_data | null)[];
  /**
   * List of errors
   */
  errors: (string | null)[];
}

export interface CreateInspectionsFromImport {
  /**
   * Cancel inspections
   */
  createInspectionsFromImport: CreateInspectionsFromImport_createInspectionsFromImport | null;
}

export interface CreateInspectionsFromImportVariables {
  id: string;
  plannedAt: string;
  dueAt: string;
  inspectors?: (BranchInspectorInput | null)[] | null;
  percentageOfInspections?: number | null;
  trusted?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
}

export interface CreateUser {
  /**
   * Create user
   */
  createUser: CreateUser_createUser | null;
}

export interface CreateUserVariables {
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  language: string;
  role: string;
  entityEid?: string | null;
  addressAid?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserFilterPreset
// ====================================================

export interface CreateUserFilterPreset_createUserFilterPreset {
  __typename: "UserFilterPreset";
  /**
   * The query of the filter
   */
  query: string;
  /**
   * The user defined name of the user preset filter
   */
  name: string;
  /**
   * The type of the filter
   */
  type: string;
}

export interface CreateUserFilterPreset {
  /**
   * Create CreateUserFilterPreset
   */
  createUserFilterPreset: CreateUserFilterPreset_createUserFilterPreset | null;
}

export interface CreateUserFilterPresetVariables {
  name: string;
  query: string;
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: declineInspection
// ====================================================

export interface declineInspection_declineInspections_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The phone number of the account
   */
  phone: string | null;
}

export interface declineInspection_declineInspections_approvals_creator {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface declineInspection_declineInspections_approvals_reasons {
  __typename: "InspectionApprovalReason";
  /**
   * The id of the approval reason
   */
  id: string;
  /**
   * The approval reason
   */
  name: string | null;
}

export interface declineInspection_declineInspections_approvals {
  __typename: "InspectionApproval";
  /**
   * The id of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  state: InspectionApprovalState;
  /**
   * The remark attached to the approval
   */
  remark: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The user responsible for the approval
   */
  creator: declineInspection_declineInspections_approvals_creator;
  /**
   * The reasons for this approval
   */
  reasons: declineInspection_declineInspections_approvals_reasons[];
}

export interface declineInspection_declineInspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * Indicates whether the inspection has been declined or not
   */
  declined: boolean | null;
  /**
   * Whether the inspection has an approval remark or not
   */
  hasApprovalRemark: boolean;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: declineInspection_declineInspections_inspector | null;
  /**
   * The accept of decline history of the inspection
   */
  approvals: declineInspection_declineInspections_approvals[];
}

export interface declineInspection {
  /**
   * Decline an inspection (based on its value)
   */
  declineInspections: declineInspection_declineInspections[];
}

export interface declineInspectionVariables {
  ids: string[];
  remark?: string | null;
  reasonIds: string[];
  dueAt?: string | null;
  declineParameters: boolean;
  inspector?: UserInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAudit
// ====================================================

export interface DeleteAudit {
  /**
   * Delete an audit
   */
  deleteAudit: boolean;
}

export interface DeleteAuditVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserFilterPreset
// ====================================================

export interface DeleteUserFilterPreset_deleteUserFilterPreset {
  __typename: "UserFilterPreset";
  /**
   * The id of the user filter preset
   */
  id: string;
}

export interface DeleteUserFilterPreset {
  /**
   * Delete DeleteUserFilterPreset
   */
  deleteUserFilterPreset: DeleteUserFilterPreset_deleteUserFilterPreset | null;
}

export interface DeleteUserFilterPresetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DetachDealerUser
// ====================================================

export interface DetachDealerUser_detachDealerUser {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
}

export interface DetachDealerUser {
  /**
   * Detach a dealer user
   */
  detachDealerUser: DetachDealerUser_detachDealerUser | null;
}

export interface DetachDealerUserVariables {
  dealerId: string;
  branchIds: string[];
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExecuteInspection
// ====================================================

export interface ExecuteInspection_executeInspection_asset_images {
  __typename: "InspectionImage";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The filename of the image
   */
  filename: string | null;
  /**
   * The URL to the original image
   */
  original_url: string | null;
  /**
   * The URL to the thumbnail image
   */
  thumb_url: string | null;
}

export interface ExecuteInspection_executeInspection_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The asset inspection images
   */
  images: (ExecuteInspection_executeInspection_asset_images | null)[] | null;
}

export interface ExecuteInspection_executeInspection_parameterValues_address {
  __typename: "Address";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The second line of the address
   */
  addressLine2: string | null;
}

export interface ExecuteInspection_executeInspection_parameterValues_remarks {
  __typename: "InspectionParameterValueRemark";
  /**
   * The id of the inspection parameter value remark
   */
  id: string;
  /**
   * The content of the remark
   */
  message: string | null;
}

export interface ExecuteInspection_executeInspection_parameterValues_images {
  __typename: "InspectionImage";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The URL to the thumbnail image
   */
  thumb_url: string | null;
  /**
   * The URL to the original image
   */
  original_url: string | null;
}

export interface ExecuteInspection_executeInspection_parameterValues_parameterGroup {
  __typename: "ParameterGroup";
  /**
   * The id of the parameter group
   */
  id: string;
}

export interface ExecuteInspection_executeInspection_parameterValues_parameter {
  __typename: "Parameter";
  /**
   * The id of the parameter
   */
  id: string;
}

export interface ExecuteInspection_executeInspection_parameterValues {
  __typename: "InspectionParameterValue";
  /**
   * The id of the parameter value
   */
  id: string;
  /**
   * The value for the parameter (attribute)
   */
  value: string | null;
  /**
   * Address attached to the parameter value
   */
  address: ExecuteInspection_executeInspection_parameterValues_address | null;
  /**
   * Additional remarks added to the parameter value
   */
  remarks: (ExecuteInspection_executeInspection_parameterValues_remarks | null)[] | null;
  /**
   * Images attached to the parameter value
   */
  images: (ExecuteInspection_executeInspection_parameterValues_images | null)[] | null;
  /**
   * The parameter group this definition belongs to. The same parameter can have
   * multiple values based on a group. For example parameter 'Tyre Profile' can
   * have a value for parameter group 'Left front' or 'Right back' and so on.
   */
  parameterGroup: ExecuteInspection_executeInspection_parameterValues_parameterGroup | null;
  /**
   * The parameter definition this value is provided for
   */
  parameter: ExecuteInspection_executeInspection_parameterValues_parameter | null;
}

export interface ExecuteInspection_executeInspection {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The asset the inspection belongs to
   */
  asset: ExecuteInspection_executeInspection_asset | null;
  /**
   * The inspection filled parameters (attributes)
   */
  parameterValues: (ExecuteInspection_executeInspection_parameterValues | null)[] | null;
}

export interface ExecuteInspection {
  /**
   * Alter (perform) an inspection by supplying inspection values
   */
  executeInspection: ExecuteInspection_executeInspection | null;
}

export interface ExecuteInspectionVariables {
  id: string;
  images?: (any | null)[] | null;
  parameterValues: InspectionParameterValueInput[];
  overwrite: boolean;
  submittedAt?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExtendAudit
// ====================================================

export interface ExtendAudit_extendAudit {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The model due_at date
   */
  dueAt: string | null;
}

export interface ExtendAudit {
  /**
   * Delete an audit
   */
  extendAudit: ExtendAudit_extendAudit;
}

export interface ExtendAuditVariables {
  id: string;
  dueAt: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HoldInspectionPut
// ====================================================

export interface HoldInspectionPut_holdInspection_holds {
  __typename: "InspectionHold";
  /**
   * The id of the inspection hold
   */
  id: string;
}

export interface HoldInspectionPut_holdInspection {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The hold trail of this inspection
   */
  holds: HoldInspectionPut_holdInspection_holds[];
  /**
   * The model updated_at date
   */
  updatedAt: string | null;
}

export interface HoldInspectionPut {
  /**
   * Hold an inspection
   */
  holdInspection: HoldInspectionPut_holdInspection;
}

export interface HoldInspectionPutVariables {
  id: string;
  message: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HoldInspection
// ====================================================

export interface HoldInspection_resolveHoldInspection_holds {
  __typename: "InspectionHold";
  /**
   * The id of the inspection hold
   */
  id: string;
  /**
   * The model resolved_at date
   */
  resolvedAt: string | null;
}

export interface HoldInspection_resolveHoldInspection {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The hold trail of this inspection
   */
  holds: HoldInspection_resolveHoldInspection_holds[];
  /**
   * The model updated_at date
   */
  updatedAt: string | null;
}

export interface HoldInspection {
  /**
   * Resolve hold an inspection
   */
  resolveHoldInspection: HoldInspection_resolveHoldInspection;
}

export interface HoldInspectionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DownloadInspectionMedia
// ====================================================

export interface DownloadInspectionMedia {
  /**
   * Generate a link to download inspection media
   */
  downloadInspectionMedia: string;
}

export interface DownloadInspectionMediaVariables {
  id: string;
  compressed: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PostponeAudit
// ====================================================

export interface PostponeAudit_postponeAudit {
  __typename: "BranchType";
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
}

export interface PostponeAudit {
  /**
   * Delete an audit
   */
  postponeAudit: PostponeAudit_postponeAudit;
}

export interface PostponeAuditVariables {
  branchId: string;
  nextAudit: string;
  message?: string | null;
  reason?: AuditPostponeReason | null;
  rating?: AuditRating | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveBranch
// ====================================================

export interface RemoveBranch {
  /**
   * Remove a branch from a dealer
   */
  removeBranch: boolean | null;
}

export interface RemoveBranchVariables {
  dealerId: string;
  branchId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveImage
// ====================================================

export interface RemoveImage_removeImage {
  __typename: "Image";
  /**
   * The id of the parameter
   */
  id: string;
}

export interface RemoveImage {
  /**
   * Removes an image from a certain inspection
   */
  removeImage: RemoveImage_removeImage | null;
}

export interface RemoveImageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestDemoForUser
// ====================================================

export interface RequestDemoForUser {
  /**
   * Request demo for a new user
   */
  requestDemoForUser: boolean | null;
}

export interface RequestDemoForUserVariables {
  firstName: string;
  lastName: string;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendDealerPasswordCreateMail
// ====================================================

export interface SendDealerPasswordCreateMail_sendDealerPasswordCreateMail {
  __typename: "Account";
  /**
   * The id of the account
   */
  id: string;
}

export interface SendDealerPasswordCreateMail {
  /**
   * Update account information
   */
  sendDealerPasswordCreateMail: (SendDealerPasswordCreateMail_sendDealerPasswordCreateMail | null)[] | null;
}

export interface SendDealerPasswordCreateMailVariables {
  ids: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendReminderMail
// ====================================================

export interface SendReminderMail_sendReminderMail {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
}

export interface SendReminderMail {
  /**
   * Send reminder mail
   */
  sendReminderMail: (SendReminderMail_sendReminderMail | null)[];
}

export interface SendReminderMailVariables {
  ids?: string[] | null;
  auditIds?: string[] | null;
  userIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SerialNumberChecked
// ====================================================

export interface SerialNumberChecked_serialNumberChecked_checks {
  __typename: "InspectionCheck";
  /**
   * The id of the inspection warning
   */
  id: string;
  /**
   * The warning severity namespace
   */
  namespace: string;
  /**
   * The warning severity level
   */
  level: string;
  /**
   * The warning type
   */
  message: string;
  /**
   * The serialised (JSON) information about this warning
   */
  attributes: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
}

export interface SerialNumberChecked_serialNumberChecked {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * Inconsistency checks for this inspection
   */
  checks: SerialNumberChecked_serialNumberChecked_checks[] | null;
}

export interface SerialNumberChecked {
  /**
   * Update account information
   */
  serialNumberChecked: SerialNumberChecked_serialNumberChecked | null;
}

export interface SerialNumberCheckedVariables {
  id: string;
  correct: boolean;
  readable: boolean;
  imageId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitInspections
// ====================================================

export interface SubmitInspections_updateInspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The model submitted_at date
   */
  submittedAt: string | null;
}

export interface SubmitInspections {
  /**
   * Accept an inspection
   */
  updateInspections: (SubmitInspections_updateInspections | null)[] | null;
}

export interface SubmitInspectionsVariables {
  ids: (string | null)[];
  submittedAt: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: switchEntity
// ====================================================

export interface switchEntity_switchEntity_entity {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
}

export interface switchEntity_switchEntity {
  __typename: "Account";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The entity of the account
   */
  entity: switchEntity_switchEntity_entity | null;
  /**
   * The permissions of the account
   */
  rights: (string | null)[] | null;
}

export interface switchEntity {
  /**
   * Switch the entity of the current user
   */
  switchEntity: switchEntity_switchEntity | null;
}

export interface switchEntityVariables {
  entityId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TransferAudit
// ====================================================

export interface TransferAudit_transferAudit_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface TransferAudit_transferAudit {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: TransferAudit_transferAudit_inspector | null;
}

export interface TransferAudit {
  /**
   * Transfer an audit to a new inspector
   */
  transferAudit: TransferAudit_transferAudit[];
}

export interface TransferAuditVariables {
  id: string;
  inspector: UserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TransferInspections
// ====================================================

export interface TransferInspections_updateInspections_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The external id of the branch
   */
  externalId: string | null;
}

export interface TransferInspections_updateInspections_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface TransferInspections_updateInspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The address/location of the inspection
   */
  address: TransferInspections_updateInspections_address | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: TransferInspections_updateInspections_inspector | null;
}

export interface TransferInspections {
  /**
   * Accept an inspection
   */
  updateInspections: (TransferInspections_updateInspections | null)[] | null;
}

export interface TransferInspectionsVariables {
  ids: (string | null)[];
  inspector: UserInput;
  addressId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAccount
// ====================================================

export interface UpdateAccount_updateDealerAccount_roles {
  __typename: "Role";
  /**
   * The id of the role
   */
  id: string;
  /**
   * The name of the role
   */
  name: string;
}

export interface UpdateAccount_updateDealerAccount {
  __typename: "DealerAccount";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The display name of the account
   */
  displayName: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The phone number of the account
   */
  phone: string | null;
  /**
   * The chosen language of the account
   */
  language: string | null;
  /**
   * is the user has bid rights
   */
  hasBidRights: boolean | null;
  /**
   * The roles of an user
   */
  roles: UpdateAccount_updateDealerAccount_roles[] | null;
}

export interface UpdateAccount {
  /**
   * Update dealer account information
   */
  updateDealerAccount: UpdateAccount_updateDealerAccount | null;
}

export interface UpdateAccountVariables {
  id: string;
  email?: string | null;
  displayName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  language?: string | null;
  roles?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBranch
// ====================================================

export interface UpdateBranch_updateBranch_accountManager {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface UpdateBranch_updateBranch_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
}

export interface UpdateBranch_updateBranch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The external id of the branch
   */
  externalId: string | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The state/province of the address
   */
  state: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The contact name of the address
   */
  contactName: string | null;
  /**
   * The email attached to the address
   */
  email: string | null;
  /**
   * The land line phone number attached to the address
   */
  phone: string | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The date of the trusted next audit
   */
  nextTrustedAudit: string | null;
  /**
   * The time in days between each trusted audit
   */
  trustedAuditFrequency: number | null;
  /**
   * The remark of the address
   */
  remark: string | null;
  /**
   * The account manager of the audit
   */
  accountManager: UpdateBranch_updateBranch_accountManager | null;
  /**
   * The entity this address is attached to
   */
  dealer: UpdateBranch_updateBranch_dealer | null;
}

export interface UpdateBranch {
  /**
   * Update dealer branch information
   */
  updateBranch: UpdateBranch_updateBranch | null;
}

export interface UpdateBranchVariables {
  id: string;
  address: BranchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInspection
// ====================================================

export interface UpdateInspection_updateInspection_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface UpdateInspection_updateInspection_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (UpdateInspection_updateInspection_asset_meta | null)[] | null;
}

export interface UpdateInspection_updateInspection_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface UpdateInspection_updateInspection_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
}

export interface UpdateInspection_updateInspection_contact {
  __typename: "Contact";
  /**
   * The id of the contact
   */
  id: string;
  /**
   * The first name of the contact
   */
  name: string | null;
  /**
   * The email address of the contact
   */
  email: string | null;
  /**
   * The land line phone number of the contact
   */
  phone: string | null;
}

export interface UpdateInspection_updateInspection {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * A message containing global information about this inspection e.g. please phone the contact at the gate
   */
  remark: string | null;
  /**
   * The external ID, a reference used by the customer to identify the inspection
   */
  externalId: string | null;
  /**
   * The asset the inspection belongs to
   */
  asset: UpdateInspection_updateInspection_asset | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: UpdateInspection_updateInspection_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: UpdateInspection_updateInspection_address | null;
  /**
   * The contact of the inspection
   */
  contact: UpdateInspection_updateInspection_contact | null;
}

export interface UpdateInspection {
  /**
   * Alter an existing inspection, requires you to be the inspector or to be of
   * your entity and you have the permission 'inspections-edit-by-entity'
   */
  updateInspection: UpdateInspection_updateInspection | null;
}

export interface UpdateInspectionVariables {
  id: string;
  inspection: InspectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInspections
// ====================================================

export interface UpdateInspections_updateInspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
}

export interface UpdateInspections {
  /**
   * Accept an inspection
   */
  updateInspections: (UpdateInspections_updateInspections | null)[] | null;
}

export interface UpdateInspectionsVariables {
  ids: (string | null)[];
  plannedAt?: string | null;
  dueAt: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NextAudits
// ====================================================

export interface NextAudits_paginateBranches_data {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
}

export interface NextAudits_paginateBranches {
  __typename: "branchPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (NextAudits_paginateBranches_data | null)[] | null;
}

export interface NextAudits {
  /**
   * Returns all branches of dealers
   */
  paginateBranches: NextAudits_paginateBranches | null;
}

export interface NextAuditsVariables {
  page?: number | null;
  limit?: number | null;
  hasNextAudit?: boolean | null;
  hasCurrentAudit?: boolean | null;
  accountManagerIds?: string[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateAccountManagers
// ====================================================

export interface PaginateAccountManagers_paginateAccountManagers_data {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface PaginateAccountManagers_paginateAccountManagers {
  __typename: "userPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateAccountManagers_paginateAccountManagers_data | null)[] | null;
}

export interface PaginateAccountManagers {
  /**
   * Returns all users connected to a branch/dealer as account manager
   */
  paginateAccountManagers: PaginateAccountManagers_paginateAccountManagers;
}

export interface PaginateAccountManagersVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateAudits
// ====================================================

export interface PaginateAudits_paginateAudits_data_inspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection equipment situation
   */
  equipmentSituation: EquipmentSituation | null;
}

export interface PaginateAudits_paginateAudits_data_closedBy {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface PaginateAudits_paginateAudits_data_inspectionStatusCount {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateAudits_paginateAudits_data_inspectionSituationCount {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateAudits_paginateAudits_data_import_inspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
}

export interface PaginateAudits_paginateAudits_data_import {
  __typename: "Import";
  /**
   * The id of the import
   */
  id: string;
  /**
   * The filename of the import
   */
  filename: string;
  /**
   * Inspections created with the import
   */
  inspections: (PaginateAudits_paginateAudits_data_import_inspections | null)[];
}

export interface PaginateAudits_paginateAudits_data_dealer_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface PaginateAudits_paginateAudits_data_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
  /**
   * The name of the dealer
   */
  name: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (PaginateAudits_paginateAudits_data_dealer_meta | null)[] | null;
}

export interface PaginateAudits_paginateAudits_data_branch_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface PaginateAudits_paginateAudits_data_branch_endedAudits_closedBy {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
}

export interface PaginateAudits_paginateAudits_data_branch_endedAudits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The model postponed_to date
   */
  postponedTo: string | null;
  /**
   * The message of the audit
   */
  message: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The amount of accepted inspections in the audit
   */
  acceptedInspections: number | null;
  /**
   * The amount of declined inspections in the audit
   */
  declinedInspections: number | null;
  /**
   * The amount of expired inspections in the audit
   */
  expiredInspections: number | null;
  /**
   * The user who closed the audit
   */
  closedBy: PaginateAudits_paginateAudits_data_branch_endedAudits_closedBy | null;
}

export interface PaginateAudits_paginateAudits_data_branch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The external id of the branch
   */
  externalId: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The amount of assets attached to the dealer
   */
  assetsAmount: number;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * Arbitrary information added to the address
   */
  meta: (PaginateAudits_paginateAudits_data_branch_meta | null)[] | null;
  /**
   * The last three previous audits for this branch
   */
  endedAudits: PaginateAudits_paginateAudits_data_branch_endedAudits[];
}

export interface PaginateAudits_paginateAudits_data {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The status of the audit
   */
  status: AuditStatus | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The model postponed_to date
   */
  postponedTo: string | null;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The score of the audit
   */
  score: number | null;
  /**
   * Whether the audit is from a trusted party or not
   */
  trusted: boolean | null;
  /**
   * If one of the inspections is on hold
   */
  isOnHold: boolean | null;
  /**
   * The inspections of the audit
   */
  inspections: PaginateAudits_paginateAudits_data_inspections[];
  /**
   * The user who closed the audit
   */
  closedBy: PaginateAudits_paginateAudits_data_closedBy | null;
  /**
   * The amount of inspections with distance anomalies
   */
  distanceAnomalies: number | null;
  /**
   * The amount of inspections with inconsistency anomalies
   */
  inconsistencyAnomalies: number | null;
  /**
   * The amount of inspections with picture in picture anomalies
   */
  pictureInPictureAnomalies: number | null;
  /**
   * The amount of inspections with serial number anomalies
   */
  serialNumberAnomalies: number | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
  /**
   * The amount of submitted inspections in the audit
   */
  submittedInspections: number | null;
  /**
   * The amount of open inspections in the audit
   */
  openInspections: number | null;
  /**
   * The amount of accepted inspections in the audit
   */
  acceptedInspections: number | null;
  /**
   * The amount of declined inspections in the audit
   */
  declinedInspections: number | null;
  /**
   * The amount of expired inspections in the audit
   */
  expiredInspections: number | null;
  /**
   * The amount of inspections without anomalies in the audit
   */
  inspectionsWithoutAnomaliesAmount: number | null;
  /**
   * The amount of inspections with anomalies in the audit
   */
  inspectionsWithAnomaliesAmount: number | null;
  /**
   * The amount of anomalies in the audit
   */
  anomaliesAmount: number | null;
  /**
   * The amount of inspections for a certain status
   */
  inspectionStatusCount: PaginateAudits_paginateAudits_data_inspectionStatusCount[];
  /**
   * The amount of inspections for a certain situation
   */
  inspectionSituationCount: PaginateAudits_paginateAudits_data_inspectionSituationCount[];
  /**
   * The import this audit was created from
   */
  import: PaginateAudits_paginateAudits_data_import | null;
  /**
   * The dealer of the audit
   */
  dealer: PaginateAudits_paginateAudits_data_dealer;
  /**
   * The branch of the audit
   */
  branch: PaginateAudits_paginateAudits_data_branch;
}

export interface PaginateAudits_paginateAudits {
  __typename: "auditPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateAudits_paginateAudits_data | null)[] | null;
}

export interface PaginateAudits {
  /**
   * Returns all audits
   */
  paginateAudits: PaginateAudits_paginateAudits | null;
}

export interface PaginateAuditsVariables {
  page?: number | null;
  limit?: number | null;
  trusted?: boolean | null;
  accountManagerIds?: string[] | null;
  status?: AuditStatus | null;
  rating?: AuditRating[] | null;
  dealerIds?: string[] | null;
  branchIds?: string[] | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  withAnomaliesToReview?: boolean | null;
  withActionsToReview?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateDealerAccounts
// ====================================================

export interface PaginateDealerAccounts_paginateDealerAccounts_data_entity {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
}

export interface PaginateDealerAccounts_paginateDealerAccounts_data_addresses_entity {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
}

export interface PaginateDealerAccounts_paginateDealerAccounts_data_addresses {
  __typename: "Address";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The entity this address is attached to
   */
  entity: PaginateDealerAccounts_paginateDealerAccounts_data_addresses_entity | null;
}

export interface PaginateDealerAccounts_paginateDealerAccounts_data_roles {
  __typename: "Role";
  /**
   * The id of the role
   */
  id: string;
  /**
   * The name of the role
   */
  name: string;
}

export interface PaginateDealerAccounts_paginateDealerAccounts_data_device {
  __typename: "Device";
  /**
   * The name of the device
   */
  name: string | null;
  /**
   * The os on the device
   */
  systemOs: string | null;
  /**
   * The version used on the device
   */
  appVersion: string | null;
  /**
   * The version used on the device is outdated
   */
  outdated: boolean | null;
}

export interface PaginateDealerAccounts_paginateDealerAccounts_data {
  __typename: "DealerAccount";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The display name of the account
   */
  displayName: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The phone number of the account
   */
  phone: string | null;
  /**
   * The chosen language of the account
   */
  language: string | null;
  /**
   * is the user has an password
   */
  hasPassword: boolean | null;
  /**
   * The entity of the account
   */
  entity: PaginateDealerAccounts_paginateDealerAccounts_data_entity | null;
  /**
   * The addresses this user is connected to
   */
  addresses: (PaginateDealerAccounts_paginateDealerAccounts_data_addresses | null)[] | null;
  /**
   * is the user has bid rights
   */
  hasBidRights: boolean | null;
  /**
   * The amount of inspection connected to this account
   */
  inspectionsAmountOpen: number | null;
  /**
   * The amount of inspection connected to this account
   */
  inspectionsAmountTotal: number | null;
  /**
   * The roles of an user
   */
  roles: PaginateDealerAccounts_paginateDealerAccounts_data_roles[] | null;
  /**
   * The last device of the user
   */
  device: PaginateDealerAccounts_paginateDealerAccounts_data_device | null;
}

export interface PaginateDealerAccounts_paginateDealerAccounts {
  __typename: "dealerAccountPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateDealerAccounts_paginateDealerAccounts_data | null)[] | null;
}

export interface PaginateDealerAccounts {
  /**
   * Get paginated dealer accounts
   */
  paginateDealerAccounts: PaginateDealerAccounts_paginateDealerAccounts | null;
}

export interface PaginateDealerAccountsVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  dealerId?: string | null;
  branchId?: string | null;
  type?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateDealerBranches
// ====================================================

export interface PaginateDealerBranches_paginateDealerBranches_data_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface PaginateDealerBranches_paginateDealerBranches_data {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The amount of inspections attached to the dealer
   */
  inspectionsAmount: number;
  /**
   * The amount of inspections attached to the branch
   */
  openInspectionsAmount: number;
  /**
   * Arbitrary information added to the address
   */
  meta: (PaginateDealerBranches_paginateDealerBranches_data_meta | null)[] | null;
}

export interface PaginateDealerBranches_paginateDealerBranches {
  __typename: "branchPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateDealerBranches_paginateDealerBranches_data | null)[] | null;
}

export interface PaginateDealerBranches {
  /**
   * Returns all branches of a dealer
   */
  paginateDealerBranches: PaginateDealerBranches_paginateDealerBranches | null;
}

export interface PaginateDealerBranchesVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paginateDealerInspections
// ====================================================

export interface paginateDealerInspections_paginateDealerInspections_data_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface paginateDealerInspections_paginateDealerInspections_data_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (paginateDealerInspections_paginateDealerInspections_data_asset_meta | null)[] | null;
}

export interface paginateDealerInspections_paginateDealerInspections_data_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface paginateDealerInspections_paginateDealerInspections_data_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
}

export interface paginateDealerInspections_paginateDealerInspections_data {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The thumbnail of the inspection
   */
  thumb: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model started_at date
   */
  startedAt: string | null;
  /**
   * The model finished_at date
   */
  finishedAt: string | null;
  /**
   * The model submitted_at date
   */
  submittedAt: string | null;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * The asset the inspection belongs to
   */
  asset: paginateDealerInspections_paginateDealerInspections_data_asset | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: paginateDealerInspections_paginateDealerInspections_data_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: paginateDealerInspections_paginateDealerInspections_data_address | null;
}

export interface paginateDealerInspections_paginateDealerInspections {
  __typename: "inspectionPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (paginateDealerInspections_paginateDealerInspections_data | null)[] | null;
}

export interface paginateDealerInspections {
  /**
   * Get paged inspections
   */
  paginateDealerInspections: paginateDealerInspections_paginateDealerInspections | null;
}

export interface paginateDealerInspectionsVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  status?: (InspectionStatus | null)[] | null;
  inspectorId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlanningBranch
// ====================================================

export interface PlanningBranch_branch_lastInspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface PlanningBranch_branch_accountManager {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
}

export interface PlanningBranch_branch_endedAudits_closedBy {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
}

export interface PlanningBranch_branch_endedAudits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The model postponed_to date
   */
  postponedTo: string | null;
  /**
   * The message of the audit
   */
  message: string | null;
  /**
   * The amount of accepted inspections in the audit
   */
  acceptedInspections: number | null;
  /**
   * The amount of declined inspections in the audit
   */
  declinedInspections: number | null;
  /**
   * The amount of expired inspections in the audit
   */
  expiredInspections: number | null;
  /**
   * The user who closed the audit
   */
  closedBy: PlanningBranch_branch_endedAudits_closedBy | null;
}

export interface PlanningBranch_branch_dealer_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface PlanningBranch_branch_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (PlanningBranch_branch_dealer_meta | null)[] | null;
}

export interface PlanningBranch_branch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The second line of the address
   */
  addressLine2: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The state/province of the address
   */
  state: string | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The amount of assets attached to the dealer
   */
  assetsAmount: number;
  /**
   * The last inspector of the branch
   */
  lastInspector: PlanningBranch_branch_lastInspector | null;
  /**
   * The account manager of the audit
   */
  accountManager: PlanningBranch_branch_accountManager | null;
  /**
   * The last three previous audits for this branch
   */
  endedAudits: PlanningBranch_branch_endedAudits[];
  /**
   * The entity this address is attached to
   */
  dealer: PlanningBranch_branch_dealer | null;
}

export interface PlanningBranch {
  /**
   * Query a specific branch identified by a Branch ID
   */
  branch: PlanningBranch_branch | null;
}

export interface PlanningBranchVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlanningPaginateAudits
// ====================================================

export interface PlanningPaginateAudits_paginateAudits_data_dealer_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface PlanningPaginateAudits_paginateAudits_data_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The name of the dealer
   */
  name: string | null;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (PlanningPaginateAudits_paginateAudits_data_dealer_meta | null)[] | null;
}

export interface PlanningPaginateAudits_paginateAudits_data_branch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The external id of the branch
   */
  externalId: string | null;
}

export interface PlanningPaginateAudits_paginateAudits_data {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The status of the audit
   */
  status: AuditStatus | null;
  /**
   * Whether the audit is from a trusted party or not
   */
  trusted: boolean | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The score of the audit
   */
  score: number | null;
  /**
   * If one of the inspections is on hold
   */
  isOnHold: boolean | null;
  /**
   * The amount of submitted inspections in the audit
   */
  submittedInspections: number | null;
  /**
   * The amount of open inspections in the audit
   */
  openInspections: number | null;
  /**
   * The amount of accepted inspections in the audit
   */
  acceptedInspections: number | null;
  /**
   * The amount of declined inspections in the audit
   */
  declinedInspections: number | null;
  /**
   * The amount of expired inspections in the audit
   */
  expiredInspections: number | null;
  /**
   * The dealer of the audit
   */
  dealer: PlanningPaginateAudits_paginateAudits_data_dealer;
  /**
   * The branch of the audit
   */
  branch: PlanningPaginateAudits_paginateAudits_data_branch;
}

export interface PlanningPaginateAudits_paginateAudits {
  __typename: "auditPagination";
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * List of items on the current page
   */
  data: (PlanningPaginateAudits_paginateAudits_data | null)[] | null;
}

export interface PlanningPaginateAudits {
  /**
   * Returns all audits
   */
  paginateAudits: PlanningPaginateAudits_paginateAudits | null;
}

export interface PlanningPaginateAuditsVariables {
  page?: number | null;
  limit?: number | null;
  accountManagerIds?: string[] | null;
  status?: AuditStatus | null;
  search?: string | null;
  searchableFields: (string | null)[];
  withAnomaliesToReview?: boolean | null;
  withActionsToReview?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateBranches
// ====================================================

export interface PaginateBranches_paginateBranches_data_accountManager {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface PaginateBranches_paginateBranches_data_users {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface PaginateBranches_paginateBranches_data_lastInspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface PaginateBranches_paginateBranches_data_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
}

export interface PaginateBranches_paginateBranches_data {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The external id of the branch
   */
  externalId: string | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The state/province of the address
   */
  state: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The contact name of the address
   */
  contactName: string | null;
  /**
   * The email attached to the address
   */
  email: string | null;
  /**
   * The land line phone number attached to the address
   */
  phone: string | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The date of the trusted next audit
   */
  nextTrustedAudit: string | null;
  /**
   * The time in days between each trusted audit
   */
  trustedAuditFrequency: number | null;
  /**
   * The remark of the address
   */
  remark: string | null;
  /**
   * The amount of assets attached to the dealer
   */
  assetsAmount: number;
  /**
   * The account manager of the audit
   */
  accountManager: PaginateBranches_paginateBranches_data_accountManager | null;
  /**
   * The users the address is attached to
   */
  users: (PaginateBranches_paginateBranches_data_users | null)[] | null;
  /**
   * The last inspector of the branch
   */
  lastInspector: PaginateBranches_paginateBranches_data_lastInspector | null;
  /**
   * The entity this address is attached to
   */
  dealer: PaginateBranches_paginateBranches_data_dealer | null;
}

export interface PaginateBranches_paginateBranches {
  __typename: "branchPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateBranches_paginateBranches_data | null)[] | null;
}

export interface PaginateBranches {
  /**
   * Returns all branches of dealers
   */
  paginateBranches: PaginateBranches_paginateBranches | null;
}

export interface PaginateBranchesVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  dealerId?: string | null;
  hasNextAudit?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateContacts
// ====================================================

export interface PaginateContacts_paginateContacts_data {
  __typename: "Contact";
  /**
   * The id of the contact
   */
  id: string;
  /**
   * The first name of the contact
   */
  name: string | null;
  /**
   * The first name of the contact
   */
  firstName: string | null;
  /**
   * The last name of the contact
   */
  lastName: string | null;
  /**
   * The email address of the contact
   */
  email: string | null;
  /**
   * The land line phone number of the contact
   */
  phone: string | null;
  /**
   * The mobile phone number of the contact
   */
  mobile: string | null;
}

export interface PaginateContacts_paginateContacts {
  __typename: "contactPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateContacts_paginateContacts_data | null)[] | null;
}

export interface PaginateContacts {
  /**
   * Get paged makes
   */
  paginateContacts: PaginateContacts_paginateContacts | null;
}

export interface PaginateContactsVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateChildEntities
// ====================================================

export interface PaginateChildEntities_paginateChildEntities_data_auditsByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_paginateChildEntities_data_dealersAuditedByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_paginateChildEntities_data_addressesAuditedByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_paginateChildEntities_data_auditsByStatusByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_paginateChildEntities_data_auditsByStatusByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (PaginateChildEntities_paginateChildEntities_data_auditsByStatusByMonth_partitions | null)[];
}

export interface PaginateChildEntities_paginateChildEntities_data_auditsByRatingByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_paginateChildEntities_data_auditsByRatingByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (PaginateChildEntities_paginateChildEntities_data_auditsByRatingByMonth_partitions | null)[];
}

export interface PaginateChildEntities_paginateChildEntities_data_auditsWithAnomaliesByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_paginateChildEntities_data_inspectionsByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_paginateChildEntities_data_inspectionsByStatusByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_paginateChildEntities_data_inspectionsByStatusByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (PaginateChildEntities_paginateChildEntities_data_inspectionsByStatusByMonth_partitions | null)[];
}

export interface PaginateChildEntities_paginateChildEntities_data_inspectionsBySituationByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_paginateChildEntities_data_inspectionsBySituationByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (PaginateChildEntities_paginateChildEntities_data_inspectionsBySituationByMonth_partitions | null)[];
}

export interface PaginateChildEntities_paginateChildEntities_data {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
  /**
   * The amount of audits by month
   */
  auditsByMonth: PaginateChildEntities_paginateChildEntities_data_auditsByMonth[];
  /**
   * The amount of dealers audited by month
   */
  dealersAuditedByMonth: PaginateChildEntities_paginateChildEntities_data_dealersAuditedByMonth[];
  /**
   * The amount of addresses audited by month
   */
  addressesAuditedByMonth: PaginateChildEntities_paginateChildEntities_data_addressesAuditedByMonth[];
  /**
   * The amount of audits by status by month
   */
  auditsByStatusByMonth: PaginateChildEntities_paginateChildEntities_data_auditsByStatusByMonth[];
  /**
   * The amount of audits by rating by month
   */
  auditsByRatingByMonth: PaginateChildEntities_paginateChildEntities_data_auditsByRatingByMonth[];
  /**
   * The amount of audits with anomalies by month
   */
  auditsWithAnomaliesByMonth: PaginateChildEntities_paginateChildEntities_data_auditsWithAnomaliesByMonth[];
  /**
   * The amount of inspection by month
   */
  inspectionsByMonth: PaginateChildEntities_paginateChildEntities_data_inspectionsByMonth[];
  /**
   * The amount of inspections by status by month
   */
  inspectionsByStatusByMonth: PaginateChildEntities_paginateChildEntities_data_inspectionsByStatusByMonth[];
  /**
   * The amount of inspections by rating by month
   */
  inspectionsBySituationByMonth: PaginateChildEntities_paginateChildEntities_data_inspectionsBySituationByMonth[];
}

export interface PaginateChildEntities_paginateChildEntities {
  __typename: "entityPagination";
  /**
   * List of items on the current page
   */
  data: (PaginateChildEntities_paginateChildEntities_data | null)[] | null;
}

export interface PaginateChildEntities_paginateInspections {
  __typename: "inspectionPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
}

export interface PaginateChildEntities_inspectionStatistics_statusByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_inspectionStatistics_statusByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (PaginateChildEntities_inspectionStatistics_statusByMonth_partitions | null)[];
}

export interface PaginateChildEntities_inspectionStatistics_situationByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_inspectionStatistics_situationByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (PaginateChildEntities_inspectionStatistics_situationByMonth_partitions | null)[];
}

export interface PaginateChildEntities_inspectionStatistics {
  __typename: "InspectionStatisticsType";
  /**
   * The dealer equipment situation partitions
   */
  statusByMonth: (PaginateChildEntities_inspectionStatistics_statusByMonth | null)[];
  /**
   * The dealer inspection status partitions
   */
  situationByMonth: (PaginateChildEntities_inspectionStatistics_situationByMonth | null)[];
}

export interface PaginateChildEntities_auditStatistics_toPlanByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_dealersByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_addressesByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_inspectionsByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_inspectionsWithAnomaliesByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_anomaliesByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_withAnomaliesByMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_byStatus {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_byRating {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_byStatusThisMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_byRatingThisMonth {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_byStatusByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_byStatusByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (PaginateChildEntities_auditStatistics_byStatusByMonth_partitions | null)[];
}

export interface PaginateChildEntities_auditStatistics_byRatingByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface PaginateChildEntities_auditStatistics_byRatingByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (PaginateChildEntities_auditStatistics_byRatingByMonth_partitions | null)[];
}

export interface PaginateChildEntities_auditStatistics {
  __typename: "AuditStatisticsType";
  /**
   * The amount of branches to plan
   */
  toPlan: number;
  /**
   * The amount of branches to plan by month
   */
  toPlanByMonth: (PaginateChildEntities_auditStatistics_toPlanByMonth | null)[];
  /**
   * The amount of dealers audited month
   */
  dealersByMonth: (PaginateChildEntities_auditStatistics_dealersByMonth | null)[];
  /**
   * The amount of addresses audited month
   */
  addressesByMonth: (PaginateChildEntities_auditStatistics_addressesByMonth | null)[];
  /**
   * The amount of inspection by month
   */
  inspectionsByMonth: (PaginateChildEntities_auditStatistics_inspectionsByMonth | null)[];
  /**
   * The amount of inspection with anomalies by month
   */
  inspectionsWithAnomaliesByMonth: (PaginateChildEntities_auditStatistics_inspectionsWithAnomaliesByMonth | null)[];
  /**
   * The amount of anomalies month
   */
  anomaliesByMonth: (PaginateChildEntities_auditStatistics_anomaliesByMonth | null)[];
  /**
   * The amount of audits with anomalies by month
   */
  withAnomaliesByMonth: (PaginateChildEntities_auditStatistics_withAnomaliesByMonth | null)[];
  /**
   * The amount of audits by status
   */
  byStatus: (PaginateChildEntities_auditStatistics_byStatus | null)[];
  /**
   * The amount of audits by rating
   */
  byRating: (PaginateChildEntities_auditStatistics_byRating | null)[];
  /**
   * The amount of audits by status this month
   */
  byStatusThisMonth: (PaginateChildEntities_auditStatistics_byStatusThisMonth | null)[];
  /**
   * The amount of audits by rating this month
   */
  byRatingThisMonth: (PaginateChildEntities_auditStatistics_byRatingThisMonth | null)[];
  /**
   * The amount of audits by status by month
   */
  byStatusByMonth: (PaginateChildEntities_auditStatistics_byStatusByMonth | null)[];
  /**
   * The amount of audits by rating by month
   */
  byRatingByMonth: (PaginateChildEntities_auditStatistics_byRatingByMonth | null)[];
}

export interface PaginateChildEntities_paginateBranches_data_dealer_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface PaginateChildEntities_paginateBranches_data_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The name of the dealer
   */
  name: string | null;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (PaginateChildEntities_paginateBranches_data_dealer_meta | null)[] | null;
}

export interface PaginateChildEntities_paginateBranches_data_currentAudit {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The amount of open inspections in the audit
   */
  openInspections: number | null;
  /**
   * The amount of expired inspections in the audit
   */
  expiredInspections: number | null;
  /**
   * The amount of submitted inspections in the audit
   */
  submittedInspections: number | null;
}

export interface PaginateChildEntities_paginateBranches_data_endedAudits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The status of the audit
   */
  status: AuditStatus | null;
  /**
   * The model postponed_to date
   */
  postponedTo: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The amount of accepted inspections in the audit
   */
  acceptedInspections: number | null;
  /**
   * The amount of declined inspections in the audit
   */
  declinedInspections: number | null;
}

export interface PaginateChildEntities_paginateBranches_data {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The entity this address is attached to
   */
  dealer: PaginateChildEntities_paginateBranches_data_dealer | null;
  /**
   * Audits this year
   */
  auditsThisYearAmount: number | null;
  /**
   * Audits postponed this year
   */
  auditsPostponedThisYearAmount: number | null;
  /**
   * Audits closed this year
   */
  auditsClosedThisYearAmount: number | null;
  /**
   * Audits closed with anomalies this year
   */
  auditsClosedWithAnomaliesThisYearAmount: number | null;
  /**
   * The current audit for this branch
   */
  currentAudit: PaginateChildEntities_paginateBranches_data_currentAudit | null;
  /**
   * The last three previous audits for this branch
   */
  endedAudits: PaginateChildEntities_paginateBranches_data_endedAudits[];
}

export interface PaginateChildEntities_paginateBranches {
  __typename: "branchPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateChildEntities_paginateBranches_data | null)[] | null;
}

export interface PaginateChildEntities {
  /**
   * Paginate through the child entities of the entity
   */
  paginateChildEntities: PaginateChildEntities_paginateChildEntities | null;
  /**
   * Get paged inspections
   */
  paginateInspections: PaginateChildEntities_paginateInspections | null;
  /**
   * Get paged dealers
   */
  inspectionStatistics: PaginateChildEntities_inspectionStatistics | null;
  /**
   * Returns a single audit
   */
  auditStatistics: PaginateChildEntities_auditStatistics | null;
  /**
   * Returns all branches of dealers
   */
  paginateBranches: PaginateChildEntities_paginateBranches | null;
}

export interface PaginateChildEntitiesVariables {
  dateMonth?: DateRangeInput | null;
  dateYear?: DateRangeInput | null;
  accountManagerIds?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AuditsExpiredInspections
// ====================================================

export interface AuditsExpiredInspections_globalDashboard_auditsExpiredInspections_branch_owner {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
}

export interface AuditsExpiredInspections_globalDashboard_auditsExpiredInspections_branch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The owner (entity) of the address
   */
  owner: AuditsExpiredInspections_globalDashboard_auditsExpiredInspections_branch_owner | null;
}

export interface AuditsExpiredInspections_globalDashboard_auditsExpiredInspections_previousAudits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
}

export interface AuditsExpiredInspections_globalDashboard_auditsExpiredInspections {
  __typename: "Audit";
  /**
   * The amount of expired inspections in the audit
   */
  expiredInspections: number | null;
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The amount of inspections with situation Sold
   */
  soldAmount: number | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
  /**
   * The amount of inspections in the audit
   */
  inspectionsAmount: number | null;
  /**
   * The branch of the audit
   */
  branch: AuditsExpiredInspections_globalDashboard_auditsExpiredInspections_branch;
  /**
   * The last previous audits
   */
  previousAudits: AuditsExpiredInspections_globalDashboard_auditsExpiredInspections_previousAudits[];
}

export interface AuditsExpiredInspections_globalDashboard {
  __typename: "GlobalDashboard";
  /**
   * Audits with expired inspections
   */
  auditsExpiredInspections: AuditsExpiredInspections_globalDashboard_auditsExpiredInspections[];
}

export interface AuditsExpiredInspections {
  /**
   * Returns statistics of all attached child entities
   */
  globalDashboard: AuditsExpiredInspections_globalDashboard | null;
}

export interface AuditsExpiredInspectionsVariables {
  entities?: string[] | null;
  date?: DateRangeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AuditsHalfContent
// ====================================================

export interface AuditsHalfContent_globalDashboard_auditsHalfContent_branch_owner {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
}

export interface AuditsHalfContent_globalDashboard_auditsHalfContent_branch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The owner (entity) of the address
   */
  owner: AuditsHalfContent_globalDashboard_auditsHalfContent_branch_owner | null;
}

export interface AuditsHalfContent_globalDashboard_auditsHalfContent_previousAudits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
}

export interface AuditsHalfContent_globalDashboard_auditsHalfContent {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The amount of inspections with situation Sold
   */
  soldAmount: number | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
  /**
   * The amount of inspections in the audit
   */
  inspectionsAmount: number | null;
  /**
   * The branch of the audit
   */
  branch: AuditsHalfContent_globalDashboard_auditsHalfContent_branch;
  /**
   * The last previous audits
   */
  previousAudits: AuditsHalfContent_globalDashboard_auditsHalfContent_previousAudits[];
}

export interface AuditsHalfContent_globalDashboard {
  __typename: "GlobalDashboard";
  /**
   * Audits with rating half_content
   */
  auditsHalfContent: AuditsHalfContent_globalDashboard_auditsHalfContent[];
}

export interface AuditsHalfContent {
  /**
   * Returns statistics of all attached child entities
   */
  globalDashboard: AuditsHalfContent_globalDashboard | null;
}

export interface AuditsHalfContentVariables {
  entities?: string[] | null;
  date?: DateRangeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AuditsNotContent
// ====================================================

export interface AuditsNotContent_globalDashboard_auditsNotContent_branch_owner {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
}

export interface AuditsNotContent_globalDashboard_auditsNotContent_branch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The owner (entity) of the address
   */
  owner: AuditsNotContent_globalDashboard_auditsNotContent_branch_owner | null;
}

export interface AuditsNotContent_globalDashboard_auditsNotContent_previousAudits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
}

export interface AuditsNotContent_globalDashboard_auditsNotContent {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The amount of inspections with situation Sold
   */
  soldAmount: number | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
  /**
   * The amount of inspections in the audit
   */
  inspectionsAmount: number | null;
  /**
   * The branch of the audit
   */
  branch: AuditsNotContent_globalDashboard_auditsNotContent_branch;
  /**
   * The last previous audits
   */
  previousAudits: AuditsNotContent_globalDashboard_auditsNotContent_previousAudits[];
}

export interface AuditsNotContent_globalDashboard {
  __typename: "GlobalDashboard";
  /**
   * Audits with rating not_content
   */
  auditsNotContent: AuditsNotContent_globalDashboard_auditsNotContent[];
}

export interface AuditsNotContent {
  /**
   * Returns statistics of all attached child entities
   */
  globalDashboard: AuditsNotContent_globalDashboard | null;
}

export interface AuditsNotContentVariables {
  entities?: string[] | null;
  date?: DateRangeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AuditsSold
// ====================================================

export interface AuditsSold_globalDashboard_auditsSoldSituation_branch_owner {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
}

export interface AuditsSold_globalDashboard_auditsSoldSituation_branch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The owner (entity) of the address
   */
  owner: AuditsSold_globalDashboard_auditsSoldSituation_branch_owner | null;
}

export interface AuditsSold_globalDashboard_auditsSoldSituation_previousAudits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
}

export interface AuditsSold_globalDashboard_auditsSoldSituation {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The amount of inspections with situation Sold
   */
  soldAmount: number | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
  /**
   * The amount of inspections in the audit
   */
  inspectionsAmount: number | null;
  /**
   * The branch of the audit
   */
  branch: AuditsSold_globalDashboard_auditsSoldSituation_branch;
  /**
   * The last previous audits
   */
  previousAudits: AuditsSold_globalDashboard_auditsSoldSituation_previousAudits[];
}

export interface AuditsSold_globalDashboard {
  __typename: "GlobalDashboard";
  /**
   * Audits with inspections with situation sold
   */
  auditsSoldSituation: AuditsSold_globalDashboard_auditsSoldSituation[];
}

export interface AuditsSold {
  /**
   * Returns statistics of all attached child entities
   */
  globalDashboard: AuditsSold_globalDashboard | null;
}

export interface AuditsSoldVariables {
  entities?: string[] | null;
  date?: DateRangeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GlobalDashboard
// ====================================================

export interface GlobalDashboard_globalDashboard_statistics_audits {
  __typename: "GlobalDashboardAuditStatistics";
  /**
   * The amount of audits performed
   */
  amountPerformed: number;
  /**
   * The amount of audits planned
   */
  amountPlanned: number;
  /**
   * The amount of audits with rating NOT_CONTENT
   */
  amountNotContent: number;
  /**
   * The amount of audits with rating HALF_CONTENT
   */
  amountHalfContent: number;
  /**
   * The amount of audits with rating CONTENT
   */
  amountContent: number;
}

export interface GlobalDashboard_globalDashboard_statistics_inspections {
  __typename: "GlobalDashboardInspectionStatistics";
  /**
   * The amount of audits performed
   */
  amountPerformed: number;
  /**
   * The amount of audits planned
   */
  amountPlanned: number;
  /**
   * The amount of inspection with anomalies
   */
  amountWithAnomalies: number;
  /**
   * The amount of inspections that are declined
   */
  amountDeclined: number;
  /**
   * The amount of inspections that are accepted
   */
  amountAccepted: number;
  /**
   * The amount of inspections that are expired
   */
  amountExpired: number;
  /**
   * The amount of inspections with situation Sold
   */
  amountSold: number;
}

export interface GlobalDashboard_globalDashboard_statistics_anomalies {
  __typename: "GlobalDashboardAnomalyStatistics";
  /**
   * The amount of Sold Date anomalies
   */
  amountSoldDate: number;
  /**
   * The amount of Demo anomalies
   */
  amountDemo: number;
  /**
   * The amount of Rental anomalies
   */
  amountRental: number;
  /**
   * The amount of Picture in Picture anomalies
   */
  amountPiP: number;
}

export interface GlobalDashboard_globalDashboard_statistics {
  __typename: "GlobalDashboardStatistics";
  /**
   * Global dashboard audits statistics
   */
  audits: GlobalDashboard_globalDashboard_statistics_audits;
  /**
   * Global dashboard audits statistics
   */
  inspections: GlobalDashboard_globalDashboard_statistics_inspections;
  /**
   * Global dashboard audits statistics
   */
  anomalies: GlobalDashboard_globalDashboard_statistics_anomalies;
}

export interface GlobalDashboard_globalDashboard {
  __typename: "GlobalDashboard";
  /**
   * Audits with rating not_content
   */
  statistics: GlobalDashboard_globalDashboard_statistics;
}

export interface GlobalDashboard {
  /**
   * Returns statistics of all attached child entities
   */
  globalDashboard: GlobalDashboard_globalDashboard | null;
}

export interface GlobalDashboardVariables {
  entities?: string[] | null;
  date?: DateRangeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InspectionType
// ====================================================

export interface InspectionType_inspectionType {
  __typename: "InspectionType";
  /**
   * The id of the inspection type
   */
  id: string;
  /**
   * The start time of the inspection type
   */
  startTime: string | null;
  /**
   * The due time of the inspection type
   */
  dueTime: string | null;
}

export interface InspectionType {
  /**
   * Get inspection type
   */
  inspectionType: InspectionType_inspectionType;
}

export interface InspectionTypeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InspectionTypes
// ====================================================

export interface InspectionTypes_paginateInspectionTypes_data {
  __typename: "InspectionType";
  /**
   * The id of the inspection type
   */
  id: string;
  /**
   * The name of the inspection type
   */
  name: string;
}

export interface InspectionTypes_paginateInspectionTypes {
  __typename: "inspectionTypePagination";
  /**
   * List of items on the current page
   */
  data: (InspectionTypes_paginateInspectionTypes_data | null)[] | null;
}

export interface InspectionTypes {
  /**
   * Paginate all available inspection types
   */
  paginateInspectionTypes: InspectionTypes_paginateInspectionTypes | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllGripResources
// ====================================================

export interface AllGripResources_allGripResources_predictions_parameter {
  __typename: "GripResourceParameterType";
  /**
   * The Grip Resource ID
   */
  id: string;
  /**
   * The parameter name
   */
  name: string;
  /**
   * The parameter type
   */
  type: string;
}

export interface AllGripResources_allGripResources_predictions {
  __typename: "GripResourcePredictionType";
  /**
   * The confidence of the prediction
   */
  confidence: number | null;
  /**
   * The option_id of the prediction
   */
  option_id: number | null;
  /**
   * The value of the prediction
   */
  value: string | null;
  /**
   * The Grip Resource ID
   */
  parameter: AllGripResources_allGripResources_predictions_parameter;
}

export interface AllGripResources_allGripResources {
  __typename: "GripResourceType";
  /**
   * The Grip Resource ID
   */
  id: string;
  /**
   * The Grip Resource external_id. This is the id of the object_image in inspection-backoffice api
   */
  external_id: string;
  /**
   * The Grip Resource ID
   */
  predictions: AllGripResources_allGripResources_predictions[];
}

export interface AllGripResources {
  /**
   * Query to get all Grip Resources predictions for a specific inspection ID
   */
  allGripResources: AllGripResources_allGripResources[] | null;
}

export interface AllGripResourcesVariables {
  iid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckExternalId
// ====================================================

export interface CheckExternalId_paginateInspections {
  __typename: "inspectionPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
}

export interface CheckExternalId {
  /**
   * Get paged inspections
   */
  paginateInspections: CheckExternalId_paginateInspections | null;
}

export interface CheckExternalIdVariables {
  externalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CreateInspectionData
// ====================================================

export interface CreateInspectionData_paginateInspectionTypes_data {
  __typename: "InspectionType";
  /**
   * The id of the inspection type
   */
  id: string;
  /**
   * The name of the inspection type
   */
  name: string;
  /**
   * Whether a make and type is required to create an inspection for this type
   */
  makeTypeRequired: boolean;
  /**
   * Whether a group is required to create an inspection for this type
   */
  groupRequired: boolean;
  /**
   * Whether a dealer connection is required to create an inspection for this type
   */
  dealerRequired: boolean;
}

export interface CreateInspectionData_paginateInspectionTypes {
  __typename: "inspectionTypePagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * List of items on the current page
   */
  data: (CreateInspectionData_paginateInspectionTypes_data | null)[] | null;
}

export interface CreateInspectionData_paginateFlows_data_inspectionType {
  __typename: "InspectionType";
  /**
   * The id of the inspection type
   */
  id: string;
  /**
   * The name of the inspection type
   */
  name: string;
  /**
   * Whether a make and type is required to create an inspection for this type
   */
  makeTypeRequired: boolean;
  /**
   * Whether a group is required to create an inspection for this type
   */
  groupRequired: boolean;
  /**
   * Whether a dealer connection is required to create an inspection for this type
   */
  dealerRequired: boolean;
}

export interface CreateInspectionData_paginateFlows_data_group {
  __typename: "Group";
  /**
   * The id of the group
   */
  id: string;
  /**
   * The name of the productgroup
   */
  name: string | null;
}

export interface CreateInspectionData_paginateFlows_data {
  __typename: "InspectionFlow";
  /**
   * The id of the inspection flow
   */
  id: string;
  /**
   * Shows if this is an entity flow or a flow that belongs to an inspection you own
   */
  isEntityFlow: boolean | null;
  /**
   * The inspection type of the flow
   */
  inspectionType: CreateInspectionData_paginateFlows_data_inspectionType | null;
  /**
   * The product group of the flow
   */
  group: CreateInspectionData_paginateFlows_data_group | null;
}

export interface CreateInspectionData_paginateFlows {
  __typename: "flowPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * List of items on the current page
   */
  data: (CreateInspectionData_paginateFlows_data | null)[] | null;
}

export interface CreateInspectionData {
  /**
   * Paginate all available inspection types
   */
  paginateInspectionTypes: CreateInspectionData_paginateInspectionTypes | null;
  /**
   * Get paged flows
   */
  paginateFlows: CreateInspectionData_paginateFlows | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Inspection
// ====================================================

export interface Inspection_inspection_inspectionType {
  __typename: "InspectionType";
  /**
   * The id of the inspection type
   */
  id: string;
  /**
   * The name of the inspection type
   */
  name: string;
}

export interface Inspection_inspection_asset_makeModel_make {
  __typename: "Make";
  /**
   * The id of the make
   */
  id: string;
  /**
   * The name of the make
   */
  name: string | null;
}

export interface Inspection_inspection_asset_makeModel {
  __typename: "MakeModel";
  /**
   * The id of the makeModel
   */
  id: string;
  /**
   * The name of the makeModel
   */
  name: string | null;
  /**
   * The make of this makeModel
   */
  make: Inspection_inspection_asset_makeModel_make | null;
}

export interface Inspection_inspection_asset_group {
  __typename: "Group";
  /**
   * The id of the group
   */
  id: string;
  /**
   * The name of the productgroup
   */
  name: string | null;
}

export interface Inspection_inspection_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface Inspection_inspection_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * The license plate of the asset
   */
  licensePlate: string | null;
  /**
   * The year of manufacture of the asset
   */
  yearOfManufacture: number | null;
  /**
   * The makeModel of this asset
   */
  makeModel: Inspection_inspection_asset_makeModel | null;
  /**
   * The product group of this asset
   */
  group: Inspection_inspection_asset_group | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (Inspection_inspection_asset_meta | null)[] | null;
}

export interface Inspection_inspection_images {
  __typename: "InspectionImage";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The filename of the image
   */
  filename: string | null;
  /**
   * The URL to the thumbnail image
   */
  thumb_url: string | null;
  /**
   * The URL to the original image
   */
  original_url: string | null;
  /**
   * Show Recapture InspectionChecks in the front-end
   */
  showRecaptureCheck: boolean | null;
}

export interface Inspection_inspection_logs_device {
  __typename: "Device";
  /**
   * The device identifier
   */
  id: string;
  /**
   * The name of the device
   */
  name: string | null;
  /**
   * The device brand
   */
  brand: string | null;
  /**
   * The device model
   */
  model: string | null;
}

export interface Inspection_inspection_logs {
  __typename: "InspectionLog";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * Accuracy of the coordinates
   */
  accuracy: number;
  /**
   * Latitude of the event
   */
  latitude: number;
  /**
   * Longitude of the event
   */
  longitude: number;
  /**
   * Distance of the coordinates to the address
   */
  distance: number | null;
  /**
   * The device that produced the log
   */
  device: Inspection_inspection_logs_device | null;
}

export interface Inspection_inspection_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The phone number of the account
   */
  phone: string | null;
}

export interface Inspection_inspection_address_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The name of the dealer
   */
  name: string | null;
}

export interface Inspection_inspection_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The entity this address is attached to
   */
  dealer: Inspection_inspection_address_dealer | null;
}

export interface Inspection_inspection_contact {
  __typename: "Contact";
  /**
   * The id of the contact
   */
  id: string;
  /**
   * The first name of the contact
   */
  firstName: string | null;
  /**
   * The last name of the contact
   */
  lastName: string | null;
  /**
   * The email address of the contact
   */
  email: string | null;
  /**
   * The land line phone number of the contact
   */
  phone: string | null;
  /**
   * The mobile phone number of the contact
   */
  mobile: string | null;
}

export interface Inspection_inspection_accountManager {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The phone number of the account
   */
  phone: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
}

export interface Inspection_inspection_syncJob_statistics_device {
  __typename: "Device";
  /**
   * The device identifier
   */
  id: string;
  /**
   * The name of the device
   */
  name: string | null;
  /**
   * The os on the device
   */
  systemOs: string | null;
}

export interface Inspection_inspection_syncJob_statistics {
  __typename: "SyncJobStatistics";
  /**
   * The id of the sync job statistics
   */
  id: string;
  /**
   * The IP Address used to report statistics from
   */
  ipAddress: string | null;
  /**
   * The network state of the device
   */
  networkState: NetworkStateEnum | null;
  /**
   * The network carrier used
   */
  networkCarrier: string | null;
  /**
   * The generation of cellular connection used
   */
  networkCellularGeneration: string | null;
  /**
   * The device battery state
   */
  batteryState: BatteryStateEnum | null;
  /**
   * The device battery level
   */
  batteryLevel: number | null;
  /**
   * The amount of free disk space in megabytes
   */
  freeDiskStorage: number | null;
  /**
   * The amount of used memory in megabytes
   */
  usedMemory: number | null;
  /**
   * Whether the app is configured to upload media over cellular
   */
  mediaOverCellular: boolean | null;
  /**
   * The device the statistics are reported from
   */
  device: Inspection_inspection_syncJob_statistics_device;
}

export interface Inspection_inspection_syncJob {
  __typename: "SyncJob";
  /**
   * The id of the sync job
   */
  id: string;
  /**
   * The status of a sync job
   */
  status: SyncJobStatusEnum | null;
  /**
   * The total number of media in the sync job
   */
  numberOfMedia: number | null;
  /**
   * The total number of requests in the sync job
   */
  numberOfRequests: number | null;
  /**
   * The total number of requests in the sync job
   */
  numberOfRequestsDone: number | null;
  /**
   * The total number of images in the sync job
   */
  numberOfImages: number | null;
  /**
   * The total number of uploaded images in the sync job
   */
  numberOfImagesDone: number | null;
  /**
   * The total number of videos in the sync job
   */
  numberOfVideos: number | null;
  /**
   * The total number of uploaded videos in the sync job
   */
  numberOfVideosDone: number | null;
  /**
   * The total number of data fields in the sync job
   */
  numberOfFields: number | null;
  /**
   * The estimated time (based on previous upload speed) remaining to finish sync, in seconds
   */
  estimatedTimeRemaining: number | null;
  /**
   * The model updated_at date
   */
  updatedAt: string | null;
  /**
   * The last known statistics of the sync job
   */
  statistics: Inspection_inspection_syncJob_statistics | null;
}

export interface Inspection_inspection_checks {
  __typename: "InspectionCheck";
  /**
   * The id of the inspection warning
   */
  id: string;
  /**
   * The warning severity namespace
   */
  namespace: string;
  /**
   * The warning severity level
   */
  level: string;
  /**
   * The warning type
   */
  message: string;
  /**
   * The serialised (JSON) information about this warning
   */
  attributes: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
}

export interface Inspection_inspection_approvals_creator {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface Inspection_inspection_approvals_reasons {
  __typename: "InspectionApprovalReason";
  /**
   * The id of the approval reason
   */
  id: string;
  /**
   * The approval reason
   */
  name: string | null;
}

export interface Inspection_inspection_approvals {
  __typename: "InspectionApproval";
  /**
   * The id of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  state: InspectionApprovalState;
  /**
   * The user responsible for the approval
   */
  creator: Inspection_inspection_approvals_creator;
  /**
   * The remark attached to the approval
   */
  remark: string | null;
  /**
   * The reasons for this approval
   */
  reasons: Inspection_inspection_approvals_reasons[];
  /**
   * The model created_at date
   */
  createdAt: string | null;
}

export interface Inspection_inspection_transfers_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface Inspection_inspection_transfers_address {
  __typename: "Address";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
}

export interface Inspection_inspection_transfers {
  __typename: "InspectionTransfer";
  /**
   * The user targeted as the inspector
   */
  inspector: Inspection_inspection_transfers_inspector;
  /**
   * The product group of the flow
   */
  address: Inspection_inspection_transfers_address | null;
  /**
   * The transfer status
   */
  status: InspectionTransferStatus;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * The model updated_at date
   */
  updatedAt: string | null;
}

export interface Inspection_inspection_sourceInspection {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
}

export interface Inspection_inspection_relatedInspections_inspectionType {
  __typename: "InspectionType";
  /**
   * The id of the inspection type
   */
  id: string;
  /**
   * The name of the inspection type
   */
  name: string;
}

export interface Inspection_inspection_relatedInspections_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface Inspection_inspection_relatedInspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The thumbnail of the inspection
   */
  thumb: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The date the inspection got assigned the status
   */
  statusDate: string | null;
  /**
   * The inspection equipment situation
   */
  equipmentSituation: EquipmentSituation | null;
  /**
   * The amount of inconsistency warnings for this inspection
   */
  warningsAmount: number | null;
  /**
   * The inspection equipment situation
   */
  inspectionType: Inspection_inspection_relatedInspections_inspectionType | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: Inspection_inspection_relatedInspections_inspector | null;
}

export interface Inspection_inspection_parameterValues_remarks {
  __typename: "InspectionParameterValueRemark";
  /**
   * The id of the inspection parameter value remark
   */
  id: string;
  /**
   * The content of the remark
   */
  message: string | null;
}

export interface Inspection_inspection_parameterValues_images_checks {
  __typename: "InspectionCheck";
  /**
   * The id of the inspection warning
   */
  id: string;
  /**
   * The warning severity namespace
   */
  namespace: string;
  /**
   * The warning severity level
   */
  level: string;
  /**
   * The warning type
   */
  message: string;
  /**
   * The serialised (JSON) information about this warning
   */
  attributes: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
}

export interface Inspection_inspection_parameterValues_images {
  __typename: "InspectionImage";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The URL to the thumbnail image
   */
  thumb_url: string | null;
  /**
   * The URL to the original image
   */
  original_url: string | null;
  /**
   * Show Recapture InspectionChecks in the front-end
   */
  showRecaptureCheck: boolean | null;
  checks: (Inspection_inspection_parameterValues_images_checks | null)[] | null;
}

export interface Inspection_inspection_parameterValues_damages_image {
  __typename: "Image";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The URL to the thumbnail image
   */
  thumb_url: string | null;
  /**
   * The URL to the original image
   */
  original_url: string | null;
}

export interface Inspection_inspection_parameterValues_damages {
  __typename: "InspectionParameterValueDamage";
  /**
   * The id of the inspection parameter value remark
   */
  id: string;
  /**
   * The content of the remark
   */
  message: string | null;
  /**
   * Indicated costs for the specified damage
   */
  costs: number | null;
  /**
   * Boolean to indicate if the damage costs should be passed on to the lessee
   */
  costsForLessee: boolean | null;
  /**
   * An image containing the damage
   */
  image: Inspection_inspection_parameterValues_damages_image | null;
}

export interface Inspection_inspection_parameterValues_parameterGroup {
  __typename: "ParameterGroup";
  /**
   * The id of the parameter group
   */
  id: string;
}

export interface Inspection_inspection_parameterValues_parameter_parameterOptions {
  __typename: "ParameterOption";
  /**
   * The id of the parameter option
   */
  id: string;
  /**
   * The name of the parameter option
   */
  value: string | null;
}

export interface Inspection_inspection_parameterValues_parameter {
  __typename: "Parameter";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The name of the parameter
   */
  name: string | null;
  /**
   * The type of the parameter
   */
  type: string | null;
  /**
   * The metric of the parameter (KM, KG)
   */
  metric: string | null;
  /**
   * The available options for this parameter
   */
  parameterOptions: (Inspection_inspection_parameterValues_parameter_parameterOptions | null)[] | null;
}

export interface Inspection_inspection_parameterValues_address {
  __typename: "Address";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The second line of the address
   */
  addressLine2: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
}

export interface Inspection_inspection_parameterValues {
  __typename: "InspectionParameterValue";
  /**
   * The id of the parameter value
   */
  id: string;
  /**
   * The value for the parameter (attribute)
   */
  value: string | null;
  /**
   * Whether the parameter value has been declined or not
   */
  declined: boolean | null;
  /**
   * Additional remarks added to the parameter value
   */
  remarks: (Inspection_inspection_parameterValues_remarks | null)[] | null;
  /**
   * Images attached to the parameter value
   */
  images: (Inspection_inspection_parameterValues_images | null)[] | null;
  /**
   * Additional damages added to the parameter value
   */
  damages: (Inspection_inspection_parameterValues_damages | null)[] | null;
  /**
   * The parameter group this definition belongs to. The same parameter can have
   * multiple values based on a group. For example parameter 'Tyre Profile' can
   * have a value for parameter group 'Left front' or 'Right back' and so on.
   */
  parameterGroup: Inspection_inspection_parameterValues_parameterGroup | null;
  /**
   * The parameter definition this value is provided for
   */
  parameter: Inspection_inspection_parameterValues_parameter | null;
  /**
   * Address attached to the parameter value
   */
  address: Inspection_inspection_parameterValues_address | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
}

export interface Inspection_inspection_holds_creator {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface Inspection_inspection_holds {
  __typename: "InspectionHold";
  /**
   * The id of the inspection hold
   */
  id: string;
  /**
   * The message of the inspection hold
   */
  message: string;
  /**
   * The user responsible for the inspection hold
   */
  creator: Inspection_inspection_holds_creator | null;
  /**
   * The model resolved_at date
   */
  resolvedAt: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The model updated_at date
   */
  updatedAt: string | null;
}

export interface Inspection_inspection_appraisals_appraisalType {
  __typename: "AppraisalType";
  /**
   * The id of the appraisal type
   */
  id: string;
  /**
   * The name of the appraisal type
   */
  name: string;
  /**
   * Wether the appraisal ran automatically (e.g. by a system)
   */
  automatic: boolean;
}

export interface Inspection_inspection_appraisals_appraiser {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface Inspection_inspection_appraisals_valuationAgency {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
}

export interface Inspection_inspection_appraisals_report {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * The url of the file
   */
  url: string;
  /**
   * File name is an internal note
   */
  fileName: string;
}

export interface Inspection_inspection_appraisals {
  __typename: "Appraisal";
  /**
   * The id of the appraisal
   */
  id: string;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The appraisal type of the appraisal
   */
  appraisalType: Inspection_inspection_appraisals_appraisalType;
  /**
   * The value of the appraisal
   */
  value: string | null;
  /**
   * The Appraser of the appraisal
   */
  appraiser: Inspection_inspection_appraisals_appraiser | null;
  /**
   * The valuation agency of the appraisal
   */
  valuationAgency: Inspection_inspection_appraisals_valuationAgency | null;
  /**
   * The report of the appraisal
   */
  report: Inspection_inspection_appraisals_report | null;
}

export interface Inspection_inspection_flow_parameterCategories_parameterGroups_parameters_options {
  __typename: "ParameterOption";
  /**
   * The id of the parameter option
   */
  id: string;
  /**
   * The name of the parameter option
   */
  value: string | null;
}

export interface Inspection_inspection_flow_parameterCategories_parameterGroups_parameters {
  __typename: "FlowParameter";
  /**
   * The id of the flow parameter
   */
  id: string;
  /**
   * The name of the flow parameter
   */
  name: string | null;
  /**
   * The type of the flow parameter
   */
  type: string | null;
  /**
   * The metric of the flow parameter
   */
  metric: string | null;
  /**
   * The available options for this parameter
   */
  options: (Inspection_inspection_flow_parameterCategories_parameterGroups_parameters_options | null)[] | null;
  /**
   * The parameter pid of the flow parameter
   */
  parameterPid: string | null;
}

export interface Inspection_inspection_flow_parameterCategories_parameterGroups {
  __typename: "FlowParameterGroup";
  /**
   * The id of the flow parameter group
   */
  id: string;
  /**
   * The name of the flow parameter group
   */
  name: string | null;
  /**
   * The parameter group pgid of the flow parameter group
   */
  parameterGroupPgid: string | null;
  /**
   * The view type of the flow parameter group
   */
  viewType: string | null;
  /**
   * The parameters of the flow, e.g. Colour, Size, Airconditioning
   */
  parameters: (Inspection_inspection_flow_parameterCategories_parameterGroups_parameters | null)[] | null;
}

export interface Inspection_inspection_flow_parameterCategories {
  __typename: "FlowParameterCategory";
  /**
   * The id of the flow parameter category
   */
  id: string;
  /**
   * The name of the flow parameter category
   */
  name: string | null;
  /**
   * The parameter category pcid of the flow parameter category
   */
  parameterCategoryPcid: string | null;
  /**
   * The view type of the flow parameter category
   */
  viewType: string | null;
  /**
   * The parameter groups of the flow, e.g. Tyres Left, Tyres Right
   */
  parameterGroups: (Inspection_inspection_flow_parameterCategories_parameterGroups | null)[] | null;
}

export interface Inspection_inspection_flow {
  __typename: "InspectionFlow";
  /**
   * The id of the inspection flow
   */
  id: string;
  /**
   * The parameter categories of the flow
   */
  parameterCategories: (Inspection_inspection_flow_parameterCategories | null)[] | null;
}

export interface Inspection_inspection_notes_creator {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface Inspection_inspection_notes_file {
  __typename: "Media";
  /**
   * The id of the media
   */
  id: string;
  /**
   * File name is an internal note
   */
  fileName: string;
  /**
   * The url of the file
   */
  url: string;
}

export interface Inspection_inspection_notes {
  __typename: "Note";
  /**
   * The id of the note
   */
  id: string;
  /**
   * The text of the note
   */
  note: string;
  /**
   * If the note is an internal note
   */
  internal: boolean;
  /**
   * The creator of the note
   */
  creator: Inspection_inspection_notes_creator;
  /**
   * The file added to the media
   */
  file: Inspection_inspection_notes_file | null;
  /**
   * The model created at date
   */
  createdAt: string | null;
}

export interface Inspection_inspection {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The external ID, a reference used by the customer to identify the inspection
   */
  externalId: string | null;
  /**
   * The inspection equipment situation
   */
  equipmentSituation: EquipmentSituation | null;
  /**
   * A message containing global information about this inspection e.g. please phone the contact at the gate
   */
  remark: string | null;
  /**
   * Shows if the inspection is trusted
   */
  trusted: boolean | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model started_at date
   */
  startedAt: string | null;
  /**
   * The model finished_at date
   */
  finishedAt: string | null;
  /**
   * The model submitted_at date
   */
  submittedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model automatically_accepted_at date
   */
  automaticallyAcceptedAt: string | null;
  /**
   * The inspection equipment situation
   */
  inspectionType: Inspection_inspection_inspectionType | null;
  /**
   * The asset the inspection belongs to
   */
  asset: Inspection_inspection_asset | null;
  /**
   * The inspection images
   */
  images: (Inspection_inspection_images | null)[] | null;
  /**
   * The inspection logs for this inspection
   */
  logs: (Inspection_inspection_logs | null)[] | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: Inspection_inspection_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: Inspection_inspection_address | null;
  /**
   * The contact of the inspection
   */
  contact: Inspection_inspection_contact | null;
  /**
   * The account manager of the inspection
   */
  accountManager: Inspection_inspection_accountManager | null;
  /**
   * The last sync job of the inspection
   */
  syncJob: Inspection_inspection_syncJob | null;
  /**
   * Inconsistency checks for this inspection
   */
  checks: Inspection_inspection_checks[] | null;
  /**
   * The accept of decline history of the inspection
   */
  approvals: Inspection_inspection_approvals[];
  /**
   * The transfer trail of this inspection
   */
  transfers: (Inspection_inspection_transfers | null)[] | null;
  /**
   * The source inspection this inspection is based upon
   */
  sourceInspection: Inspection_inspection_sourceInspection | null;
  relatedInspections: Inspection_inspection_relatedInspections[] | null;
  /**
   * The inspection filled parameters (attributes)
   */
  parameterValues: (Inspection_inspection_parameterValues | null)[] | null;
  /**
   * The hold trail of this inspection
   */
  holds: Inspection_inspection_holds[];
  /**
   * The appraisals for this inspection
   */
  appraisals: (Inspection_inspection_appraisals | null)[] | null;
  /**
   * The inspection flow of the inspection
   */
  flow: Inspection_inspection_flow | null;
  /**
   * The notes of an inspection
   */
  notes: (Inspection_inspection_notes | null)[] | null;
}

export interface Inspection {
  /**
   * Get a specific inspection identified by an InspectionID
   */
  inspection: Inspection_inspection | null;
}

export interface InspectionVariables {
  id: string;
  displayType: string;
  entityOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateInspections
// ====================================================

export interface PaginateInspections_paginateInspections_data_type {
  __typename: "InspectionType";
  /**
   * The id of the inspection type
   */
  id: string;
  /**
   * The name of the inspection type
   */
  name: string;
}

export interface PaginateInspections_paginateInspections_data_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface PaginateInspections_paginateInspections_data_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * The license plate of the asset
   */
  licensePlate: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (PaginateInspections_paginateInspections_data_asset_meta | null)[] | null;
}

export interface PaginateInspections_paginateInspections_data_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface PaginateInspections_paginateInspections_data_address_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
}

export interface PaginateInspections_paginateInspections_data_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The entity this address is attached to
   */
  dealer: PaginateInspections_paginateInspections_data_address_dealer | null;
}

export interface PaginateInspections_paginateInspections_data_flow {
  __typename: "InspectionFlow";
  /**
   * The id of the inspection flow
   */
  id: string;
}

export interface PaginateInspections_paginateInspections_data_parameterValues {
  __typename: "InspectionParameterValue";
  /**
   * The id of the parameter value
   */
  id: string;
}

export interface PaginateInspections_paginateInspections_data_checks {
  __typename: "InspectionCheck";
  /**
   * The id of the inspection warning
   */
  id: string;
  /**
   * The warning severity namespace
   */
  namespace: string;
}

export interface PaginateInspections_paginateInspections_data_appraisals {
  __typename: "Appraisal";
  /**
   * The id of the appraisal
   */
  id: string;
  /**
   * The value of the appraisal
   */
  value: string | null;
}

export interface PaginateInspections_paginateInspections_data {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The thumbnail of the inspection
   */
  thumb: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The score of the inspection
   */
  score: number | null;
  /**
   * The external ID, a reference used by the customer to identify the inspection
   */
  externalId: string | null;
  /**
   * Shows if the inspection is trusted
   */
  trusted: boolean | null;
  /**
   * The type (sort) of the inspection, for example a full inspection, commercial inspection, etc.
   */
  type: PaginateInspections_paginateInspections_data_type | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model finished_at date
   */
  finishedAt: string | null;
  /**
   * The model submitted_at date
   */
  submittedAt: string | null;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The inspection equipment situation
   */
  equipmentSituation: EquipmentSituation | null;
  /**
   * Whether the inspection has an approval remark or not
   */
  hasApprovalRemark: boolean;
  /**
   * The amount of anomalies for this inspection
   */
  anomaliesAmount: number | null;
  /**
   * The asset the inspection belongs to
   */
  asset: PaginateInspections_paginateInspections_data_asset | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: PaginateInspections_paginateInspections_data_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: PaginateInspections_paginateInspections_data_address | null;
  /**
   * The inspection flow of the inspection
   */
  flow: PaginateInspections_paginateInspections_data_flow | null;
  /**
   * The inspection filled parameters (attributes)
   */
  parameterValues: (PaginateInspections_paginateInspections_data_parameterValues | null)[] | null;
  /**
   * Inconsistency checks for this inspection
   */
  checks: PaginateInspections_paginateInspections_data_checks[] | null;
  /**
   * The appraisals for this inspection
   */
  appraisals: (PaginateInspections_paginateInspections_data_appraisals | null)[] | null;
}

export interface PaginateInspections_paginateInspections {
  __typename: "inspectionPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateInspections_paginateInspections_data | null)[] | null;
}

export interface PaginateInspections {
  /**
   * Get paged inspections
   */
  paginateInspections: PaginateInspections_paginateInspections | null;
}

export interface PaginateInspectionsVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  status?: (InspectionStatus | null)[] | null;
  trusted?: boolean | null;
  equipmentSituation?: (EquipmentSituation | null)[] | null;
  accountManagerIds?: string[] | null;
  date?: DateRangeInput | null;
  anomaly?: boolean | null;
  dealerIds?: (string | null)[] | null;
  dealerId?: string | null;
  branchId?: string | null;
  inspectorId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateDealers
// ====================================================

export interface PaginateDealers_paginateDealers_data_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface PaginateDealers_paginateDealers_data {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The name of the dealer
   */
  name: string | null;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
  /**
   * The amount of branches attached to the dealer
   */
  branchesAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  openInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  closedInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  inspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  foundInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  notFoundInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  expiredInspectionsAmount: number;
  /**
   * The amount of inspections attached to the dealer
   */
  inspectionsWithWarningsAmount: number;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (PaginateDealers_paginateDealers_data_meta | null)[] | null;
}

export interface PaginateDealers_paginateDealers {
  __typename: "dealerPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateDealers_paginateDealers_data | null)[] | null;
}

export interface PaginateDealers {
  /**
   * Get paged dealers
   */
  paginateDealers: PaginateDealers_paginateDealers | null;
}

export interface PaginateDealersVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  date?: DateRangeInput | null;
  hasInspections?: boolean | null;
  accountManagerIds?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateUnreadUserHelpArticles
// ====================================================

export interface PaginateUnreadUserHelpArticles_paginateUnreadUserHelpArticles_data {
  __typename: "UserHelpArticle";
  /**
   * The ID of the article
   */
  id: string;
}

export interface PaginateUnreadUserHelpArticles_paginateUnreadUserHelpArticles {
  __typename: "UserHelpArticlePagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * List of items on the current page
   */
  data: (PaginateUnreadUserHelpArticles_paginateUnreadUserHelpArticles_data | null)[] | null;
}

export interface PaginateUnreadUserHelpArticles {
  /**
   * Get the unread user help articles of a user
   */
  paginateUnreadUserHelpArticles: PaginateUnreadUserHelpArticles_paginateUnreadUserHelpArticles;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateUserHelpCategories
// ====================================================

export interface PaginateUserHelpCategories_paginateUserHelpCategories_data {
  __typename: "UserHelpCategory";
  /**
   * The ID of the category
   */
  id: string;
  /**
   * The name of the category
   */
  name: string;
  /**
   * The type the category
   */
  type: string;
}

export interface PaginateUserHelpCategories_paginateUserHelpCategories {
  __typename: "UserHelpCategoryPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * List of items on the current page
   */
  data: (PaginateUserHelpCategories_paginateUserHelpCategories_data | null)[] | null;
}

export interface PaginateUserHelpCategories {
  /**
   * A paginated view of all the User Help Categories
   */
  paginateUserHelpCategories: PaginateUserHelpCategories_paginateUserHelpCategories;
}

export interface PaginateUserHelpCategoriesVariables {
  page: number;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchUserHelpCategories
// ====================================================

export interface SearchUserHelpCategories_searchUserHelp_UserHelpArticle {
  __typename: "UserHelpArticle";
  /**
   * The ID of the article
   */
  id: string;
  /**
   * The title of the article
   */
  title: string;
  /**
   * The teaser of the article (no HTML and ellipsised after 160 characters)
   */
  teaser: string;
  /**
   * The tags enabling easy searching
   */
  tags: string[];
}

export interface SearchUserHelpCategories_searchUserHelp_UserHelpVideo {
  __typename: "UserHelpVideo";
  /**
   * The ID of the video
   */
  id: string;
  /**
   * The title of the video
   */
  title: string;
  /**
   * The url to the video
   */
  url: string;
  /**
   * The tags enabling easy searching
   */
  tags: string[];
}

export interface SearchUserHelpCategories_searchUserHelp_UserHelpFAQ {
  __typename: "UserHelpFAQ";
  /**
   * The ID of the FAQ
   */
  id: string;
  /**
   * The question to be aswered
   */
  question: string;
  /**
   * The answer to the question
   */
  answer: string;
  /**
   * The tags enabling easy searching
   */
  tags: string[];
}

export type SearchUserHelpCategories_searchUserHelp = SearchUserHelpCategories_searchUserHelp_UserHelpArticle | SearchUserHelpCategories_searchUserHelp_UserHelpVideo | SearchUserHelpCategories_searchUserHelp_UserHelpFAQ;

export interface SearchUserHelpCategories {
  /**
   * Search the user help system for specific resources
   */
  searchUserHelp: SearchUserHelpCategories_searchUserHelp[];
}

export interface SearchUserHelpCategoriesVariables {
  query: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserHelpArticle
// ====================================================

export interface UserHelpArticle_userHelpArticle_category {
  __typename: "UserHelpCategory";
  /**
   * The ID of the category
   */
  id: string;
  /**
   * The name of the category
   */
  name: string;
}

export interface UserHelpArticle_userHelpArticle {
  __typename: "UserHelpArticle";
  /**
   * The ID of the article
   */
  id: string;
  /**
   * The title of the article
   */
  title: string;
  /**
   * The content of the article
   */
  content: string;
  /**
   * The link to the (external) resource
   */
  link: string | null;
  /**
   * The tags enabling easy searching
   */
  tags: string[];
  /**
   * The UserHelpCategory the article belongs to
   */
  category: UserHelpArticle_userHelpArticle_category;
  /**
   * The date of creation
   */
  createdAt: string;
}

export interface UserHelpArticle {
  /**
   * A User Help Article
   */
  userHelpArticle: UserHelpArticle_userHelpArticle;
}

export interface UserHelpArticleVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserHelpCategory
// ====================================================

export interface UserHelpCategory_userHelpCategory_articles {
  __typename: "UserHelpArticle";
  /**
   * The ID of the article
   */
  id: string;
  /**
   * The title of the article
   */
  title: string;
  /**
   * The teaser of the article (no HTML and ellipsised after 160 characters)
   */
  teaser: string;
}

export interface UserHelpCategory_userHelpCategory_videos {
  __typename: "UserHelpVideo";
  /**
   * The ID of the video
   */
  id: string;
  /**
   * The title of the video
   */
  title: string;
  /**
   * The url to the video
   */
  url: string;
}

export interface UserHelpCategory_userHelpCategory_faqs {
  __typename: "UserHelpFAQ";
  /**
   * The ID of the FAQ
   */
  id: string;
  /**
   * The question to be aswered
   */
  question: string;
  /**
   * The answer to the question
   */
  answer: string;
}

export interface UserHelpCategory_userHelpCategory {
  __typename: "UserHelpCategory";
  /**
   * The ID of the category
   */
  id: string;
  /**
   * The name of the category
   */
  name: string;
  /**
   * The type the category
   */
  type: string;
  /**
   * The date of creation
   */
  createdAt: string;
  /**
   * The articles attached to the category
   */
  articles: UserHelpCategory_userHelpCategory_articles[];
  /**
   * The videos attached to the category
   */
  videos: UserHelpCategory_userHelpCategory_videos[];
  /**
   * The FAQs attached to the category
   */
  faqs: UserHelpCategory_userHelpCategory_faqs[];
}

export interface UserHelpCategory {
  /**
   * A User Help Category
   */
  userHelpCategory: UserHelpCategory_userHelpCategory;
}

export interface UserHelpCategoryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserHelpVideo
// ====================================================

export interface UserHelpVideo_userHelpVideo_category {
  __typename: "UserHelpCategory";
  /**
   * The ID of the category
   */
  id: string;
  /**
   * The name of the category
   */
  name: string;
}

export interface UserHelpVideo_userHelpVideo {
  __typename: "UserHelpVideo";
  /**
   * The ID of the video
   */
  id: string;
  /**
   * The title of the video
   */
  title: string;
  /**
   * The url to the video
   */
  url: string;
  /**
   * The link to the (external) resource
   */
  link: string | null;
  /**
   * The tags enabling easy searching
   */
  tags: string[];
  /**
   * The UserHelpCategory the article belongs to
   */
  category: UserHelpVideo_userHelpVideo_category;
  /**
   * The date of creation
   */
  createdAt: string;
}

export interface UserHelpVideo {
  /**
   * A User Help Video
   */
  userHelpVideo: UserHelpVideo_userHelpVideo;
}

export interface UserHelpVideoVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchDealerAccounts
// ====================================================

export interface SearchDealerAccounts_paginateDealerAccounts_data {
  __typename: "DealerAccount";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface SearchDealerAccounts_paginateDealerAccounts {
  __typename: "dealerAccountPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (SearchDealerAccounts_paginateDealerAccounts_data | null)[] | null;
}

export interface SearchDealerAccounts {
  /**
   * Get paginated dealer accounts
   */
  paginateDealerAccounts: SearchDealerAccounts_paginateDealerAccounts | null;
}

export interface SearchDealerAccountsVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: snapshot
// ====================================================

export interface snapshot_inspection_inspectionType {
  __typename: "InspectionType";
  /**
   * The id of the inspection type
   */
  id: string;
}

export interface snapshot_inspection_asset_group {
  __typename: "Group";
  /**
   * The id of the group
   */
  id: string;
  /**
   * The name of the productgroup
   */
  name: string | null;
}

export interface snapshot_inspection_asset_makeModel_make {
  __typename: "Make";
  /**
   * The id of the make
   */
  id: string;
  /**
   * The name of the make
   */
  name: string | null;
}

export interface snapshot_inspection_asset_makeModel {
  __typename: "MakeModel";
  /**
   * The id of the makeModel
   */
  id: string;
  /**
   * The name of the makeModel
   */
  name: string | null;
  /**
   * The make of this makeModel
   */
  make: snapshot_inspection_asset_makeModel_make | null;
}

export interface snapshot_inspection_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface snapshot_inspection_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * The product group of this asset
   */
  group: snapshot_inspection_asset_group | null;
  /**
   * The makeModel of this asset
   */
  makeModel: snapshot_inspection_asset_makeModel | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (snapshot_inspection_asset_meta | null)[] | null;
}

export interface snapshot_inspection_parameterValues_address {
  __typename: "Address";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The second line of the address
   */
  addressLine2: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
}

export interface snapshot_inspection_parameterValues_images_checks {
  __typename: "InspectionCheck";
  /**
   * The id of the inspection warning
   */
  id: string;
  /**
   * The warning severity namespace
   */
  namespace: string;
  /**
   * The warning severity level
   */
  level: string;
  /**
   * The warning type
   */
  message: string;
  /**
   * The serialised (JSON) information about this warning
   */
  attributes: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
}

export interface snapshot_inspection_parameterValues_images {
  __typename: "InspectionImage";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The URL to the thumbnail image
   */
  thumb_url: string | null;
  /**
   * The URL to the original image
   */
  original_url: string | null;
  checks: (snapshot_inspection_parameterValues_images_checks | null)[] | null;
}

export interface snapshot_inspection_parameterValues_parameter_parameterOptions {
  __typename: "ParameterOption";
  /**
   * The id of the parameter option
   */
  id: string;
  /**
   * The name of the parameter option
   */
  value: string | null;
}

export interface snapshot_inspection_parameterValues_parameter {
  __typename: "Parameter";
  /**
   * The id of the parameter
   */
  id: string;
  /**
   * The name of the parameter
   */
  name: string | null;
  /**
   * The type of the parameter
   */
  type: string | null;
  /**
   * The metric of the parameter (KM, KG)
   */
  metric: string | null;
  /**
   * The available options for this parameter
   */
  parameterOptions: (snapshot_inspection_parameterValues_parameter_parameterOptions | null)[] | null;
}

export interface snapshot_inspection_parameterValues {
  __typename: "InspectionParameterValue";
  /**
   * The id of the parameter value
   */
  id: string;
  /**
   * The value for the parameter (attribute)
   */
  value: string | null;
  /**
   * Address attached to the parameter value
   */
  address: snapshot_inspection_parameterValues_address | null;
  /**
   * Images attached to the parameter value
   */
  images: (snapshot_inspection_parameterValues_images | null)[] | null;
  /**
   * The parameter definition this value is provided for
   */
  parameter: snapshot_inspection_parameterValues_parameter | null;
}

export interface snapshot_inspection_warnings {
  __typename: "InspectionWarning";
  /**
   * The id of the inspection warning
   */
  id: string;
  /**
   * The warning type
   */
  type: InspectionWarningType;
  /**
   * The warning severity level
   */
  level: InspectionWarningLevel;
  /**
   * The serialised (JSON) information about this warning
   */
  description: string | null;
}

export interface snapshot_inspection_checks_image_parameterValue_parameter {
  __typename: "Parameter";
  /**
   * The name of the parameter
   */
  name: string | null;
}

export interface snapshot_inspection_checks_image_parameterValue {
  __typename: "ParameterValue";
  /**
   * The id of the parameter value
   */
  id: string;
  /**
   * The parameter definition this value is provided for
   */
  parameter: snapshot_inspection_checks_image_parameterValue_parameter | null;
}

export interface snapshot_inspection_checks_image {
  __typename: "InspectionImage";
  /**
   * The filename of the image
   */
  filename: string | null;
  /**
   * The alias of the image
   */
  alias: string | null;
  /**
   * The parameter value this image is linked to
   */
  parameterValue: snapshot_inspection_checks_image_parameterValue | null;
}

export interface snapshot_inspection_checks {
  __typename: "InspectionCheck";
  /**
   * The id of the inspection warning
   */
  id: string;
  /**
   * The warning severity namespace
   */
  namespace: string;
  /**
   * The warning severity level
   */
  level: string;
  /**
   * The warning type
   */
  message: string;
  /**
   * The serialised (JSON) information about this warning
   */
  attributes: string | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The asset inspection image
   */
  image: snapshot_inspection_checks_image | null;
}

export interface snapshot_inspection_approvals_creator {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface snapshot_inspection_approvals_reasons {
  __typename: "InspectionApprovalReason";
  /**
   * The id of the approval reason
   */
  id: string;
  /**
   * The approval reason
   */
  name: string | null;
}

export interface snapshot_inspection_approvals {
  __typename: "InspectionApproval";
  /**
   * The id of the approval
   */
  id: string;
  /**
   * The state of the approval
   */
  state: InspectionApprovalState;
  /**
   * The user responsible for the approval
   */
  creator: snapshot_inspection_approvals_creator;
  /**
   * The remark attached to the approval
   */
  remark: string | null;
  /**
   * The reasons for this approval
   */
  reasons: snapshot_inspection_approvals_reasons[];
  /**
   * The model created_at date
   */
  createdAt: string | null;
}

export interface snapshot_inspection_logs_device {
  __typename: "Device";
  /**
   * The device identifier
   */
  id: string;
  /**
   * The name of the device
   */
  name: string | null;
  /**
   * The device brand
   */
  brand: string | null;
  /**
   * The device model
   */
  model: string | null;
}

export interface snapshot_inspection_logs {
  __typename: "InspectionLog";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * Accuracy of the coordinates
   */
  accuracy: number;
  /**
   * Latitude of the event
   */
  latitude: number;
  /**
   * Longitude of the event
   */
  longitude: number;
  /**
   * Distance of the coordinates to the address
   */
  distance: number | null;
  /**
   * The device that produced the log
   */
  device: snapshot_inspection_logs_device | null;
}

export interface snapshot_inspection_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
  /**
   * The phone number of the account
   */
  phone: string | null;
}

export interface snapshot_inspection_address_entity {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
}

export interface snapshot_inspection_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The first line of  the address
   */
  addressLine1: string | null;
  /**
   * The zip code of the address
   */
  zipCode: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The state/province of the address
   */
  state: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The country of the address in a readable format
   */
  countryName: string | null;
  /**
   * The remark of the address
   */
  remark: string | null;
  /**
   * The latitude of the address
   */
  latitude: number | null;
  /**
   * The longitude of the address
   */
  longitude: number | null;
  /**
   * The human readable, formatted address line of the address
   */
  line: string | null;
  /**
   * The entity this address is attached to
   */
  entity: snapshot_inspection_address_entity | null;
}

export interface snapshot_inspection_transfers_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface snapshot_inspection_transfers_address {
  __typename: "Address";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
}

export interface snapshot_inspection_transfers {
  __typename: "InspectionTransfer";
  /**
   * The user targeted as the inspector
   */
  inspector: snapshot_inspection_transfers_inspector;
  /**
   * The product group of the flow
   */
  address: snapshot_inspection_transfers_address | null;
  /**
   * The transfer status
   */
  status: InspectionTransferStatus;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * The model updated_at date
   */
  updatedAt: string | null;
}

export interface snapshot_inspection_relatedInspections_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
}

export interface snapshot_inspection_relatedInspections {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The thumbnail of the inspection
   */
  thumb: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The date the inspection got assigned the status
   */
  statusDate: string | null;
  /**
   * The inspection equipment situation
   */
  equipmentSituation: EquipmentSituation | null;
  /**
   * The amount of inconsistency warnings for this inspection
   */
  warningsAmount: number | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: snapshot_inspection_relatedInspections_inspector | null;
}

export interface snapshot_inspection {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * Shows if the inspection is trusted
   */
  trusted: boolean | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model started_at date
   */
  startedAt: string | null;
  /**
   * The model finished_at date
   */
  finishedAt: string | null;
  /**
   * The model submitted_at date
   */
  submittedAt: string | null;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model automatically_accepted_at date
   */
  automaticallyAcceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * A message containing the reason to why this inspection has been declined
   */
  declinedReason: string | null;
  /**
   * The Inspection ID of the next inspection in the audit
   */
  nextAuditInspectionId: string | null;
  /**
   * The Inspection ID of the next inspection in the audit
   */
  previousAuditInspectionId: string | null;
  /**
   * The inspection equipment situation
   */
  inspectionType: snapshot_inspection_inspectionType | null;
  /**
   * The asset the inspection belongs to
   */
  asset: snapshot_inspection_asset | null;
  /**
   * The inspection filled parameters (attributes)
   */
  parameterValues: (snapshot_inspection_parameterValues | null)[] | null;
  /**
   * Inconsistency warnings for this inspection
   */
  warnings: (snapshot_inspection_warnings | null)[] | null;
  /**
   * Inconsistency checks for this inspection
   */
  checks: snapshot_inspection_checks[] | null;
  /**
   * The accept of decline history of the inspection
   */
  approvals: snapshot_inspection_approvals[];
  /**
   * The inspection logs for this inspection
   */
  logs: (snapshot_inspection_logs | null)[] | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: snapshot_inspection_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: snapshot_inspection_address | null;
  /**
   * The transfer trail of this inspection
   */
  transfers: (snapshot_inspection_transfers | null)[] | null;
  relatedInspections: snapshot_inspection_relatedInspections[] | null;
}

export interface snapshot {
  /**
   * Get a specific inspection identified by an InspectionID
   */
  inspection: snapshot_inspection | null;
}

export interface snapshotVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paginateSnapshots
// ====================================================

export interface paginateSnapshots_paginateInspections_data_asset_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface paginateSnapshots_paginateInspections_data_asset_images {
  __typename: "InspectionImage";
  /**
   * The id of the parameter
   */
  id: string;
}

export interface paginateSnapshots_paginateInspections_data_asset {
  __typename: "InspectionAsset";
  /**
   * The id of the asset
   */
  id: string;
  /**
   * The human readable name of the asset
   */
  name: string | null;
  /**
   * The serial number of the asset
   */
  serialNumber: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (paginateSnapshots_paginateInspections_data_asset_meta | null)[] | null;
  /**
   * The asset inspection images
   */
  images: (paginateSnapshots_paginateInspections_data_asset_images | null)[] | null;
}

export interface paginateSnapshots_paginateInspections_data_inspector {
  __typename: "User";
  /**
   * The id of the account
   */
  id: string;
  /**
   * The name of the account
   */
  name: string | null;
  /**
   * The first name of the account
   */
  firstName: string | null;
  /**
   * The last name of the account
   */
  lastName: string | null;
  /**
   * The email of the account
   */
  email: string | null;
}

export interface paginateSnapshots_paginateInspections_data_address_dealer_meta {
  __typename: "Meta";
  /**
   * The id of the product sector
   */
  key: string;
  /**
   * The name of the product sector
   */
  value: string | null;
}

export interface paginateSnapshots_paginateInspections_data_address_dealer {
  __typename: "DealerType";
  /**
   * The id of the dealer
   */
  id: string;
  /**
   * The external id of the dealer
   */
  externalId: string | null;
  /**
   * Arbitrary information added to the inspection
   */
  meta: (paginateSnapshots_paginateInspections_data_address_dealer_meta | null)[] | null;
}

export interface paginateSnapshots_paginateInspections_data_address {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The city of the address
   */
  city: string | null;
  /**
   * The country of the address in ISO 3166-1 Alpha-2 format
   */
  country: string | null;
  /**
   * The entity this address is attached to
   */
  dealer: paginateSnapshots_paginateInspections_data_address_dealer | null;
}

export interface paginateSnapshots_paginateInspections_data {
  __typename: "Inspection";
  /**
   * The id of the inspection
   */
  id: string;
  /**
   * The thumbnail of the inspection
   */
  thumb: string | null;
  /**
   * The inspection status
   */
  status: InspectionStatus;
  /**
   * The inspection equipment situation
   */
  equipmentSituation: EquipmentSituation | null;
  /**
   * Whether the inspection has an approval remark or not
   */
  hasApprovalRemark: boolean;
  /**
   * The amount of inconsistency warnings for this inspection
   */
  warningsAmount: number | null;
  /**
   * The score of the inspection
   */
  score: number | null;
  /**
   * The amount of anomalies for this inspection
   */
  anomaliesAmount: number | null;
  /**
   * The model created_at date
   */
  createdAt: string | null;
  /**
   * The model planned_at date
   */
  plannedAt: string | null;
  /**
   * The model due_at date
   */
  dueAt: string | null;
  /**
   * The model started_at date
   */
  startedAt: string | null;
  /**
   * The model finished_at date
   */
  finishedAt: string | null;
  /**
   * The model submitted_at date
   */
  submittedAt: string | null;
  /**
   * The model accepted_at date
   */
  acceptedAt: string | null;
  /**
   * The model declined_at date
   */
  declinedAt: string | null;
  /**
   * The asset the inspection belongs to
   */
  asset: paginateSnapshots_paginateInspections_data_asset | null;
  /**
   * The user assigned as the inspector of/to carry out the inspection
   */
  inspector: paginateSnapshots_paginateInspections_data_inspector | null;
  /**
   * The address/location of the inspection
   */
  address: paginateSnapshots_paginateInspections_data_address | null;
}

export interface paginateSnapshots_paginateInspections {
  __typename: "inspectionPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (paginateSnapshots_paginateInspections_data | null)[] | null;
}

export interface paginateSnapshots {
  /**
   * Get paged inspections
   */
  paginateInspections: paginateSnapshots_paginateInspections | null;
}

export interface paginateSnapshotsVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  status?: (InspectionStatus | null)[] | null;
  trusted?: boolean | null;
  equipmentSituation?: (EquipmentSituation | null)[] | null;
  accountManagerIds?: string[] | null;
  date?: DateRangeInput | null;
  anomaly?: boolean | null;
  dealerIds?: (string | null)[] | null;
  dealerId?: string | null;
  branchId?: string | null;
  inspectorId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SnapshotStatistics
// ====================================================

export interface SnapshotStatistics_inspectionStatistics_statusByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface SnapshotStatistics_inspectionStatistics_statusByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (SnapshotStatistics_inspectionStatistics_statusByMonth_partitions | null)[];
}

export interface SnapshotStatistics_inspectionStatistics_situationByMonth_partitions {
  __typename: "StatisticsPartitionType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * The name of the partition
   */
  name: string;
  /**
   * The value of the partition
   */
  count: number;
}

export interface SnapshotStatistics_inspectionStatistics_situationByMonth {
  __typename: "statisticsGroupType";
  /**
   * The id of the partition
   */
  id: string | null;
  /**
   * the name of the group
   */
  name: string;
  /**
   * The partition of the group`
   */
  partitions: (SnapshotStatistics_inspectionStatistics_situationByMonth_partitions | null)[];
}

export interface SnapshotStatistics_inspectionStatistics {
  __typename: "InspectionStatisticsType";
  /**
   * The dealer equipment situation partitions
   */
  statusByMonth: (SnapshotStatistics_inspectionStatistics_statusByMonth | null)[];
  /**
   * The dealer inspection status partitions
   */
  situationByMonth: (SnapshotStatistics_inspectionStatistics_situationByMonth | null)[];
}

export interface SnapshotStatistics {
  /**
   * Get paged dealers
   */
  inspectionStatistics: SnapshotStatistics_inspectionStatistics | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateTypes
// ====================================================

export interface PaginateTypes_paginateTypes_data {
  __typename: "MakeModel";
  /**
   * The id of the makeModel
   */
  id: string;
  /**
   * The name of the makeModel
   */
  name: string | null;
}

export interface PaginateTypes_paginateTypes {
  __typename: "makeModelPagination";
  /**
   * Number of total items selected by the query
   */
  total: number;
  /**
   * Number of items returned per page
   */
  per_page: number;
  /**
   * Current page of the cursor
   */
  current_page: number;
  /**
   * Number of the first item returned
   */
  from: number | null;
  /**
   * Number of the last item returned
   */
  to: number | null;
  /**
   * The last page (number of pages)
   */
  last_page: number;
  /**
   * Determines if cursor has more pages after the current page
   */
  has_more_pages: boolean;
  /**
   * List of items on the current page
   */
  data: (PaginateTypes_paginateTypes_data | null)[] | null;
}

export interface PaginateTypes {
  /**
   * Get paged makes
   */
  paginateTypes: PaginateTypes_paginateTypes | null;
}

export interface PaginateTypesVariables {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  searchableFields?: (string | null)[] | null;
  make?: string | null;
  validated?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginateUserFilterPreset
// ====================================================

export interface PaginateUserFilterPreset_paginateUserFilterPreset {
  __typename: "UserFilterPreset";
  /**
   * The id of the user filter preset
   */
  id: string;
  /**
   * The user defined name of the user preset filter
   */
  name: string;
  /**
   * The query of the filter
   */
  query: string;
  /**
   * The type of the filter
   */
  type: string;
}

export interface PaginateUserFilterPreset {
  /**
   * Paginate filter presets of the user
   */
  paginateUserFilterPreset: (PaginateUserFilterPreset_paginateUserFilterPreset | null)[] | null;
}

export interface PaginateUserFilterPresetVariables {
  page?: number | null;
  limit?: number | null;
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Audit
// ====================================================

export interface Audit_branch_owner {
  __typename: "Entity";
  /**
   * The id of the entity
   */
  id: string;
  /**
   * The name of the entity
   */
  name: string | null;
}

export interface Audit_branch {
  __typename: "BranchType";
  /**
   * The id of the address
   */
  id: string;
  /**
   * The human readable name of the address
   */
  name: string | null;
  /**
   * The amount of days between audits
   */
  auditFrequency: number | null;
  /**
   * The date of the next audit
   */
  nextAudit: string | null;
  /**
   * The owner (entity) of the address
   */
  owner: Audit_branch_owner | null;
}

export interface Audit_previousAudits {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
}

export interface Audit {
  __typename: "Audit";
  /**
   * The id of the audit
   */
  id: string;
  /**
   * The rating of the audit
   */
  rating: AuditRating | null;
  /**
   * The model closed_at date
   */
  closedAt: string | null;
  /**
   * The amount of inspections with situation Sold
   */
  soldAmount: number | null;
  /**
   * The amount of inspections with sold date anomalies
   */
  soldDateAnomalies: number | null;
  /**
   * The amount of inspections with times in demo anomalies
   */
  timesInDemoAnomalies: number | null;
  /**
   * The amount of inspections with times in rental anomalies
   */
  timesInRentalAnomalies: number | null;
  /**
   * The amount of inspections in the audit
   */
  inspectionsAmount: number | null;
  /**
   * The branch of the audit
   */
  branch: Audit_branch;
  /**
   * The last previous audits
   */
  previousAudits: Audit_previousAudits[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserHelpArticleParts
// ====================================================

export interface UserHelpArticleParts {
  __typename: "UserHelpArticle";
  /**
   * The ID of the article
   */
  id: string;
  /**
   * The title of the article
   */
  title: string;
  /**
   * The teaser of the article (no HTML and ellipsised after 160 characters)
   */
  teaser: string;
  /**
   * The tags enabling easy searching
   */
  tags: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserHelpVideoParts
// ====================================================

export interface UserHelpVideoParts {
  __typename: "UserHelpVideo";
  /**
   * The ID of the video
   */
  id: string;
  /**
   * The title of the video
   */
  title: string;
  /**
   * The url to the video
   */
  url: string;
  /**
   * The tags enabling easy searching
   */
  tags: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserHelpFAQParts
// ====================================================

export interface UserHelpFAQParts {
  __typename: "UserHelpFAQ";
  /**
   * The ID of the FAQ
   */
  id: string;
  /**
   * The question to be aswered
   */
  question: string;
  /**
   * The answer to the question
   */
  answer: string;
  /**
   * The tags enabling easy searching
   */
  tags: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The type of an address
 */
export enum AddressType {
  addressBook = "addressBook",
  branch = "branch",
}

/**
 * The reason for postponing an audit
 */
export enum AuditPostponeReason {
  AUDIT_BY_EXTERNAL_PARTY = "AUDIT_BY_EXTERNAL_PARTY",
  AUDIT_FREQUENCY_CHANGED = "AUDIT_FREQUENCY_CHANGED",
  NO_ASSETS_IN_STOCK = "NO_ASSETS_IN_STOCK",
}

/**
 * The rating of an audit
 */
export enum AuditRating {
  CONTENT = "CONTENT",
  HALF_CONTENT = "HALF_CONTENT",
  NOT_CONTENT = "NOT_CONTENT",
}

/**
 * The status of an audit
 */
export enum AuditStatus {
  CLOSED = "CLOSED",
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  POSTPONED = "POSTPONED",
}

/**
 * Defines the battery state a device
 */
export enum BatteryStateEnum {
  charging = "charging",
  full = "full",
  unplugged = "unplugged",
}

/**
 * The equipment situation of an inspection
 */
export enum EquipmentSituation {
  FOUND = "FOUND",
  IN_DEMONSTRATION = "IN_DEMONSTRATION",
  IN_TRANSIT = "IN_TRANSIT",
  NOT_FOUND = "NOT_FOUND",
  OTHER_SITUATION = "OTHER_SITUATION",
  RENTAL = "RENTAL",
  SERIAL_NUMBER_INCORRECT = "SERIAL_NUMBER_INCORRECT",
  SOLD = "SOLD",
  SOLD_AMONG_THE_GROUP = "SOLD_AMONG_THE_GROUP",
  SOLD_PAID = "SOLD_PAID",
  TRANSFERRED = "TRANSFERRED",
}

/**
 * The state of an inspection approval
 */
export enum InspectionApprovalState {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

/**
 * The status of an inspection
 */
export enum InspectionStatus {
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
  CREATED = "CREATED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  FINISHED = "FINISHED",
  ON_HOLD = "ON_HOLD",
  PAUSED = "PAUSED",
  PLANNED = "PLANNED",
  STARTED = "STARTED",
  SUBMITTED = "SUBMITTED",
  TRANSFER_PENDING = "TRANSFER_PENDING",
}

/**
 * The status of an inspection transfer
 */
export enum InspectionTransferStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
}

/**
 * The type of an inspection
 */
export enum InspectionTypeEnum {
  CollectionAndRecovery = "CollectionAndRecovery",
  FinalInspection = "FinalInspection",
  InspectionLight = "InspectionLight",
  Repossession = "Repossession",
  Snapshot = "Snapshot",
}

/**
 * The level of inspection warning
 */
export enum InspectionWarningLevel {
  error = "error",
  info = "info",
  warning = "warning",
}

/**
 * The type of inspection warning
 */
export enum InspectionWarningType {
  inconsistency = "inconsistency",
  max_distance = "max_distance",
  no_location_log = "no_location_log",
}

/**
 * Defines the network state of a device
 */
export enum NetworkStateEnum {
  cellular = "cellular",
  wifi = "wifi",
}

/**
 * Defines the status of a sync job
 */
export enum SyncJobStatusEnum {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
}

/**
 * Defines possible systems of measurement
 */
export enum SystemOfMeasurementEnum {
  IMPERIAL = "IMPERIAL",
  METRIC = "METRIC",
}

/**
 * An input model containing information about a specific address
 */
export interface AddressInput {
  id?: string | null;
  name?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  zipCode?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  contactName?: string | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
  placeId?: string | null;
  externalId?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  remark?: string | null;
  accountManagerId?: string | null;
  dealer?: DynamicDealerInput | null;
}

/**
 * An input model containing information about a specific branch
 */
export interface BranchInput {
  id?: string | null;
  name?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  zipCode?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  contactName?: string | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
  placeId?: string | null;
  externalId?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  remark?: string | null;
  accountManagerId?: string | null;
  dealer?: DynamicDealerInput | null;
  nextAudit?: string | null;
  auditFrequency?: number | null;
  nextTrustedAudit?: string | null;
  trustedAuditFrequency?: number | null;
}

/**
 * An input model containing information about a the branch inspector
 */
export interface BranchInspectorInput {
  id?: string | null;
  inspector?: UserInput | null;
  inspectorEmail?: string | null;
}

/**
 * An input model containing information about a specific contact
 */
export interface ContactInput {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
}

/**
 * An input with a start date and end date
 */
export interface DateRangeInput {
  start?: string | null;
  end?: string | null;
}

/**
 * Dealer information
 */
export interface DealerInput {
  name: string;
  externalId: string;
}

/**
 * Dealer reference to link or information to create a dealer
 */
export interface DynamicDealerInput {
  id?: string | null;
  input?: DealerInput | null;
}

/**
 * An input model containing information about a specific group
 */
export interface GroupInput {
  id: string;
}

/**
 * An input model containing (identification) information about an asset
 */
export interface InspectionAssetInput {
  id?: string | null;
  make?: string | null;
  type?: string | null;
  makeModel?: MakeModelInput | null;
  group?: GroupInput | null;
  serialNumber?: string | null;
  licensePlate?: string | null;
  yearOfManufacture?: number | null;
}

/**
 * An input model containing information about an inspection
 */
export interface InspectionInput {
  inspectionType?: InspectionTypeInput | null;
  sourceInspection?: SourceInspectionInput | null;
  trusted?: boolean | null;
  externalId?: string | null;
  asset: InspectionAssetInput;
  plannedAt?: string | null;
  dueAt?: string | null;
  assignedAt?: string | null;
  startedAt?: string | null;
  stoppedAt?: string | null;
  finishedAt?: string | null;
  acceptedAt?: string | null;
  submittedAt?: string | null;
  declinedAt?: string | null;
  declinedReason?: string | null;
  inspector?: UserInput | null;
  inspectorEmail?: string | null;
  remark?: string | null;
  parameterValues?: (InspectionParameterValueInput | null)[] | null;
  contact?: ContactInput | null;
  address?: AddressInput | null;
}

/**
 * An input model containing a damage indication for a specific InspectionParameterValue
 */
export interface InspectionParameterValueDamageInput {
  id?: string | null;
  message?: string | null;
  costs?: number | null;
  costsForLessee?: boolean | null;
  image?: string | null;
}

/**
 * An input model containing a connection between a Parameter and a value also including remarks or damages
 */
export interface InspectionParameterValueInput {
  id?: string | null;
  parameter: ParameterInput;
  parameterGroup?: ParameterGroupInput | null;
  value?: string | null;
  address?: AddressInput | null;
  remarks?: (InspectionParameterValueRemarkInput | null)[] | null;
  damages?: (InspectionParameterValueDamageInput | null)[] | null;
  createdAt?: string | null;
  images?: (string | null)[] | null;
}

/**
 * An input model containing a remarks for a specific InspectionParameterValue
 */
export interface InspectionParameterValueRemarkInput {
  id?: string | null;
  message?: string | null;
}

/**
 * A model containing information about the inspection type
 */
export interface InspectionTypeInput {
  id: string;
}

/**
 * An asset make model
 */
export interface MakeModelInput {
  id?: string | null;
  make?: string | null;
  model?: string | null;
}

/**
 * An model referencing a specific parameter group
 */
export interface ParameterGroupInput {
  id?: string | null;
}

/**
 * An model referencing a specific parameter
 */
export interface ParameterInput {
  id: string;
}

/**
 * A model containing information about the source inspection
 */
export interface SourceInspectionInput {
  id: string;
}

/**
 * An input model containing information about a specific user
 */
export interface UserInput {
  id?: string | null;
  email?: string | null;
  notify?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
