import {Component, Emit, Model, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
export default class InputMixin<T1 = any> extends Vue {
  protected value: T1 | null = null;

  @Model('change') protected model!: any;

  @Prop({type: Boolean, default: true}) protected clearable!: boolean;
  @Prop(String) protected label?: string;
  @Prop(String) protected placeholderText?: string;
  @Prop(String) protected error!: string;
  @Prop(Number) protected tabIndex?: number;
  @Prop({type: Boolean, default: false}) protected disabled!: boolean;
  @Prop({type: Boolean, default: false}) protected required!: boolean;

  @Prop({type: Boolean, default: false}) protected autoFocus!: boolean;

  protected focus: boolean = false;

  get isFilled(): boolean {
    if (typeof this.value === 'string') {
      return this.value.length > 0;
    }

    return this.value !== null;
  }

  get up(): boolean {
    return this.focus || this.isFilled;
  }

  get isClearable(): boolean {
    return !this.disabled && this.clearable && this.isFilled;
  }

  protected clear() {
    this.value = null;
    this.$emit('clear');
  }

  @Watch('model', {immediate: true})
  protected onModelChange(value: T1 | null) {
    this.value = value;
  }

  @Watch('value')
  @Emit('change')
  protected onValueChange(value: T1 | null) {
    return this.isFilled ? value : null;
  }

  protected input(value: any) {
    this.$emit('input', this.value);
  }

  @Watch('focus')
  protected onFocusChange() {
    if (this.focus) {
      this.$emit('focus');
    } else {
      this.$emit('blur');
    }
  }
}
