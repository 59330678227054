import {EquipmentSituation} from '@/types/intrador';
import i18n from '@/store/i18n';
import {BarChartSeries} from '@/layouts/back-office/elements/charts/BarChartMixin';
import {FilterSelectOption} from '@/layouts/back-office/elements/filters/SelectFilter.vue';

export const equipmentSituationKeys = Object.keys(EquipmentSituation);

export function getEquipmentSituationTranslation(key: EquipmentSituation): string|null {
  switch (key) {
    case EquipmentSituation.FOUND:
      return i18n.it('snapshot.equipment-situation.found.title', 'Found') as string;
    case EquipmentSituation.SOLD:
      return i18n.it('snapshot.equipment-situation.sold.title', 'Sold') as string;
    case EquipmentSituation.TRANSFERRED:
      return i18n.it('snapshot.equipment-situation.transferred.title', 'Transferred') as string;
    case EquipmentSituation.IN_DEMONSTRATION:
      return i18n.it('snapshot.equipment-situation.in-demonstration.title', 'Demo') as string;
    case EquipmentSituation.IN_TRANSIT:
      return i18n.it('snapshot.equipment-situation.in-transit.title', 'Transit') as string;
    case EquipmentSituation.OTHER_SITUATION:
      return i18n.it('snapshot.equipment-situation.other-situation.title', 'Other') as string;
    case EquipmentSituation.SOLD_PAID:
      return i18n.it('snapshot.equipment-situation.sold-paid.title', 'Sold & paid') as string;
    case EquipmentSituation.SOLD_AMONG_THE_GROUP:
      return i18n.it('snapshot.equipment-situation.sold-among-the-group.title', 'Sold among group') as string;
    case EquipmentSituation.RENTAL:
      return i18n.it('snapshot.equipment-situation.rental.title', 'Rental') as string;
    case EquipmentSituation.SERIAL_NUMBER_INCORRECT:
      return i18n.it('snapshot.equipment-situation.serial-number-invalid.title', 'S/N invalid') as string;
    case EquipmentSituation.NOT_FOUND:
      return i18n.it('snapshot.equipment-situation.not-found.title', 'Not found') as string;
  }
  return null;
}

export function getEquipmentSituationIcon(key: EquipmentSituation): string|null {
  switch (key) {
    case EquipmentSituation.FOUND:
      return 'fa-check-double';
    case EquipmentSituation.SOLD:
      return 'fa-dollar-sign';
    case EquipmentSituation.TRANSFERRED:
      return 'fa-exchange-alt';
    case EquipmentSituation.IN_DEMONSTRATION:
      return 'fa-camera';
    case EquipmentSituation.IN_TRANSIT:
      return 'fa-shipping-fast';
    case EquipmentSituation.OTHER_SITUATION:
      return 'fa-question';
    case EquipmentSituation.SOLD_PAID:
      return 'fa-dollar-sign';
    case EquipmentSituation.SOLD_AMONG_THE_GROUP:
      return 'fa-dollar-sign';
    case EquipmentSituation.RENTAL:
      return 'fa-external-link-square-alt';
    case EquipmentSituation.SERIAL_NUMBER_INCORRECT:
      return 'fa-barcode';
    case EquipmentSituation.NOT_FOUND:
      return 'fa-times';
  }

  return null;
}

export function getEquipmentSituationColor(key: EquipmentSituation): string|null {
  switch (key) {
    case EquipmentSituation.FOUND:
      return '#8ABF59';
    case EquipmentSituation.SOLD: // This one
      return '#F3B85E';
    case EquipmentSituation.TRANSFERRED:
      return '#d88e0e';
    case EquipmentSituation.IN_DEMONSTRATION: // This one
      return '#563F7A';
    case EquipmentSituation.IN_TRANSIT:
      return '#563F7A';
    case EquipmentSituation.OTHER_SITUATION:
      return '#666666';
    case EquipmentSituation.SOLD_PAID:
      return '#f9d89f';
    case EquipmentSituation.SOLD_AMONG_THE_GROUP:
      return '#d88e0e';
    case EquipmentSituation.RENTAL: // This one
      return '#2197AE';
  }

  return null;
}

export function getEquipmentSituationFilterOptions(keys: string[] = []): FilterSelectOption[] {
  const statusOptions: FilterSelectOption[] = [];

  if (keys.length === 0) {
    keys = equipmentSituationKeys;
  }

  keys.forEach((key: string) => {
    statusOptions.push({
      key,
      icon: getEquipmentSituationIcon(key as EquipmentSituation)!,
      label: getEquipmentSituationTranslation(key as EquipmentSituation)!,
    });
  });

  return statusOptions;
}

export function getEquipmentSituationSeries(keys: string[] = []): BarChartSeries[] {
  const statusOptions: BarChartSeries[] = [];

  if (keys.length === 0) {
    keys = equipmentSituationKeys;
  }

  keys.forEach((key: string) => {
    statusOptions.push({
      key,
      label:  getEquipmentSituationTranslation(key as EquipmentSituation)!,
      color: getEquipmentSituationColor(key as EquipmentSituation)!,
    });
  });

  return statusOptions;
}

export default {
  keys: equipmentSituationKeys,
  getTranslation: getEquipmentSituationTranslation,
  getIcon: getEquipmentSituationIcon,
  getColor: getEquipmentSituationColor,

  getFilterOptions: getEquipmentSituationFilterOptions,
  getSeries: getEquipmentSituationSeries,
};
