import store from '@/store/store';
import {Module, VuexModule, Mutation, Action} from 'vuex-module-decorators';
import {Route} from 'vue-router';
import {graphqlClient} from '@/store/apollo/apollo';

export interface Token {
  tokenType?: string;
  accessToken?: string;
  expiresAt?: Date;
  referral?: string;
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function createAuthUrl(url: string, params: object) {
  const domain = process.env.VUE_APP_AUTH_DOMAIN;
  const query = Object.entries(params).map(([key, value]) => key + '=' + value);

  return domain + '/' + url + '?' + query.join('&');
}

@Module
export default class Authentication extends VuexModule {
  protected tokenType: string = 'Bearer';
  protected accessToken: string = '';
  protected expiresAt: Date | null = null;
  protected account: any | null = null;
  protected permissions: string[] = [];
  protected scopes: string | undefined = process.env.VUE_APP_AUTH_SCOPE;

  get authorization() {
    return (this.accessToken)
      ? `${this.tokenType} ${this.accessToken}`
      : null;
  }

  get tokenScopes(): string[] {
    return (this.accessToken)
      ? parseJwt(this.accessToken).scopes.sort()
      : [];
  }

  get parsedScopes(): string[] {
    return (this.scopes)
      ? this.scopes.split(' ').sort()
      : [];
  }

  get validScopes(): boolean {
    return this.parsedScopes.every((scope: string) => this.tokenScopes.indexOf(scope) >= 0);
  }

  get isLoggedIn(): boolean {
    return (this.accessToken.length > 0) && this.validScopes &&
      (this.expiresAt !== null && this.expiresAt >= new Date());
  }

  get user(): any {
    return this.account;
  }

  get can() {
    return (value: string | string[]): boolean => {
      if (Array.isArray(value)) {
        return value.every((permission: string) => {
          return permission.split('|').some((p: string) => {
            return this.permissions.indexOf(p) >= 0;
          });
        });
      } else {
        return value.split('|').some((p: string) => {
          return this.permissions.indexOf(p) >= 0;
        });
      }
    };
  }

  @Action({commit: 'setToken'})
  private initAuthentication() {
    const expiredAt = localStorage.getItem('auth.expires_at');

    return {
      tokenType: localStorage.getItem('auth.token_type'),
      accessToken: localStorage.getItem('auth.access_token'),
      expiresAt: (expiredAt) ? new Date(parseInt(expiredAt, 10)) : undefined,
    };
  }

  @Action({})
  private authorize(to: Route) {
    return new Promise((resolve, reject) => {
      if (this.isLoggedIn) {
        return resolve(to);
      }

      return reject();
    }).catch(() => {
      store.dispatch('deleteToken')
        .then(() => {
          localStorage.setItem('auth.referral', to.fullPath);

          window.location.href = createAuthUrl('oauth/authorize', {
            response_type: 'token',
            client_id: process.env.VUE_APP_CLIENT_ID,
            lang: to.params.lang,
            redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URL,
            scope: this.scopes,
          });
        });
    });
  }

  @Action({commit: 'clear'})
  private logout(referral: string) {
    store.dispatch('deleteToken')
      .then(() => {
        if (!referral.startsWith('http')) {
          referral = process.env.VUE_APP_DOMAIN + referral;
        }

        window.location.href = createAuthUrl('logout', {
          continue: referral,
        });
      });
  }

  @Action({commit: 'setToken'})
  private resolveCallback(to: Route) {
    const hash = to.hash.substr(1);
    const parts: string[] = hash.split('&');

    const token: Token = {
      referral: localStorage.getItem('auth.referral') || '/',
    };

    parts.forEach((part) => {
      const item = part.split('=');

      switch (item[0]) {
        case 'access_token':
          token.accessToken = item[1];
          break;
        case 'token_type':
          token.tokenType = item[1];
          break;
        case 'expires_in':
          const expiredAt = new Date();
          expiredAt.setSeconds(expiredAt.getSeconds() + parseInt(item[1], 10));

          token.expiresAt = expiredAt;
          break;
      }
    });

    return token;
  }

  @Action({commit: 'clear'})
  private deleteToken() {
    return true;
  }

  @Action({commit: 'setAccount'})
  private async resolveAccount() {
    if (!this.isLoggedIn) {
      return null;
    }

    const account = await graphqlClient.query({
      query: require('@/graphql/Account.gql'),
    }).catch((error) => {
      if (error.networkError.statusCode === 401) {
        store.dispatch('deleteToken');
      }
      return error;
    });

    return account.data.account;
  }

  @Mutation
  private async setToken(token: Token) {
    this.tokenType = token.tokenType || 'Bearer';
    this.accessToken = token.accessToken || '';
    this.expiresAt = token.expiresAt || new Date();

    localStorage.setItem('auth.token_type', this.tokenType);
    localStorage.setItem('auth.access_token', this.accessToken || '');
    localStorage.setItem('auth.expires_at', this.expiresAt.getTime().toString());
    localStorage.setItem('auth.expires_on', '2000000000'); // Trick the old back office

    if (this.account === null) {
      store.dispatch('resolveAccount');
    }
  }

  @Mutation
  private async setAccount(account: any) {
    this.account = account;
    if (this.account !== null) {
      this.permissions = this.account.rights;
    }
  }

  @Mutation
  private clear() {
    this.tokenType = 'Bearer';
    this.accessToken = '';
    this.expiresAt = null;

    localStorage.removeItem('auth.token_type');
    localStorage.removeItem('auth.access_token');
    localStorage.removeItem('auth.expires_at');
    localStorage.removeItem('auth.expires_on');
  }
}

