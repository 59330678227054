




































import { Component, Mixins } from 'vue-property-decorator';
import UserHelpViewMixin from '@/components/user-help/views/mixins/UserHelpViewMixin';
import UserHelpArticleCard from '@/components/user-help/views/cards/UserHelpArticleCard.vue';
import UserHelpVideoCard from '@/components/user-help/views/cards/UserHelpVideoCard.vue';
import UserHelpFAQCard from '@/components/user-help/views/cards/UserHelpFAQCard.vue';
import UserHelpSearch from '@/components/user-help/views/UserHelpSearch.vue';

@Component({
  components: {
    UserHelpSearch,
    UserHelpFAQCard,
    UserHelpVideoCard,
    UserHelpArticleCard,
  },
})
export default class UserHelpCategory extends Mixins(UserHelpViewMixin) {
  private openFAQ: string | null = null;
}
