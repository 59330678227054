import Vue, { PluginObject } from 'vue';
import VueI18n, { LocaleMessageObject } from 'vue-i18n';
import { translationClient } from './apollo/apollo';
import { Account_account_culture, SystemOfMeasurementEnum } from '@/types/intrador';

Vue.use(VueI18n);

const TRANSLATION_WARNINGS: boolean = (process.env.VUE_APP_TRANSLATION_WARNINGS || 'false') === 'true';

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en-gb',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-gb',
  silentTranslationWarn: !TRANSLATION_WARNINGS,
  silentFallbackWarn: !TRANSLATION_WARNINGS,
});

const loadedLanguages: string[] = [];

interface Translation {
  key: string;
  value: string;
}

interface Substitutions {
  [key: string]: string;
}

/**
 * Method to manually replace :example in texts with provided substitutions
 *
 * @param text
 * @param substitutions
 * @return THe substituted text
 */
function replace(text: string, substitutions: Substitutions|null): string {
  if (substitutions) {
    for (const s in substitutions) {
      if (substitutions.hasOwnProperty(s)) {
        text = text.replace(new RegExp('{' + s + '}', 'ig'), substitutions[s]);
      }
    }
  }

  return text;
}

function iTranslate(key: string, ...args: any[]) {
  const fallback = (args.length > 0) ? args.splice(-1, 1)[0] : null;
  const substitutions = (args.length > 0) ? args[0] : null;

  return (i18n.te(key))
    ? i18n.t(key, ...args)
    : (fallback)
      ? replace(fallback, substitutions)
      : key;
}

function iTranslateC(key: string, ...args: any[]) {
  const fallback = (args.length > 0) ? args.splice(-1, 1)[0] : null;
  const substitutions = (args.length > 0) ? args[0] : null;

  return (i18n.te(key))
    ? i18n.tc(key, ...args)
    : (fallback)
      ? replace(fallback, substitutions)
      : key;
}

function iDate(dateTime: Date|string|null, ...args: any[]) {
  if (dateTime === null) {
    return null;
  }

  let dt = dateTime;
  if (typeof(dt) === 'string') {
    try {
      dt = new Date(dt);
    } catch (e) {
      return null;
    }
  }

  return i18n.d(dt, ...args);
}

function iNumber(n: number, ...args: any[]): string {
  if (args.length > 0) {
    switch (args[0]) {
      case 'distance':
        return Vue.prototype.$culture.systemOfMeasurement === SystemOfMeasurementEnum.METRIC
          ? `${i18n.n(n, ...args)}m`
          : `${i18n.n(n * 3.2808, ...args)}ft`;
      case 'percentage':
        return `${i18n.n(n, ...args)}%`;
    }
  }

  return i18n.n(n, ...args);
}

function setI18nLanguage(lang: string, culture: Account_account_culture, data: Translation[]): string {
  const translations: LocaleMessageObject = {};
  data.forEach((item) => {
    translations[item.key] = item.value;
  });

  i18n.setDateTimeFormat(lang.toLowerCase(), {
    dateUtc: {
      year: 'numeric', month: 'short', day: 'numeric', timeZone: 'Europe/Amsterdam',
    },
    dateUtcWithoutYear: {
       month: 'short', day: 'numeric', timeZone: 'Europe/London',
    },
    date: {
      year: 'numeric', month: 'short', day: 'numeric',
    },
    dateWithoutYear: {
      month: 'short', day: 'numeric',
    },
    time: {
      hour: 'numeric', minute: 'numeric',
    },
    dateTime: {
      year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
    },
    dateTimeWithoutYear: {
      month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
    },
  });

  i18n.setNumberFormat(lang.toLowerCase(), {
    number: {
      maximumFractionDigits: 0,
    },
    percentage: {
      // style: 'unit',
      // // @ts-ignore
      // unit: 'percent',
      // unitDisplay: 'short',
      maximumFractionDigits: 0,
    },
    distance: {
      // style: 'unit',
      // @ts-ignore
      // unit: culture.systemOfMeasurement === SystemOfMeasurementEnum.METRIC ? 'meter' : 'foot',
      // unitDisplay: 'short',
      maximumFractionDigits: 0,
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
    currencyConvert: {
      style: 'currency',
      currency: culture.currency.code,
      currencyDisplay: 'symbol',
    },
  });

  i18n.setLocaleMessage(lang.toLowerCase(), translations);
  i18n.locale = lang.toLowerCase();
  localStorage.setItem('lang', i18n.locale);

  // Store culture settings in the vue object so we can access the details in components
  Vue.prototype.$culture = culture;

  loadedLanguages.push(lang);

  return lang;
}

export async function loadLanguage(lang: string): Promise<string> {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      const { data: { language } } = await translationClient.query({
        query: require('@/graphql/Language.gql'),
        variables: {
          language: lang,
          clientId: process.env.VUE_APP_CLIENT_ID,
          build: process.env.BITBUCKET_BUILD_NUMBER || process.env.VUE_APP_BUILD_NUMBER || 1,
        },
      });

      return setI18nLanguage(language.languageCode, language.culture, language.translations);
    }
  }

  return lang;
}

const Translation: PluginObject<any> = {
  install: (vue: any, options: any) => {
    vue.prototype.$it = iTranslate;
    vue.prototype.$itc = iTranslateC;
    vue.prototype.$id = iDate;
    vue.prototype.$in = iNumber;
  },
};

Vue.use(Translation);

export default {
  locale: i18n.locale,
  it: iTranslate,
  itc: iTranslateC,
  id: iDate,
  ite: i18n.te,
  in: iNumber,
};


