






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Spinner from './Spinner.vue';

@Component({
  components: {Spinner},
})
export default class SpinnerButton extends Vue {
  @Prop({ type: Boolean, default: false }) protected disabled!: boolean;
  @Prop({ type: Boolean, default: false }) protected spinning!: boolean;
  @Prop({ type: Boolean, default: true }) protected keepText!: boolean;
  @Prop({ type: String, default: null }) protected color!: boolean;
  @Prop(Number) protected tabIndex?: number;

  private isSpinning: boolean = false;

  public toggle(state: boolean | null = null): boolean {
    this.isSpinning = state !== null ? state : !this.isSpinning;

    return this.isSpinning;
  }

  @Watch('spinning', {immediate: true})
  private spinningChanged(value: boolean) {
    this.isSpinning = value;
  }

  private click(event: Event) {
    if (this.disabled || this.isSpinning) {
      return;
    }

    this.$emit('click', this.toggle, event);
  }
}
