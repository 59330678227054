import _Vue, {PluginObject} from 'vue';
import axios from 'axios';
import store from '@/store/store';
import i18n from '@/store/i18n';

const Http: PluginObject<any> = {
  install: (vue: typeof _Vue) => {
    const http = axios.create({
      baseURL: process.env.VUE_APP_API_DOMAIN,
    });

    http.interceptors.request.use((config) => {
      if (store.getters.isLoggedIn) {
        config.headers.common.Authorization = store.getters.authorization;
      }

      config.headers.common['Accept-language'] = i18n.locale;

      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    // intercept all errors
    http.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch('deleteToken');
          store.dispatch('authorize');
        }

        if (
          error.request.responseType === 'blob' &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              error.response.data = JSON.parse(reader.result as string);
              resolve(Promise.reject(error));
            };

            reader.onerror = () => {
              reject(error);
            };

            reader.readAsText(error.response.data);
          });
        }
      }

      return Promise.reject(error);
    });

    vue.prototype.$http = http;
  },
};

export default Http;
