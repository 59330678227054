import {loadLanguage} from '@/store/i18n';
import {RawLocation, Route} from 'vue-router';

export default async function(to: Route, from: Route, next: (to?: RawLocation) => void) {
  const requestLang = to.params.lang;
  const lang = (await loadLanguage(requestLang)).toLowerCase();

  if (requestLang !== lang) {
    const params = Object.assign({}, to.params);
    params.lang = lang;

    return next({
      name: to.name || undefined,
      params,
      replace: true,
    });
  }

  return next();
}
