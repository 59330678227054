













import { Component, Vue, Watch } from 'vue-property-decorator';
import UserHelpArticle from '@/components/user-help/views/UserHelpArticle.vue';
import UserHelpVideo from '@/components/user-help/views/UserHelpVideo.vue';
import UserHelpCategory from '@/components/user-help/views/UserHelpCategory.vue';
import UserHelpCategories from '@/components/user-help/views/UserHelpCategories.vue';
import * as Sentry from '@sentry/browser';

interface UserHelpRoute {
  id: string;
  type: string;
}

@Component({
  components: {
    UserHelpCategories,
    UserHelpArticle,
    UserHelpCategory,
    UserHelpVideo,
  },
})
export default class UserHelpModal extends Vue {
  private stack: UserHelpRoute[] = [];
  private isOpen = false;

  private get current() {
    return this.stack.length > 0 ? this.stack[this.stack.length - 1] : null;
  }

  public launch() {
    this.isOpen = true;
  }

  private created() {
    this.checkRouteQuery();
  }

  private async mounted() {
    if (!this.$can('view-user-help')) {
      return;
    }

    try {
      // Check if there are any unread articles
      const {data} = await this.$apollo.query({
        query: require('@/graphql/queries/user-help/PaginateUnreadUserHelpArticles.gql'),
      });

      if (data && data.paginateUnreadUserHelpArticles) {
        const articles = data.paginateUnreadUserHelpArticles.data;
        if (articles.length > 0) {
          this.open({
            id: articles[0].id,
            type: 'UserHelpArticle',
          });

          this.launch();
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  private back() {
    if (this.stack.length > 0) {
      this.stack.pop();

      if (this.current && (this.current.type === 'UserHelpArticle' || this.current.type === 'UserHelpVideo')) {
        this.$router.replace({
          path: this.$route.path,
          params: this.$route.params,
          query: {
            [this.current.type === 'UserHelpArticle' ? 'user-help-article' : 'user-help-video']:
              (this.current.id as string),
          },
        });
      } else {
        const query = { ...this.$route.query };
        delete query['user-help-article'];
        delete query['user-help-video'];

        this.$router.replace({
          path: this.$route.path,
          params: this.$route.params,
          query,
        });
      }
    }
  }

  private close() {
    // Close the modal
    this.isOpen = false;
    // Clear the stack
    this.stack = [];
  }

  private open(route: UserHelpRoute) {
    this.stack.push(route);

    if ((route.type === 'UserHelpArticle' || route.type === 'UserHelpVideo')
      && (!this.current || route.id !== this.current.id)) {
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: { 'user-help-article': this.current!.id },
      });
    }
  }

  @Watch('$route.query')
  private checkRouteQuery() {
    if (!('user-help-article' in this.$route.query) && !('user-help-video' in this.$route.query)) {
      return;
    }

    const id = this.$route.query['user-help-article'] || this.$route.query['user-help-video'];
    if (!id) {
      return;
    }

    if (this.current === null || this.current.id !== id) {
      this.open({
        type: 'user-help-article' in this.$route.query ? 'UserHelpArticle' : 'UserHelpVideo',
        id: (id as string),
      });

      if (!this.isOpen) {
        this.launch();
      }
    }
  }
}
