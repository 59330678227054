import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UserHelpViewMixin extends Vue {
  @Prop(String) protected id!: string;

  /**
   * Navigate back into the stack
   *
   * @private
   */
  private back() {
    this.$emit('back');
  }

  /**
   * Close the User Help Modal
   *
   * @private
   */
  private close() {
    this.$emit('close');
  }

  /**
   * Open a new route
   *
   * @param id The ID of the resource
   * @param type The type of the route
   * @private
   */
  private open(id: string, type: string) {
    this.$emit('open', { id, type });
  }
}
