









import {Component, Vue} from 'vue-property-decorator';
import AuditItem from '@/pages/AISystems/AISystemItem.vue';

@Component({
  components: {AuditItem},
})
export default class AISystemChooser extends Vue {

  private items: any = [
    {
      title: 'AI SYSTEM',
      name: 'Recapture detect',
      type: 'Neural Network',
      description: 'Classifies an image as either anomalous or original',
      icon: 'images',
      active: true,
      routeName: 'recapture-ai-check',
      inspectionChecksNamespace: 'PictureInPicture',
    },
    {
      title: 'ENSEMBLE SYSTEM',
      name: 'Serial number detect',
      type: 'Masking + Google Vision API',
      description: 'Extracts serial-number regions with a masking method after which ' +
        'OCR is applied to this sub-patch',
      icon: 'check-square',
      active: true,
      routeName: 'serial-number-check',
    },
    {
      title: 'ENSEMBLE SYSTEM',
      name: 'Address Recognition',
      type: 'Google Vision + Conditional Random Fields (CRF)',
      description: 'Finds paragraphs in images, converts these to text with Google Vision OCR API and identifies ' +
        'addresses by applying a CRF AI-system. Finally Google Geocode is used to attempt to validate the address',
      icon: 'money-check',
      routeName: '',
      active: false,
    }];


}
