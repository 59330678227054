import store from '@/store/store';
import {setContext} from 'apollo-link-context';
import {i18n} from '@/store/i18n';

export const authLink = (setContext(async (_, {headers}) => {
    headers = {...headers};

    const authorization = store.getters.authorization;

    if (authorization !== null) {
        headers.authorization = authorization;
    }

    headers['Accept-Language'] = i18n.locale;

    return {headers};
}));
