




















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class InputGroup extends Vue {
  @Prop(Boolean) private clearable!: boolean;
  @Prop(Boolean) private focus!: boolean;
  @Prop(Boolean) private open!: boolean;
  @Prop({type: Boolean, default: true}) private maxHeight!: boolean;
  @Prop(String) private error!: string;
  @Prop({type: String, default: 'right'}) private balloonPositioning!: string;
}
