import _Vue, {PluginObject} from 'vue';
import SnapshotSituation from '@/plugins/snapshot/SnapshotSituation';

const Snapshot: PluginObject<any> = {
  install: (vue: typeof _Vue) => {

    vue.prototype.$snapshot = {
      equipmentSituation: SnapshotSituation,
    };
  },
};

export default Snapshot;
