




























































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AuditItem extends Vue {
  @Prop(Object) protected item: any;

  private faClass(icon: string) {
    return `fa fa-${icon}` + ` fa-3x`;
  }
}

