import Vue from 'vue';
import Router, {RawLocation, Route, RouteConfig} from 'vue-router';
import BackOffice from '@/layouts/back-office/BackOffice.vue';
import Fallback, {toHome} from './Fallback';
import Translate from '@/router/Translate';
import {AuthGuard, AuthCallback} from '@/router/Auth';
import {ErrorHandler, Position} from 'vue-router/types/router';
import routes from '@/router/routes';
import {MenuPosition} from '@/layouts/back-office/aside/MenuPosition';

Vue.use(Router);

export interface IntradorRouteConfig extends RouteConfig {
  children?: IntradorRouteConfig[];

  title?: string | (() => string);
  icon?: string | string[];
  permission?: string | string[] | null;
  menuPosition?: MenuPosition;
  menuOrder?: number;

  activeNames?: string[];
  notActiveNames?: string[];

  homeWeight?: number;
}

function getNamedRoutes(iRoutes: IntradorRouteConfig[]): IntradorRouteConfig[] {
  let nRoutes: IntradorRouteConfig[] = [];

  iRoutes.forEach((route: IntradorRouteConfig) => {
    if (typeof route.name !== 'undefined') {
      nRoutes.push(route);
    }

    if (route.children) {
      nRoutes = nRoutes.concat(getNamedRoutes(route.children));
    }
  });

  return nRoutes;
}

function getRouterRoutes(r: IntradorRouteConfig[], auth: boolean = false): RouteConfig[] {
  return r.map((iRoute: IntradorRouteConfig) => {
    const route: RouteConfig = {
      ...iRoute,
      meta: (iRoute.meta) ? iRoute.meta : {},
    };

    if (iRoute.children) {
      route.children = getRouterRoutes(iRoute.children);
    }
    if (auth && !('auth' in route.meta)) {
      route.meta.auth = true;
    }
    if (iRoute.title) {
      route.meta.title = iRoute.title;
    }
    if (iRoute.icon) {
      route.meta.icon = iRoute.icon;
    }
    if (iRoute.permission) {
      route.meta.permissions = (Array.isArray(iRoute.permission)) ? iRoute.permission : [iRoute.permission];
    }
    if (iRoute.menuPosition) {
      route.meta.menuPosition = iRoute.menuPosition;
    }

    return route;
  });
}

export enum LastRouterAction {
  Push,
  Replace,
  Go,
  Back,
  Forward,
}

class IRouter extends Router {
  public lastRouterAction: LastRouterAction | null = null;

  public push(location: RawLocation, onComplete?: () => void, onAbort?: ErrorHandler): any {
    this.lastRouterAction = LastRouterAction.Push;

    if (!onComplete && !onAbort && typeof Promise !== 'undefined') {
      return super.push(location);
    }

    super.push(location, onComplete, onAbort);
  }

  public replace(location: RawLocation, onComplete?: () => void, onAbort?: ErrorHandler): any {
    this.lastRouterAction = LastRouterAction.Replace;

    if (!onComplete && !onAbort && typeof Promise !== 'undefined') {
      return super.replace(location);
    }

    super.replace(location, onComplete, onAbort);
  }

  public go(n: number): void {
    if (n === 1) {
      this.lastRouterAction = LastRouterAction.Forward;
    } else if (n === -1) {
      this.lastRouterAction = LastRouterAction.Back;
    } else {
      this.lastRouterAction = LastRouterAction.Go;
    }
    super.go(n);
  }

  public back(): void {
    this.lastRouterAction = LastRouterAction.Back;
    super.back();
  }

  public forward(): void {
    this.lastRouterAction = LastRouterAction.Forward;
    super.forward();
  }
}

const router = new IRouter({
  mode: 'history',

  base: process.env.BASE_URL,

  scrollBehavior(to: Route, from: Route, savedPosition: Position | void) {
    if (to.name !== from.name) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {x: 0, y: 0};
      }
    }
  },

  routes: [
    {
      path: '/:lang(\\w{2}-\\w{2})',
      component: BackOffice,
      beforeEnter: Translate,
      children: [
        {
          name: 'home',
          path: '',
          beforeEnter: toHome,
        },
        {
          name: '404',
          path: '404',
          // TODO: This must be changed to a 404 component
          component: {
            render(c: any) {
              return c('router-view');
            },
          },
        },
        ...getRouterRoutes(routes, true),
      ],
    },
    {
      name: 'auth-callback',
      path: '/auth/callback',
      beforeEnter: AuthCallback,
    },
    {
      name: 'fallback',
      path: '*',
      beforeEnter: Fallback,
    },
  ],
});


window.addEventListener('popstate', (event) => {
  const state = event.state;

  if (state) {
    router.lastRouterAction = null;
  }
});

router.beforeEach(AuthGuard);

// Attach previous route on router params
router.beforeEach((to: Route, from: Route, next: ((vm?: any) => void)) => {
  const previous = (router.lastRouterAction === LastRouterAction.Push ||
    router.lastRouterAction === LastRouterAction.Forward)
    ? from
    : from.meta.previous;

  if (previous && previous.name) {
    to.meta.previous = previous;
  }

  next();
});

export const namedRoutes = getNamedRoutes(routes);

export default router;
