import _Vue, {PluginObject} from 'vue';


export function pastDays(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date;
}

export function sameDate(dateA: Date, dateB: Date): boolean {
  return dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate();
}

export function toDate12Hour(date: Date): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0));
}


const DatePlugin: PluginObject<any> = {
  install: (vue: typeof _Vue) => {
    vue.prototype.$toDate12Hour = (date: Date) => {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0));
    };
  },
};

export default DatePlugin;
