

























































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class SerialNumberCheck extends Vue {
  @Prop(Object) protected audit!: any;

  private top = true;
  private btnIndex: number = 0;
  private keyPressListener: ((event: KeyboardEvent) => void) | null = null;

  private created() {
    this.keyPressListener = this.keyPress.bind(this);
    document.addEventListener('keydown', this.keyPressListener);
  }

  private beforeDestroy() {
    if (this.keyPressListener) {
      document.removeEventListener('keydown', this.keyPressListener);
    }
  }

  private keyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.saveByBtnIndex();
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      this.setBtnIndex((this.btnIndex + 1) % 3);
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      this.setBtnIndex((this.btnIndex + 2) % 3);
    }
  }

  private setBtnIndex(index: number) {
    this.btnIndex = index;
  }

  private saveByBtnIndex() {
    switch (this.btnIndex) {
      case 0:
        this.serialNumberCorrect();
        break;
      case 1:
        this.serialNumberInCorrect();
        break;
      case 2:
        this.serialNumberNotReadable();
        break;
    }
    this.btnIndex = 0;
  }

  get serialNumberImage() {
    return this.audit.images.find((i: any) => {
      return i.original_url && (i.original_url.endsWith('.jpeg') || i.original_url.endsWith('.jpg'));
    });
  }

  get serialNumberImageUrl() {
    const image = this.serialNumberImage;

    return (image) ? image.original_url : null;
  }

  private serialNumberCorrect() {
    this.save(true);
  }

  private serialNumberInCorrect() {
    this.save(false);
  }

  private serialNumberNotReadable() {
    this.save(false, false);
  }

  private async save(correct: boolean, readable: boolean = true) {
    this.$emit('saving', {correct, readable});

    const result = await this.$apollo.mutate({
      mutation: require('@/graphql/mutations/SerialNumberChecked.gql'),
      variables: {
        id: this.audit.id,
        correct,
        readable,
        imageId: this.serialNumberImage?.id,
      },
    });

    this.$emit('saved', {correct, readable});
  }
}
