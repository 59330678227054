























import {Component, Vue} from 'vue-property-decorator';
import SerialNumberCheck from '@/components/snapshot/SerialNumberCheck.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {SerialNumberCheck, Spinner},
})
export default class SerialNumberAudit extends Vue {
  private next() {
    ((this.$refs.query as any).getApolloQuery() as any).refetch();
  }
}
