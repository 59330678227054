
export const copy = {
  inserted: (el: HTMLElement, directive: any, vue: any) => {
    el.style.userSelect = 'all';
    el.style.cursor = 'pointer';
    el.addEventListener('click', (event: MouseEvent) => {
      // Copy the selected text
      document.execCommand('copy');
    });

    // Get translation and current label
    const text = vue.context.$it('global.click-to-copy', 'Click to copy');
    const label = el.getAttribute('aria-label');

    // Add tooltip
    el.setAttribute('aria-label', label ? `${text}: ${label}` : text);

    // Specify direction if not set
    el.dataset.balloonPos = el.dataset.balloonPos || 'down';
  },
};

export default copy;
