


















import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  SearchUserHelpCategories_searchUserHelp_UserHelpFAQ,
  UserHelpCategory_userHelpCategory_faqs,
} from '@/types/intrador';

type FAQ = SearchUserHelpCategories_searchUserHelp_UserHelpFAQ | UserHelpCategory_userHelpCategory_faqs;

@Component
export default class UserHelpFAQCard extends Vue {
  @Prop(Object) protected faq!: FAQ;
  @Prop(Boolean) protected open!: boolean;
  @Prop(Function) protected click!: (faq: FAQ) => void;
}
