import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {graphqlClient} from '@/store/apollo/apollo';
import store from '@/store/store';
import {AllLanguages_allLanguages} from '@/types/intrador';

let fetchingLanguages: boolean = false;

@Module
export default class Language extends VuexModule {
  protected languages: AllLanguages_allLanguages[] | null = null;

  get languageName() {
    return (code: string): string => {
      if (this.languages === null) {
        store.dispatch('getLanguages');
        return code;
      }

      const language = this.languages.find((l: AllLanguages_allLanguages) => l.languageCode === code);
      return (language) ? language.name! : code;
    };
  }

  get localName() {
    return (code: string): string => {
      if (this.languages === null) {
        store.dispatch('getLanguages');
        return code;
      }

      const language = this.languages.find((l: AllLanguages_allLanguages) => l.languageCode === code);
      return (language) ? language.localTranslation! : code;
    };
  }

  @Action({})
  private getLanguages() {
    if (fetchingLanguages) {
      return;
    }
    fetchingLanguages = true;

    graphqlClient.query({
      query: require('@/graphql/Languages.gql'),
    }).then((data: any) => {
      store.dispatch('languagesReceived', data.data.allLanguages);
    });
  }

  @Action({commit: 'setLanguages'})
  private languagesReceived(languages: AllLanguages_allLanguages[]) {
    return languages;
  }

  @Mutation
  private async setLanguages(languages: AllLanguages_allLanguages[]) {
    this.languages = languages;
  }
}
