

























import {Component, Prop, Vue} from 'vue-property-decorator';
import {MenuPosition} from '@/layouts/back-office/aside/MenuPosition';

export interface AsideMenuItem {
  name?: string | null;
  title: string;
  icon: string|string[];
  path?: string | null;
  permission?: string | string[] | null;
  action?: () => void;
  menuPosition?: MenuPosition;
  activeNames?: string[];
  notActiveNames?: string[];
  order: number;
}

@Component({})
export default class AsideItem extends Vue {
  @Prop(Object) protected item!: AsideMenuItem;
  @Prop(Boolean) protected open!: boolean;
  @Prop(Boolean) protected highlight?: boolean;

  private get routerLinkClasses() {
    return {
      'highlight': this.highlight,
      'router-link-active': this.active,
      'router-link-not-active': this.notActive,
    };
  }

  private get active() {
    if (!this.$route.name) {
      return false;
    }

    return (this.item.activeNames ?? []).indexOf(this.$route.name) >= 0;
  }

  private get notActive() {
    if (!this.$route.name) {
      return false;
    }

    return (this.item.notActiveNames ?? []).indexOf(this.$route.name) >= 0;
  }
}
