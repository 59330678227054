













import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  SearchUserHelpCategories_searchUserHelp_UserHelpArticle,
  UserHelpCategory_userHelpCategory_articles,
} from '@/types/intrador';

type Article = SearchUserHelpCategories_searchUserHelp_UserHelpArticle | UserHelpCategory_userHelpCategory_articles;

@Component
export default class UserHelpArticleCard extends Vue {
  @Prop(Object) protected article!: Article;
  @Prop(Function) protected click!: (article: Article) => void;
}
