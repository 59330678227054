import i18n from '@/store/i18n';

export enum DateTimeFormats {
  Date,
  Time,
  DateTime,
  DateTimeNoYear,
}

const defaultDateTimeFormats: any = {
  [DateTimeFormats.Date]: 'd-M-Y',
  [DateTimeFormats.Time]: 'H:i',
  [DateTimeFormats.DateTime]: 'd-M-Y H:i',
  [DateTimeFormats.DateTimeNoYear]: 'd-M H:i',
};

let shortTranslation: any = {};
let longTranslation: any = {};

export function shortMonthTranslation(month: number) {
  if (Object.keys(shortTranslation).length !== 12) {
    shortTranslation = {
      1: i18n.it('months.january.short', 'Jan'),
      2: i18n.it('months.february.short', 'Feb'),
      3: i18n.it('months.march.short', 'Mar'),
      4: i18n.it('months.april.short', 'Apr'),
      5: i18n.it('months.may.short', 'May'),
      6: i18n.it('months.june.short', 'Jun'),
      7: i18n.it('months.july.short', 'Jul'),
      8: i18n.it('months.august.short', 'Aug'),
      9: i18n.it('months.september.short', 'Sept'),
      10: i18n.it('months.october.short', 'Oct'),
      11: i18n.it('months.november.short', 'Nov'),
      12: i18n.it('months.december.short', 'Dec'),
    };
  }

  return shortTranslation[month] || '';
}

export function longMonthTranslation(month: number) {
  if (Object.keys(longTranslation).length !== 12) {
    longTranslation = {
      1: i18n.it('months.january.long', 'January'),
      2: i18n.it('months.february.long', 'February'),
      3: i18n.it('months.march.long', 'March'),
      4: i18n.it('months.april.long', 'April'),
      5: i18n.it('months.may.long', 'May'),
      6: i18n.it('months.june.long', 'June'),
      7: i18n.it('months.july.long', 'July'),
      8: i18n.it('months.august.long', 'August'),
      9: i18n.it('months.september.long', 'September'),
      10: i18n.it('months.october.long', 'October'),
      11: i18n.it('months.november.long', 'November'),
      12: i18n.it('months.december.long', 'December'),
    };
  }

  return longTranslation[month] || '';
}

export default function(value: string | Date, format: DateTimeFormats | string = DateTimeFormats.DateTime): string {
  if (typeof format === 'number') {
    format = defaultDateTimeFormats[format];
  }

  const date: Date = (typeof value === 'string')
    ? new Date(value)
    : value;

  if (typeof format === 'string') {
    let mask: string = format;

    const month: number = date.getMonth() + 1;
    const year: string = date.getFullYear().toString();

    const matches = mask.match(/\w/g) || [];
    matches.forEach((character) => {
      switch (character) {
        /* Days */
        case 'd':
          mask = mask.replace('d', withLeadingZero(date.getDate()));
          break;
        case 'j':
          mask = mask.replace('j', withLeadingZero(date.getDate()));
          break;

        /* Months */
        case 'F':
          mask = mask.replace('F', longMonthTranslation(month));
          break;
        case 'm':
          mask = mask.replace('m', withLeadingZero(month));
          break;
        case 'M':
          mask = mask.replace('M', shortMonthTranslation(month));
          break;
        case 'n':
          mask = mask.replace('n', month.toString());
          break;

        /* Years */
        case 'Y':
          mask = mask.replace('Y', year);
          break;
        case 'y':
          mask = mask.replace('y', year.substr(year.length - 2));
          break;

        /* Time */
        case 'G':
          mask = mask.replace('G', date.getHours().toString());
          break;
        case 'H':
          mask = mask.replace('H', withLeadingZero(date.getHours()));
          break;
        case 'i':
          mask = mask.replace('i', withLeadingZero(date.getMinutes()));
          break;
        case 's':
          mask = mask.replace('s', withLeadingZero(date.getSeconds()));
          break;
      }
    });

    return mask;
  }

  return date.toDateString();
}

function withLeadingZero(value: number | string): string {
  value = value.toString();

  return (value.length === 1)
    ? '0' + value
    : value;
}
