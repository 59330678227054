import {InspectionStatus} from '@/types/intrador';
import i18n from '@/store/i18n';
import {FilterSelectOption} from '@/layouts/back-office/elements/filters/SelectFilter.vue';
import {BarChartSeries} from '@/layouts/back-office/elements/charts/BarChartMixin';

export const statusKeys = Object.keys(InspectionStatus);

export function getInspectionStatusTranslation(key: InspectionStatus): string|null {
  switch (key) {
    case InspectionStatus.CREATED:
      return i18n.it('inspection.status.created.title', 'Created') as string;
    case InspectionStatus.PLANNED:
      return i18n.it('inspection.status.planned.title', 'Planned') as string;
    case InspectionStatus.STARTED:
      return i18n.it('inspection.status.started.title', 'Started') as string;
    case InspectionStatus.PAUSED:
      return i18n.it('inspection.status.paused.title', 'Paused') as string;
    case InspectionStatus.FINISHED:
      return i18n.it('inspection.status.finished.title', 'Finished') as string;
    case InspectionStatus.ON_HOLD:
      return i18n.it('inspection.status.on-hold.title', 'On hold') as string;
    case InspectionStatus.EXPIRED:
      return i18n.it('inspection.status.expired.title', 'Expired') as string;
    case InspectionStatus.SUBMITTED:
      return i18n.it('inspection.status.submitted.title', 'Submitted') as string;
    case InspectionStatus.ACCEPTED:
      return i18n.it('inspection.status.accepted.title', 'Accepted') as string;
    case InspectionStatus.DECLINED:
      return i18n.it('inspection.status.declined.title', 'Declined') as string;
    case InspectionStatus.TRANSFER_PENDING:
      return i18n.it('inspection.status.transfer-pending.title', 'Transfer pending') as string;
    case InspectionStatus.CANCELLED:
      return i18n.it('inspection.status.cancelled.title', 'Cancelled') as string;
  }

  return null;
}

export function getStatusColor(key: InspectionStatus): string {
  switch (key) {
    case InspectionStatus.CREATED:
      return '#666666';
    case InspectionStatus.PLANNED:
      return '#3DB89A';
    case InspectionStatus.STARTED:
      return '#f9d89f';
    case InspectionStatus.PAUSED:
      return '#d88e0e';
    case InspectionStatus.FINISHED:
      return '#F4B850';
    case InspectionStatus.ON_HOLD:
      return '#ffa500';
    case InspectionStatus.EXPIRED:
      return '#D74655';
    case InspectionStatus.DECLINED:
      return '#a72532';
    case InspectionStatus.SUBMITTED:
      return '#563F7A';
    case InspectionStatus.ACCEPTED:
      return '#8ABF59';
    case InspectionStatus.TRANSFER_PENDING:
      return '#2197AE';
    case InspectionStatus.CANCELLED:
      return '#212529';
  }

  return '#666666';
}

function getStatusFilterOptions(keys: string[] = []): FilterSelectOption[] {
  const statusOptions: FilterSelectOption[] = [];

  if (keys.length === 0) {
    keys = statusKeys;
  }

  keys.forEach((key: string) => {
    statusOptions.push({
      key,
      label: getInspectionStatusTranslation(key as InspectionStatus)!,
    });
  });

  return statusOptions;
}

export function getStatusSeries(keys: string[] = []): BarChartSeries[] {
  const statusOptions: BarChartSeries[] = [];

  if (keys.length === 0) {
    keys = statusKeys;
  }

  keys.forEach((key: string) => {
    statusOptions.push({
      key,
      label:  getInspectionStatusTranslation(key as InspectionStatus)!,
      color: getStatusColor(key as InspectionStatus),
    });
  });

  return statusOptions;
}

export default {
  keys: statusKeys,
  getTranslation: getInspectionStatusTranslation,

  getFilterOptions: getStatusFilterOptions,
  getSeries: getStatusSeries,
};
