import store from '@/store/store';
import {RawLocation, Route, RouteRecord} from 'vue-router';
import {to404} from '@/router/Fallback';

export async function canProceed(permissions: string[] = [], to: Route|null = null): Promise<boolean> {
  if (to !== null) {
    await store.dispatch('authorize', to);
  }

  if (permissions.length <= 0) {
    return true;
  }

  await store.dispatch('resolveAccount');

  return store.getters.can(permissions);
}

export async function AuthGuard(to: Route, from: Route, next: (to?: RawLocation) => void) {
  await store.dispatch('initAuthentication');

  let needAuth: boolean = false;
  let permissions: string[] = [];

  to.matched.forEach((match: RouteRecord) =>  {
    needAuth = (needAuth || ('auth' in match.meta && match.meta.auth));
    if ('permissions' in match.meta) {
      permissions = permissions.concat(match.meta.permissions);
    }
  });

  if (!needAuth) {
    return next();
  }

  if (await canProceed(permissions, to)) {
    return next();
  }

  to404(to, next);
}

export function AuthCallback(to: Route, from: Route, next: (to?: RawLocation) => void) {
  store.dispatch('resolveCallback', to)
    .then((token) => {
      next({path: token.referral});
    });
}
