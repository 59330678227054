






import {Component, Vue} from 'vue-property-decorator';
import {Meta} from '@sophosoft/vue-meta-decorator';

@Component({
  metaInfo: {
    titleTemplate: '%s - Intrador',
  },
})
export default class App extends Vue {

  @Meta
  private getMetaInfo() {
    return {
      title: this.$it('global.intrador', 'Intrador') as string,
    };
  }

}
