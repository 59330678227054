






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class Spinner extends Vue {
  @Prop({ type: String, default: null }) protected color!: string;
  @Prop({ type: Number, default: 24 }) protected size!: string;
}
