

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import {
  UserHelpArticleVariables,
  UserHelpCategoryVariables,
  UserHelpVideoVariables,
  UserHelpArticle_userHelpArticle,
  UserHelpCategory_userHelpCategory,
  UserHelpVideo_userHelpVideo,
} from '@/types/intrador';
import Error from '@/components/elements/error/Error.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({ components: { Error, Spinner }})
export default class UserHelpView extends Vue {
  @Prop(Object) protected query!: any;
  @Prop(Object) protected variables!: UserHelpCategoryVariables | UserHelpArticleVariables | UserHelpVideoVariables;
  @Prop(String) protected dataKey!: string;
  @Prop(String) protected title!: string;
  @Prop(Function) protected back?: void;

  private loading = true;
  private error: any = null;
  private data:
    UserHelpCategory_userHelpCategory |
    UserHelpArticle_userHelpArticle |
    UserHelpVideo_userHelpVideo | null = null;

  private mounted() {
    this.load();
  }

  @Watch('variables')
  private async load() {
    this.loading = true;
    this.error = null;

    try {
      const { data } = await this.$apollo.query({
        query: this.query,
        variables: this.variables,
      });

      if (data && data[this.dataKey]) {
        this.data = data[this.dataKey];
      }
    } catch (e) {
      this.error = e;
    }

    this.loading = false;
  }

  private isExternalLink(link: string) {
    return link.substr(0, 4) === 'http';
  }

  private followInternalLink(link: string) {
    // Open the link via path
    if (link.indexOf('/') >= 0) {
      this.$emit('close');
      this.$router.push({ path: `/${this.$i18n.locale}/${link}` });
      return;
    }

    // Query param
    if (link.substr(0, 1) === '?') {
      // Convert to query dictionary
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: link.substr(1).split('&').reduce((o: { [key: string]: string | null }, p: string) => {
          const i = p.split('=');
          if (i.length < 2) {
            return o;
          }

          return {
            ...o,
            [i[0]]: i[1] || null,
          };
        }, {}),
      });

      return;
    }

    // Open the link via name
    this.$emit('close');
    this.$router.push({ name: link });
  }
}
