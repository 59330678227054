








import {Component, Vue, ProvideReactive} from 'vue-property-decorator';

@Component({})
export default class BackOffice extends Vue {
  @ProvideReactive() private scrollY: number = 0;

  private onScroll(event: any) {
    this.scrollY = event.target.scrollTop;
  }
}
