import { render, staticRenderFns } from "./UserHelpVideoCard.vue?vue&type=template&id=465996cf&scoped=true&functional=true&"
import script from "./UserHelpVideoCard.vue?vue&type=script&lang=ts&"
export * from "./UserHelpVideoCard.vue?vue&type=script&lang=ts&"
import style0 from "./UserHelpVideoCard.vue?vue&type=style&index=0&id=465996cf&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "465996cf",
  null
  
)

export default component.exports