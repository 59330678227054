
















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class Logo extends Vue {
  @Prop(Boolean) protected showBigLogo!: boolean;

  get logoUrl() {
    const logoBig = process.env.VUE_APP_LOGO;
    const logoSmall = process.env.VUE_APP_LOGO_SMALL;

    if (!this.showBigLogo && logoSmall) {
      return logoSmall;
    } else if (logoBig) {
      return logoBig;
    }

    return null;
  }
}
