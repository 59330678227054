
























import {Component, Emit, Prop, Model, Watch, Vue, Mixins} from 'vue-property-decorator';
import InputGroup from '@/layouts/back-office/elements/input/InputGroup.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({
  components: {InputGroup},
})
export default class InputText extends Mixins(InputMixin) {
  @Prop({type: String, default: 'text'}) private type!: string;
  @Prop({type: Boolean, default: true}) private hideAutofillChrome?: boolean;
  @Prop(Number) private step?: string;
  @Prop(Number) private min?: string;
  @Prop(Number) private max?: string;

  // function returns placeholder text on focus, empty on non-focus
  get placeholder() {
    if (this.focus) {
      return this.placeholderText;
    } else {
      return '';
    }
  }

  get doAutoCompleteChrome() {
    return this.hideAutofillChrome ? 'chrome-off' : 'on';
  }

  public becomeFirstResponder() {
    (this.$refs.input as HTMLInputElement).focus();
  }

  private tabPressed() {
    this.$emit('tab');
  }

  private enterPressed() {
    this.$emit('enter');
  }
}
