import i18n from '@/store/i18n';
import { IntradorRouteConfig } from '@/router/router';
import { MenuPosition } from '@/layouts/back-office/aside/MenuPosition';
import SerialNumber from '@/pages/AISystems/SerialNumberAudit.vue';
import RecaptureAIAudit from '@/pages/AISystems/RecaptureAIAudit.vue';
import AuditChooser from '@/pages/AISystems/AISystemChooser.vue';

const Render = {
  render(c: any) {
    return c('router-view');
  },
};

const routes: IntradorRouteConfig[] = [
  {
    name: 'global-dashboard',
    path: 'global',
    icon: 'globe',
    title: () => i18n.it('menu.global-dashboard.title', 'Global Dashboard') as string,
    component: () => {
      return import(/* webpackChunkName: "entity-dashboard" */'../pages/entity/Dashboard.vue')
        .then((m) => m.default);
    },
    permission: 'entity-children-get',
    menuPosition: MenuPosition.TOP,

    menuOrder: 1,
    homeWeight: 1,
  },
  {
    name: 'dashboard',
    path: 'dashboard',
    icon: 'tachometer-alt',
    title: () => i18n.it('menu.dashboard.title', 'Dashboard') as string,
    component: () => {
      return import(/* webpackChunkName: "dashboard" */'../pages/DashboardAssets.vue')
        .then((m) => m.default);
    },
    permission: 'assets-dashboard',
    menuPosition: MenuPosition.TOP,

    menuOrder: 1,
    homeWeight: 1,
  },
  {
    name: 'management-dashboard',
    path: 'management-dashboard',
    icon: 'tachometer-alt',
    title: () => i18n.it('menu.management.dashboard.title', 'Management Dashboard') as string,
    component: () => {
      return import(/* webpackChunkName: "management-dashboard" */'../pages/Dashboard.vue')
        .then((m) => m.default);
    },
    permission: 'inspections-dashboard',
    menuPosition: MenuPosition.TOP,

    menuOrder: 1,
    homeWeight: 1,
  },
  {
    path: 'planning',
    component: Render,
    permission: ['audit-planning-get'],
    children: [
      {
        name: 'planning',
        path: '',
        icon: 'calendar-day',
        title: () => i18n.it('menu.planning.title', 'Planning') as string,
        component: () => {
          return import(/* webpackChunkName: "planning" */'../pages/planning/Planning.vue')
            .then((m) => m.default);
        },
        props: true,
        permission: ['audit-planning-get'],
        menuPosition: MenuPosition.TOP,

        menuOrder: 3,
        homeWeight: 2,
      },
    ],
  },
  {
    path: 'locations',
    component: Render,
    permission: ['inspections-get|inspections-get-by-entity', 'dealers-get'],
    children: [
      {
        name: 'locations',
        path: '',
        icon: 'map-marked',
        title: () => i18n.it('menu.locations.title', 'Locations') as string,
        component: () => {
          return import(/* webpackChunkName: "locations" */'../pages/location/Locations.vue')
          .then((m) => m.default);
        },
        props: true,
        permission: ['dealers-branches-get', 'dealers-get'],
        menuPosition: MenuPosition.TOP,
        activeNames: ['dealers-detail', 'branches-detail'],
        menuOrder: 4,
        homeWeight: 3,
      },
    ],
  },
  {
    name: 'dealers-detail',
    path: 'dealers/:id',
    component: () => {
      return import(/* webpackChunkName: "dealer-detail" */'../pages/dealers/detail/Detail.vue')
      .then((m) => m.default);
    },
    children: [
      {
        name: 'branches-detail',
        path: 'locations/:branchId',
        permission: 'dealers-branches-get',
        component: () => {
          return import(/* webpackChunkName: "dealer-detail" */'../pages/dealers/detail/Detail.vue')
          .then((m) => m.default);
        },
      },
    ],
  },
  {
    path: 'audits',
    component: Render,
    permission: ['inspections-get|inspections-get-by-entity', 'dealers-get'],
    children: [
      {
        name: 'audits-dashboard',
        path: 'dashboard',
        icon: 'list-alt',
        title: () => i18n.it('menu.audit.dashboard.title', 'Audit Dashboard') as string,
        component: () => {
          return import(/* webpackChunkName: "audits-dashboard" */'../pages/audits/Dashboard.vue')
            .then((m) => m.default);
        },
        props: true,
        permission: ['inspections-get|inspections-get-by-entity', 'dealers-get'],
        menuPosition: MenuPosition.TOP,

        menuOrder: 2,
        homeWeight: 2,
      },
      {
        name: 'audits',
        path: '',
        icon: 'archive',
        title: () => i18n.it('menu.audits.title', 'Audits') as string,
        component: () => {
          return import(/* webpackChunkName: "audits" */'../pages/audits/Audits.vue')
            .then((m) => m.default);
        },
        props: true,
        permission: ['inspections-get|inspections-get-by-entity', 'dealers-get'],
        menuPosition: MenuPosition.TOP,

        notActiveNames: ['audits-dashboard'],

        menuOrder: 5,
        homeWeight: 2,
      },
      {
        name: 'audits-detail',
        path: ':auditId',
        component: () => {
          return import(/* webpackChunkName: "audits-detail" */'../pages/audits/detail/Detail.vue')
            .then((m) => m.default);
        },
      },
    ],
  },
  {
    path: 'dealer-dasboard',
    component: Render,
    permission: ['dealer-dashboard-get'],
    children: [
      {
        name: 'dealer-dashboard',
        path: '',
        icon: 'tachometer-alt',
        title: () => i18n.it('menu.dashboard.title', 'Dashboard') as string,
        component: () => {
          return import(/* webpackChunkName: "dealer-dashboard" */'../pages/dealer/Dashboard.vue')
            .then((m) => m.default);
        },
        props: true,
        permission: ['dealer-dashboard-get'],
        menuPosition: MenuPosition.TOP,
        homeWeight: 1,
      },
    ],
  },
  // {
  //   path: 'dealer-branches',
  //   component: Render,
  //   permission: ['dealer-branches-get'],
  //   children: [
  //     {
  //       name: 'dealer-branches',
  //       path: '',
  //       icon: 'warehouse',
  //       title: () => i18n.it('menu.branches.title', 'Branches') as string,
  //       component: () => {
  //         return import(/* webpackChunkName: "dealer-branches" */'../pages/dealer/Branches.vue')
  //           .then((m) => m.default);
  //       },
  //       props: true,
  //       permission: ['dealer-branches-get'],
  //       menuPosition: MenuPosition.TOP,
  //       homeWeight: 2,
  //     },
  //   ],
  // },
  {
    path: 'dealer-accounts',
    component: Render,
    permission: ['dealer-accounts-get'],
    children: [
      {
        name: 'dealer-accounts',
        path: '',
        icon: 'users',
        title: () => i18n.it('menu.accounts.title', 'Accounts') as string,
        component: () => {
          return import(/* webpackChunkName: "dealer-accounts" */'../pages/dealer/Accounts.vue')
            .then((m) => m.default);
        },
        props: true,
        permission: ['dealer-accounts-get'],
        menuPosition: MenuPosition.TOP,
        homeWeight: 3,
      },
    ],
  },
  {
    path: 'inspections',
    component: Render,
    permission: ['inspections-get|inspections-get-by-entity'],
    children: [
      {
        name: 'inspections',
        title: () => i18n.it('menu.inspections.title', 'Inspections') as string,
        path: '',
        icon: 'tasks',
        component: () => {
          return import(/* webpackChunkName: "inspection" */'../pages/inspection/Inspections.vue')
            .then((m) => m.default);
        },
        props: true,
        permission: ['inspections-get|inspections-get-by-entity'],
        menuPosition: MenuPosition.TOP,
        homeWeight: 3,
      },
      {
        name: 'inspections-create',
        path: 'create',
        permission: 'inspections-create',
        component: () => {
          return import(/* webpackChunkName: "inspection-create" */'../pages/inspection/create/Create.vue')
            .then((m) => m.default);
        },
      },
      {
        name: 'inspections-detail',
        path: ':id',
        component: () => {
          return import(/* webpackChunkName: "inspection-detail" */'../pages/inspection/detail/Detail.vue')
            .then((m) => m.default);
        },
      },
      {
        name: 'inspections-edit',
        path: ':id/edit',
        permission: 'inspections-edit',
        component: () => {
          return import(/* webpackChunkName: "inspection-edit" */'../pages/inspection/edit/Edit.vue')
            .then((m) => m.default);
        },
      },
    ],
  },
  {
    path: 'assets',
    component: Render,
    permission: ['assets-get'],
    children: [
      {
        name: 'assets',
        title: () => i18n.it('menu.assets.title', 'Assets') as string,
        path: '',
        icon: 'folder-open',
        permission: ['assets-get'],
        component: () => {
          return import(/* webpackChunkName: "assets" */'../pages/assets/Assets.vue')
            .then((m) => m.default);
        },
        props: true,
        menuPosition: MenuPosition.TOP,
        homeWeight: 3,
      },
      {
        name: 'assets-detail',
        path: ':id',
        component: () => {
          return import(/* webpackChunkName: "assets-detail" */'../pages/assets/detail/Detail.vue')
            .then((m) => m.default);
        },
      },
      {
        name: 'auction-detail',
        path: ':id',
        component: () => {
          return import(/* webpackChunkName: "auction-detail" */'../pages/assets/Assets.vue')
            .then((m) => m.default);
        },
      },
    ],
  },
  {
    name: 'inspections-import',
    path: 'import',
    icon: 'download',
    title: () => i18n.it('menu.import.title', 'Import') as string,
    component: () => {
      return import(/* webpackChunkName: "inspections-import" */'../pages/inspection/import/Import.vue')
        .then((m) => m.default);
    },
    permission: ['assets-import', 'inspections-bulk-create'],
    menuPosition: MenuPosition.BOTTOM,
  },
  {
    name: 'label-check',
    component: AuditChooser,
    path: 'label-check',
    icon: 'atom',
    title: () => i18n.it('menu.label-check.title', 'AI Systems') as string,
    permission: 'serial-number-check',
    menuPosition: MenuPosition.BOTTOM,
  },
  {
    name: 'serial-number-check',
    component: SerialNumber,
    path: 'serial-number-check',
    icon: 'images',
    title: () => i18n.it('menu.serial-number-check.title', 'Serial number check') as string,
    permission: 'serial-number-check',
  },
  {
    name: 'recapture-ai-check',
    component: RecaptureAIAudit,
    path: 'recapture-ai-check',
    icon: 'images',
    title: () => i18n.it('menu.recapture-ai-check.title', 'Recapture AI check') as string,
    permission: 'serial-number-check',
  },
  {
    name: 'accounts',
    component: () => {
      return import(/* webpackChunkName: "accounts" */'../pages/accounts/Accounts.vue')
        .then((m) => m.default);
    },
    path: 'accounts',
    icon: 'users',
    props: true,
    title: () => i18n.it('menu.accounts.title', 'Accounts') as string,
    permission: 'entity-users-get|children-users-get|dealers-accounts-get|appraisers-users-get|bidders-users-get',
    menuPosition: MenuPosition.BOTTOM,
  },
  {
    name: 'flows',
    component: () => {
      return import(/* webpackChunkName: "flows" */'../pages/flows/Flows.vue')
        .then((m) => m.default);
    },
    path: 'flows',
    icon: 'cogs',
    props: true,
    title: () => i18n.it('menu.flows.title', 'Edit flows') as string,
    permission: 'inspections-flow-edit',
    menuPosition: MenuPosition.BOTTOM,
  },
  {
    path: 'template-builder',
    component: Render,
    permission: ['inspection_template.view'],
    children: [
      {
        name: 'tb-overview',
        component: () => {
          return import(/* webpackChunkName: "tb-overview" */'../pages/template-builder/ListTemplates.vue')
            .then((m) => m.default);
        },
        path: 'overview',
        icon: 'cogs',
        props: true,
        title: () => `${i18n.it('tb.title', 'Template Builder')} (bèta)`,
        permission: 'inspection_template.view',
        menuPosition: MenuPosition.BOTTOM,
      },
      {
        name: 'tb-detail',
        component: () => {
          return import(/* webpackChunkName: "tb-detail" */'../pages/template-builder/detail/DetailTemplate.vue')
            .then((m) => m.default);
        },
        permission: 'inspection_template.view',
        path: 'detail/:id',
      },
    ],
  },
];

export default routes;
