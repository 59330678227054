












































































import {Component, Vue} from 'vue-property-decorator';
import AsideItem, {AsideMenuItem} from '@/layouts/back-office/aside/AsideItem.vue';
import IconLogout from '@/layouts/back-office/icons/Logout.vue';
import IconMenu from '@/layouts/back-office/icons/Menu.vue';
import md5 from 'md5';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import Logo from '@/layouts/back-office/icons/Logo.vue';
import ActionMenu, {
  ActionMenuDirection,
  ActionMenuItem,
  ActionMenuType,
} from '@/layouts/back-office/elements/ActionMenu.vue';
import {IntradorRouteConfig, namedRoutes} from '@/router/router';
import routes from '@/router/routes';
import {MenuPosition} from '@/layouts/back-office/aside/MenuPosition';
import {getHomeRouteName} from '@/router/Fallback';
import RequestDemoForUserModal from '@/layouts/back-office/aside/RequestDemoForUserModal.vue';
import {Account_account, Account_account_canSwitchToEntities} from '@/types/intrador';
import UserHelpModal from '@/components/user-help/UserHelpModal.vue';

@Component({
  components: {
    UserHelpModal,
    // HelpModal,
    RequestDemoForUserModal,
    ActionMenu,
    Logo,
    Spinner,
    AsideItem,
    IconLogout,
    IconMenu,
  },
})
export default class Aside extends Vue {
  private open: boolean = true;

  private accountActionMenuDirection: ActionMenuDirection = ActionMenuDirection.TopLeft;

  private homeRouteName: string = 'home';

  private menuTop: AsideMenuItem[] = [];
  private menuBottom: AsideMenuItem[] = [];

  private switchTo: string | null = null;

  private created() {
    getHomeRouteName()
      .then((name: string) => {
        this.homeRouteName = name;
      });

    const menuItems = this.getMenuItems(routes);

    this.menuTop = menuItems.filter((item: AsideMenuItem) => item.menuPosition === MenuPosition.TOP)
      .sort((a: AsideMenuItem, b: AsideMenuItem) => a.order - b.order);

    this.menuBottom = menuItems.filter((item: AsideMenuItem) => item.menuPosition === MenuPosition.BOTTOM)
      .sort((a: AsideMenuItem, b: AsideMenuItem) => a.order - b.order);

    this.open = localStorage.getItem('aside') === 'open';
    this.$emit('aside-changed', this.open);
  }

  private getMenuItems(iRoutes: IntradorRouteConfig[]): AsideMenuItem[] {
    return namedRoutes.map((route: IntradorRouteConfig) => {
      const title = (typeof route.title === 'function')
        ? route.title()
        : route.title!;

      const item: AsideMenuItem = {
        name: route.name,
        icon: route.icon!,
        title,
        permission: route.permission,
        menuPosition: route.menuPosition,
        activeNames: route.activeNames,
        notActiveNames: route.notActiveNames,
        order: route.menuOrder ?? 999,
      };

      return item;
    });
  }

  private getAccountActionMenuItems(account: Account_account): ActionMenuItem[] {
    const accountActionMenuItems: ActionMenuItem[] = [];

    if (this.$can('entity-switch') && account.canSwitchToEntities && account.canSwitchToEntities.length > 0) {
      accountActionMenuItems.push({
        label: this.$it('global.user-menu.switch-entity.header', 'Switch entity').toString(),
        type: ActionMenuType.Header,
      });

      account.canSwitchToEntities.forEach((entity: Account_account_canSwitchToEntities | null) => {
        if (entity && entity.id !== account.entity!.id) {
          accountActionMenuItems.push({
            // Intrador & Intrador Demo
            pinned: entity.id === '1' || entity.id === '10857',
            label: entity.name!,
            action: () => {
              this.switchEntity(entity);
            },
          });
        }
      });
    }

    accountActionMenuItems.push({
      label: this.$it('global.user-menu.toolkit.header', 'Tools').toString(),
      type: ActionMenuType.Header,
    });

    accountActionMenuItems.push({
      label: this.$it('global.user-menu.toolkit.request-demo-button', 'Request Demo').toString(),
      action: this.requestDemoForUser,
    });

    accountActionMenuItems.push({
      label: this.$it('global.user-menu.user-settings.header', 'User settings').toString(),
      type: ActionMenuType.Header,
    });

    accountActionMenuItems.push({
      label: this.$it('global.logout', 'Logout').toString(),
      action: this.logout,
    });

    return accountActionMenuItems;
  }

  private requestDemoForUser() {
    (this.$refs.requestDemoForUserModal as RequestDemoForUserModal).open();
  }

  private help() {
    (this.$refs.helpModal as UserHelpModal).launch();
  }

  private switchEntity(entity: Account_account_canSwitchToEntities) {
    this.switchTo = entity.name;

    this.$apollo.mutate({
      mutation: require('@/graphql/mutations/SwitchEntity.gql'),
      variables: {
        entityId: entity.id,
      },
    }).then(async () => {
      window.location.href = process.env.VUE_APP_DOMAIN as string;

      this.switchTo = null;
    });
  }

  /**
   * Toggle the menu
   */
  private toggle() {
    this.open = !this.open;
    localStorage.setItem('aside', (this.open) ? 'open' : 'closed');
    this.$emit('aside-changed', this.open);
  }

  /**
   * Logout the user
   */
  private logout() {
    this.$store.dispatch('logout', this.$route.fullPath);
  }

  /**
   * Calculate the avatar URL
   * @param email
   * @return string
   */
  private avatar(email: string) {
    return `https://www.gravatar.com/avatar/${md5(email)}?s=64`;
  }
}
