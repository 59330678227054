import _Vue, {PluginObject} from 'vue';
import {AddressInput, BranchInput, ContactInput, InspectionInput} from '@/types/intrador';
import InspectionStatus from '@/plugins/inspection/InspectionStatus';

const Inspection: PluginObject<any> = {
  install: (vue: typeof _Vue) => {


    function generateInput(data: any): InspectionInput {
      const inspection: InspectionInput = {
        inspectionType: {
          id: data.inspectionType!,
        },

        plannedAt: (data.plannedAt) ? data.plannedAt.toISOString() : null,
        dueAt: (data.dueAt) ? data.dueAt.toISOString() : null,

        asset: {
          group: data.group ? { id: data.group! } : null,
          make: data.make,
          type: data.type,
          yearOfManufacture: data.year,
          serialNumber: data.serialNumber,
          licensePlate: data.licensePlate,
        },

        address: (data.address) ? resolveAddressInput(data.address) : null,
        contact: (data.contact) ? resolveContactInput(data.contact) : null,

        remark: data.remark,
        externalId: data.externalId,
      };

      if (vue.prototype.$validateEmail(data.inspector!)) {
        inspection.inspectorEmail = data.inspector.trim().toLowerCase();
      } else {
        inspection.inspector = {id: data.inspector!};
      }

      return inspection;
    }

    function resolveAddressInput(data: any): BranchInput | null {
      if (data === null) {
        return null;
      }

      const input: BranchInput = {
        id: data.id,
        externalId: data.externalId,
        placeId: data.placeId,

        name: data.name,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        zipCode: data.zipCode,
        state: data.state,
        country: data.country,

        contactName: data.contactName,
        email: data.email,
        phone: data.phone,

        remark: data.remark,

        latitude: data.latitude,
        longitude: data.longitude,
      };

      if ('auditFrequency' in data) {
        input.auditFrequency = data.auditFrequency;
      }
      if ('nextAudit' in data) {
        input.nextAudit = data.nextAudit;
      }
      if ('trustedAuditFrequency' in data) {
        input.trustedAuditFrequency = data.trustedAuditFrequency;
      }
      if ('nextTrustedAudit' in data) {
        input.nextTrustedAudit = data.nextTrustedAudit;
      }
      if ('accountManagerId' in data) {
        input.accountManagerId = data.accountManagerId;
      }
      if ('accountManager' in data) {
        input.accountManagerId = data.accountManager;
      }

      if ('dealer' in data) {
        input.dealer = data.dealer;
      }

      return input;
    }

    function resolveContactInput(data: any): ContactInput | null {
      if (data === null) {
        return null;
      }

      return {
        id: data.id,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        mobile: data.mobile,
      };
    }

    vue.prototype.$inspection = {
      status: InspectionStatus,
      generateInput,
      resolveAddressInput,
      resolveContactInput,
    };
  },
};

export default Inspection;
