import { render, staticRenderFns } from "./RecaptureAICheck.vue?vue&type=template&id=0a10e10e&scoped=true&"
import script from "./RecaptureAICheck.vue?vue&type=script&lang=ts&"
export * from "./RecaptureAICheck.vue?vue&type=script&lang=ts&"
import style0 from "./RecaptureAICheck.vue?vue&type=style&index=0&id=0a10e10e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a10e10e",
  null
  
)

export default component.exports