import {Module, VuexModule, Mutation, Action, MutationAction} from 'vuex-module-decorators';

export interface HeaderMenuItem {
  label: string;
  class?: string;
  icon?: string;
  disabled?: boolean;
  action: () => void;
}

@Module
export default class Header extends VuexModule {
  private HeaderTitle: string|null = null;
  private HeaderMenuItems: HeaderMenuItem[] = [];

  get headerTitle() {
    return this.HeaderTitle;
  }

  get headerMenuItems() {
    return this.HeaderMenuItems;
  }

  @Action({commit: '_setHeaderTitle'})
  private setHeaderTitle(title: string|null) {
    return title;
  }

  @Action({commit: '_setHeaderMenuItems'})
  private setHeaderMenuItems(items: HeaderMenuItem[]) {
    return items;
  }

  @Mutation
  private _setHeaderTitle(title: string|null) {
    this.HeaderTitle = title;
  }

  @Mutation
  private _setHeaderMenuItems(items: HeaderMenuItem[]) {
    this.HeaderMenuItems = items;
  }
}

