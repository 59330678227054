import i18n from '@/store/i18n';

export interface Country {
  ccode: string;
  country_full_name: string;
  show?: boolean | null;
}



interface CountriesDictionary<T> {
  [Key: string]: T;
}

export const countriesTranslation = {
  AF: i18n.it('countries.AF', 'Afghanistan'),
  AL: i18n.it('countries.AL', 'Albania'),
  DZ: i18n.it('countries.DZ', 'Algeria'),
  AS: i18n.it('countries.AS', 'American Samoa'),
  AD: i18n.it('countries.AD', 'Andorra'),
  AO: i18n.it('countries.AO', 'Angola'),
  AI: i18n.it('countries.AI', 'Anguilla'),
  AQ: i18n.it('countries.AQ', 'Antarctica'),
  AG: i18n.it('countries.AG', 'Antigua and Barbuda'),
  AR: i18n.it('countries.AR', 'Argentina'),
  AM: i18n.it('countries.AM', 'Armenia'),
  AW: i18n.it('countries.AW', 'Aruba'),
  AU: i18n.it('countries.AU', 'Australia'),
  AT: i18n.it('countries.AT', 'Austria'),
  AZ: i18n.it('countries.AZ', 'Azerbaijan'),
  BS: i18n.it('countries.BS', 'Bahamas'),
  BH: i18n.it('countries.BH', 'Bahrain'),
  BD: i18n.it('countries.BD', 'Bangladesh'),
  BB: i18n.it('countries.BB', 'Barbados'),
  BY: i18n.it('countries.BY', 'Belarus'),
  BE: i18n.it('countries.BE', 'Belgium'),
  BZ: i18n.it('countries.BZ', 'Belize'),
  BJ: i18n.it('countries.BJ', 'Benin'),
  BM: i18n.it('countries.BM', 'Bermuda'),
  BT: i18n.it('countries.BT', 'Bhutan'),
  BO: i18n.it('countries.BO', 'Bolivia'),
  BA: i18n.it('countries.BA', 'Bosnia and Herzegovina'),
  BW: i18n.it('countries.BW', 'Botswana'),
  BV: i18n.it('countries.BV', 'Bouvet Island'),
  BR: i18n.it('countries.BR', 'Brazil'),
  IO: i18n.it('countries.IO', 'British Indian Ocean Territory'),
  BN: i18n.it('countries.BN', 'Brunei Darussalam'),
  BG: i18n.it('countries.BG', 'Bulgaria'),
  BF: i18n.it('countries.BF', 'Burkina Faso'),
  BI: i18n.it('countries.BI', 'Burundi'),
  KH: i18n.it('countries.KH', 'Cambodia'),
  CM: i18n.it('countries.CM', 'Cameroon'),
  CA: i18n.it('countries.CA', 'Canada'),
  CV: i18n.it('countries.CV', 'Cape Verde'),
  KY: i18n.it('countries.KY', 'Cayman Islands'),
  CF: i18n.it('countries.CF', 'Central African Republic'),
  TD: i18n.it('countries.TD', 'Chad'),
  CL: i18n.it('countries.CL', 'Chile'),
  CN: i18n.it('countries.CN', 'China'),
  CX: i18n.it('countries.CX', 'Christmas Island'),
  CC: i18n.it('countries.CC', 'Cocos (Keeling) Islands'),
  CO: i18n.it('countries.CO', 'Colombia'),
  KM: i18n.it('countries.KM', 'Comoros'),
  CG: i18n.it('countries.CG', 'Congo'),
  CD: i18n.it('countries.CD', 'Congo, the Democratic Republic of the'),
  CK: i18n.it('countries.CK', 'Cook Islands'),
  CR: i18n.it('countries.CR', 'Costa Rica'),
  CI: i18n.it('countries.CI', 'Cote D\'Ivoire'),
  HR: i18n.it('countries.HR', 'Croatia'),
  CU: i18n.it('countries.CU', 'Cuba'),
  CY: i18n.it('countries.CY', 'Cyprus'),
  CZ: i18n.it('countries.CZ', 'Czech Republic'),
  DK: i18n.it('countries.DK', 'Denmark'),
  DJ: i18n.it('countries.DJ', 'Djibouti'),
  DM: i18n.it('countries.DM', 'Dominica'),
  DO: i18n.it('countries.DO', 'Dominican Republic'),
  EC: i18n.it('countries.EC', 'Ecuador'),
  EG: i18n.it('countries.EG', 'Egypt'),
  SV: i18n.it('countries.SV', 'El Salvador'),
  GQ: i18n.it('countries.GQ', 'Equatorial Guinea'),
  ER: i18n.it('countries.ER', 'Eritrea'),
  EE: i18n.it('countries.EE', 'Estonia'),
  ET: i18n.it('countries.ET', 'Ethiopia'),
  FK: i18n.it('countries.FK', 'Falkland Islands (Malvinas)'),
  FO: i18n.it('countries.FO', 'Faroe Islands'),
  FJ: i18n.it('countries.FJ', 'Fiji'),
  FI: i18n.it('countries.FI', 'Finland'),
  FR: i18n.it('countries.FR', 'France'),
  GF: i18n.it('countries.GF', 'French Guiana'),
  PF: i18n.it('countries.PF', 'French Polynesia'),
  TF: i18n.it('countries.TF', 'French Southern Territories'),
  GA: i18n.it('countries.GA', 'Gabon'),
  GM: i18n.it('countries.GM', 'Gambia'),
  GE: i18n.it('countries.GE', 'Georgia'),
  DE: i18n.it('countries.DE', 'Germany'),
  GH: i18n.it('countries.GH', 'Ghana'),
  GI: i18n.it('countries.GI', 'Gibraltar'),
  GR: i18n.it('countries.GR', 'Greece'),
  GL: i18n.it('countries.GL', 'Greenland'),
  GD: i18n.it('countries.GD', 'Grenada'),
  GP: i18n.it('countries.GP', 'Guadeloupe'),
  GU: i18n.it('countries.GU', 'Guam'),
  GT: i18n.it('countries.GT', 'Guatemala'),
  GN: i18n.it('countries.GN', 'Guinea'),
  GW: i18n.it('countries.GW', 'Guinea-Bissau'),
  GY: i18n.it('countries.GY', 'Guyana'),
  HT: i18n.it('countries.HT', 'Haiti'),
  HM: i18n.it('countries.HM', 'Heard Island and Mcdonald Islands'),
  VA: i18n.it('countries.VA', 'Holy See (Vatican City State)'),
  HN: i18n.it('countries.HN', 'Honduras'),
  HK: i18n.it('countries.HK', 'Hong Kong'),
  HU: i18n.it('countries.HU', 'Hungary'),
  IS: i18n.it('countries.IS', 'Iceland'),
  IN: i18n.it('countries.IN', 'India'),
  ID: i18n.it('countries.ID', 'Indonesia'),
  IR: i18n.it('countries.IR', 'Iran, Islamic Republic of'),
  IQ: i18n.it('countries.IQ', 'Iraq'),
  IE: i18n.it('countries.IE', 'Ireland'),
  IL: i18n.it('countries.IL', 'Israel'),
  IT: i18n.it('countries.IT', 'Italy'),
  JM: i18n.it('countries.JM', 'Jamaica'),
  JP: i18n.it('countries.JP', 'Japan'),
  JO: i18n.it('countries.JO', 'Jordan'),
  KZ: i18n.it('countries.KZ', 'Kazakhstan'),
  KE: i18n.it('countries.KE', 'Kenya'),
  KI: i18n.it('countries.KI', 'Kiribati'),
  KP: i18n.it('countries.KP', 'North Korea'),
  KR: i18n.it('countries.KR', 'South Korea'),
  KW: i18n.it('countries.KW', 'Kuwait'),
  KG: i18n.it('countries.KG', 'Kyrgyzstan'),
  LA: i18n.it('countries.LA', 'Lao People\'s Democratic Republic'),
  LV: i18n.it('countries.LV', 'Latvia'),
  LB: i18n.it('countries.LB', 'Lebanon'),
  LS: i18n.it('countries.LS', 'Lesotho'),
  LR: i18n.it('countries.LR', 'Liberia'),
  LY: i18n.it('countries.LY', 'Libya'),
  LI: i18n.it('countries.LI', 'Liechtenstein'),
  LT: i18n.it('countries.LT', 'Lithuania'),
  LU: i18n.it('countries.LU', 'Luxembourg'),
  MO: i18n.it('countries.MO', 'Macao'),
  MG: i18n.it('countries.MG', 'Madagascar'),
  MW: i18n.it('countries.MW', 'Malawi'),
  MY: i18n.it('countries.MY', 'Malaysia'),
  MV: i18n.it('countries.MV', 'Maldives'),
  ML: i18n.it('countries.ML', 'Mali'),
  MT: i18n.it('countries.MT', 'Malta'),
  MH: i18n.it('countries.MH', 'Marshall Islands'),
  MQ: i18n.it('countries.MQ', 'Martinique'),
  MR: i18n.it('countries.MR', 'Mauritania'),
  MU: i18n.it('countries.MU', 'Mauritius'),
  YT: i18n.it('countries.YT', 'Mayotte'),
  MX: i18n.it('countries.MX', 'Mexico'),
  FM: i18n.it('countries.FM', 'Micronesia, Federated States of'),
  MD: i18n.it('countries.MD', 'Moldova, Republic of'),
  MC: i18n.it('countries.MC', 'Monaco'),
  MN: i18n.it('countries.MN', 'Mongolia'),
  MS: i18n.it('countries.MS', 'Montserrat'),
  MA: i18n.it('countries.MA', 'Morocco'),
  MZ: i18n.it('countries.MZ', 'Mozambique'),
  MM: i18n.it('countries.MM', 'Myanmar'),
  NA: i18n.it('countries.NA', 'Namibia'),
  NR: i18n.it('countries.NR', 'Nauru'),
  NP: i18n.it('countries.NP', 'Nepal'),
  NL: i18n.it('countries.NL', 'Netherlands'),
  NC: i18n.it('countries.NC', 'New Caledonia'),
  NZ: i18n.it('countries.NZ', 'New Zealand'),
  NI: i18n.it('countries.NI', 'Nicaragua'),
  NE: i18n.it('countries.NE', 'Niger'),
  NG: i18n.it('countries.NG', 'Nigeria'),
  NU: i18n.it('countries.NU', 'Niue'),
  NF: i18n.it('countries.NF', 'Norfolk Island'),
  MK: i18n.it('countries.MK', 'North Macedonia, Republic of'),
  MP: i18n.it('countries.MP', 'Northern Mariana Islands'),
  NO: i18n.it('countries.NO', 'Norway'),
  OM: i18n.it('countries.OM', 'Oman'),
  PK: i18n.it('countries.PK', 'Pakistan'),
  PW: i18n.it('countries.PW', 'Palau'),
  PS: i18n.it('countries.PS', 'Palestinian Territory, Occupied'),
  PA: i18n.it('countries.PA', 'Panama'),
  PG: i18n.it('countries.PG', 'Papua New Guinea'),
  PY: i18n.it('countries.PY', 'Paraguay'),
  PE: i18n.it('countries.PE', 'Peru'),
  PH: i18n.it('countries.PH', 'Philippines'),
  PN: i18n.it('countries.PN', 'Pitcairn'),
  PL: i18n.it('countries.PL', 'Poland'),
  PT: i18n.it('countries.PT', 'Portugal'),
  PR: i18n.it('countries.PR', 'Puerto Rico'),
  QA: i18n.it('countries.QA', 'Qatar'),
  RE: i18n.it('countries.RE', 'Reunion'),
  RO: i18n.it('countries.RO', 'Romania'),
  RU: i18n.it('countries.RU', 'Russia'),
  RW: i18n.it('countries.RW', 'Rwanda'),
  SH: i18n.it('countries.SH', 'Saint Helena'),
  KN: i18n.it('countries.KN', 'Saint Kitts and Nevis'),
  LC: i18n.it('countries.LC', 'Saint Lucia'),
  PM: i18n.it('countries.PM', 'Saint Pierre and Miquelon'),
  VC: i18n.it('countries.VC', 'Saint Vincent and the Grenadines'),
  WS: i18n.it('countries.WS', 'Samoa'),
  SM: i18n.it('countries.SM', 'San Marino'),
  ST: i18n.it('countries.ST', 'Sao Tome and Principe'),
  SA: i18n.it('countries.SA', 'Saudi Arabia'),
  SN: i18n.it('countries.SN', 'Senegal'),
  SC: i18n.it('countries.SC', 'Seychelles'),
  SL: i18n.it('countries.SL', 'Sierra Leone'),
  SG: i18n.it('countries.SG', 'Singapore'),
  SK: i18n.it('countries.SK', 'Slovakia'),
  SI: i18n.it('countries.SI', 'Slovenia'),
  SB: i18n.it('countries.SB', 'Solomon Islands'),
  SO: i18n.it('countries.SO', 'Somalia'),
  ZA: i18n.it('countries.ZA', 'South Africa'),
  GS: i18n.it('countries.GS', 'South Georgia and the South Sandwich Islands'),
  ES: i18n.it('countries.ES', 'Spain'),
  LK: i18n.it('countries.LK', 'Sri Lanka'),
  SD: i18n.it('countries.SD', 'Sudan'),
  SR: i18n.it('countries.SR', 'Suriname'),
  SJ: i18n.it('countries.SJ', 'Svalbard and Jan Mayen'),
  SZ: i18n.it('countries.SZ', 'Eswatini'),
  SE: i18n.it('countries.SE', 'Sweden'),
  CH: i18n.it('countries.CH', 'Switzerland'),
  SY: i18n.it('countries.SY', 'Syrian Arab Republic'),
  TW: i18n.it('countries.TW', 'Taiwan'),
  TJ: i18n.it('countries.TJ', 'Tajikistan'),
  TZ: i18n.it('countries.TZ', 'Tanzania, United Republic of'),
  TH: i18n.it('countries.TH', 'Thailand'),
  TL: i18n.it('countries.TL', 'Timor-Leste'),
  TG: i18n.it('countries.TG', 'Togo'),
  TK: i18n.it('countries.TK', 'Tokelau'),
  TO: i18n.it('countries.TO', 'Tonga'),
  TT: i18n.it('countries.TT', 'Trinidad and Tobago'),
  TN: i18n.it('countries.TN', 'Tunisia'),
  TR: i18n.it('countries.TR', 'Turkey'),
  TM: i18n.it('countries.TM', 'Turkmenistan'),
  TC: i18n.it('countries.TC', 'Turks and Caicos Islands'),
  TV: i18n.it('countries.TV', 'Tuvalu'),
  UG: i18n.it('countries.UG', 'Uganda'),
  UA: i18n.it('countries.UA', 'Ukraine'),
  AE: i18n.it('countries.AE', 'United Arab Emirates'),
  GB: i18n.it('countries.GB', 'United Kingdom'),
  US: i18n.it('countries.US', 'United States of America'),
  UM: i18n.it('countries.UM', 'United States Minor Outlying Islands'),
  UY: i18n.it('countries.UY', 'Uruguay'),
  UZ: i18n.it('countries.UZ', 'Uzbekistan'),
  VU: i18n.it('countries.VU', 'Vanuatu'),
  VE: i18n.it('countries.VE', 'Venezuela'),
  VN: i18n.it('countries.VN', 'Vietnam'),
  VG: i18n.it('countries.VG', 'Virgin Islands, British'),
  VI: i18n.it('countries.VI', 'Virgin Islands, U.S.'),
  WF: i18n.it('countries.WF', 'Wallis and Futuna'),
  EH: i18n.it('countries.EH', 'Western Sahara'),
  YE: i18n.it('countries.YE', 'Yemen'),
  ZM: i18n.it('countries.ZM', 'Zambia'),
  ZW: i18n.it('countries.ZW', 'Zimbabwe'),
  AX: i18n.it('countries.AX', 'Åland Islands'),
  BQ: i18n.it('countries.BQ', 'Bonaire, Sint Eustatius and Saba'),
  CW: i18n.it('countries.CW', 'Curaçao'),
  GG: i18n.it('countries.GG', 'Guernsey'),
  IM: i18n.it('countries.IM', 'Isle of Man'),
  JE: i18n.it('countries.JE', 'Jersey'),
  ME: i18n.it('countries.ME', 'Montenegro'),
  BL: i18n.it('countries.BL', 'Saint Barthélemy'),
  MF: i18n.it('countries.MF', 'Saint Martin (French part)'),
  RS: i18n.it('countries.RS', 'Serbia'),
  SX: i18n.it('countries.SX', 'Sint Maarten (Dutch part)'),
  SS: i18n.it('countries.SS', 'South Sudan'),
  XK: i18n.it('countries.XK', 'Kosovo'),
} as CountriesDictionary<string>;


export default function getCountries() {

  const countriesList: Country[] = [];

  if (countriesTranslation) {

    for (const ccode of Object.keys(countriesTranslation)) {

      let countryFullName = '';
      countryFullName = countriesTranslation[ccode];

      const country = {
        ccode: ccode as string,
        country_full_name: countryFullName as string,
        show: true,
      } as Country;

      countriesList.push(country);
    }
  }
  return countriesList;
}




