






































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class SerialNumberCheck extends Vue {
  @Prop(Object) protected audit!: any;

  private top = true;
  private error: string | null = null;

  private recaptureAICorrect() {
    this.save('yes');
  }

  private recaptureAIInCorrect() {
    this.save('no');
  }


  get pictureInPictureAnomalyQuestion() {
    return this.$it('inspection.check.questions.ispictureinpictureanomaly', 'Is this really an anomaly?');
  }

  private async save(questionAnswer: any) {
    await this.$apollo.mutate({
      mutation: require('@/graphql/mutations/PictureInPictureChecked.graphql'),
      variables: {
        id: this.audit.id,
        question: this.pictureInPictureAnomalyQuestion,
        question_answer: questionAnswer,
      },
    }).then((success) => {
      if (!success) {
        this.error = (this.$it('global.error', 'Something went wrong, please try again') as string);
      }
    }).catch((error) => {
      this.error = error.message;
    });

    this.$emit('saved');
  }

}
