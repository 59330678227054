import _Vue, {PluginObject, VNode} from 'vue';
import {DirectiveBinding} from 'vue/types/options';
import {Store} from 'vuex';

interface Nodes {
  [key: string]: HTMLElement;
}

const Permissions: PluginObject<any> = {
  install: (vue: typeof _Vue, store: Store<any>) => {
    const permissionNodes: Nodes = {};

    function commentNode(el: HTMLElement, vnode: VNode) {
      const key = (vnode.key)
        ? vnode.key
        : Object.keys(permissionNodes).length + 1;
      permissionNodes[key] = el;

      // replace HTMLElement with comment node
      const comment = document.createComment(' ');
      Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
      });

      vnode.elm = comment;
      vnode.isComment = true;
      vnode.key = key;

      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
    }

    function restoreNode(el: HTMLElement, vnode: VNode) {
      if (vnode.key && vnode.key in permissionNodes) {
        const elm = permissionNodes[vnode.key];

        vnode.elm = elm;
        vnode.isComment = false;

        if (el.parentNode) {
          el.parentNode.replaceChild(elm, el);
        }
      }
    }

    vue.directive(
      'can',
      (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
        const value: string | string[] = binding.value;

        if (value) {
          if (store.getters.can(value)) {
            restoreNode(el, vnode);
          } else {
            commentNode(el, vnode);
          }
        }
      },
    );

    vue.prototype.$can = (value: string | string[] | undefined) => {
      if (value === undefined) {
        return true;
      }

      return store.getters.can(value);
    };
  },
};

export default Permissions;
