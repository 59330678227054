export interface MetaObject {
  key: string;
  value: string;
}

function getMeta(data: any, key: string) {
  if (data && 'meta' in data && typeof data.meta === 'object') {
    const meta: MetaObject = data.meta.find((item: MetaObject) => item.key === key);

    if (meta) {
      return meta.value;
    }
  }

  return null;
}

export default getMeta;

export function metaExists(data: any, key: string) {
  return getMeta(data, key) !== null;
}
