import _Vue, {PluginObject} from 'vue';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Email: PluginObject<any> = {
  install: (vue: typeof _Vue) => {
    vue.prototype.$validateEmail = (email: any) => {
      if (typeof email !== 'string') {
        return false;
      }

      return re.test(email.trim().toLowerCase());
    };
  },
};

export default Email;
