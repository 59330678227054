







import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  SearchUserHelpCategories_searchUserHelp_UserHelpVideo,
  UserHelpCategory_userHelpCategory_videos,
} from '@/types/intrador';

type Video = SearchUserHelpCategories_searchUserHelp_UserHelpVideo | UserHelpCategory_userHelpCategory_videos;

@Component
export default class UserHelpVideoCard extends Vue {
  @Prop(Object) protected video!: Video;
  @Prop(Function) protected click!: (video: Video) => void;
}
