






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ErrorAlert extends Vue {
  @Prop({ type: [Error, String] }) private error!: Error | string;

  private get message() {
    if (typeof this.error === 'string') {
      return this.error;
    }

    if (this.error.message.indexOf('GraphQL error:') >= 0) {
      return this.error.message.substr(14);
    }

    return this.error.message;
  }
}
