import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache , IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { authLink } from './authLink';
import { PureQueryOptions } from 'apollo-client/core/types';
import introspectionQueryResultData from './fragmentTypes.json';

// Install the vue plugin
Vue.use(VueApollo);

// Http endpoint
const inspectionGraphqlEndpoint =
  process.env.VUE_APP_INSPECTION_GRAPHQL_ENDPOINT || process.env.VUE_APP_GRAPHQL_ENDPOINT || '';
const auctionGraphqlEndpoint = process.env.VUE_APP_AUCTION_GRAPHQL_ENDPOINT || '';
const translationEndpoint = process.env.VUE_APP_TRANSLATION_ENDPOINT || '';

export const defaultRefetchQueries: PureQueryOptions[] = [];

export function addDefaultRefetchQuery(refetchQuery: PureQueryOptions) {
  const exists = defaultRefetchQueries.some((query) => {
    return query.query === refetchQuery.query;
  });

  if (!exists) {
    defaultRefetchQueries.push(refetchQuery);
  }
}

function createClient(uri: string, storage: InMemoryCache) {
  const httpLink = ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    createUploadLink({ uri }),
    new BatchHttpLink({ uri }),
  );

  const link = [authLink, httpLink];

  return new ApolloClient({
    link: ApolloLink.from(link),
    cache: storage,
    // resolvers: {
    //   Mutation: {
    //     writeMetaDict: (_, variables, { cache }) => {
    //       cache.modify({
    //         id: cache.identify({
    //           __typename: 'InspectionAsset',
    //           id: variables.id,
    //         }),
    //         fields: {
    //           metaDict: () => variables.metaDict,
    //         },
    //       });
    //
    //       return null;
    //     },
    //   },
    // },
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
      mutate: {
        refetchQueries: () => defaultRefetchQueries,
      },
    },
  });
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const memoryCache = new InMemoryCache({
  fragmentMatcher,
  // This should be used when updating to version 3.x of apollo
  // typePolicies: {
  //   InspectionAsset: {
  //     fields: {
  //       metaDict: {
  //         read(_, { variables }) {
  //           return null;
  //         }
  //       }
  //     }
  //   }
  // }
});

export const graphqlClient = createClient(inspectionGraphqlEndpoint, memoryCache);
export const auctionGraphqlClient = createClient(auctionGraphqlEndpoint, memoryCache);
export const translationClient = createClient(translationEndpoint, memoryCache);

// Call this in the Vue app file
export function createProvider() {
  // Create vue apollo provider
  return new VueApollo({
    clients: {
      graphqlClient,
      auctionGraphqlClient,
      translationClient,
    },
    defaultClient: graphqlClient,
  });
}

