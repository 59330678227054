












































import {Component, Prop, Vue} from 'vue-property-decorator';
import fuzzysearch from 'fuzzysearch';

export enum ActionMenuDirection {
  Top,
  Right,
  Bottom,
  Left,
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
}

export enum ActionMenuType {
  Header,
  Button,
}

export interface ActionMenuItem {
  label: string;
  type?: ActionMenuType;
  icon?: string;
  action?: () => void;
  disabled?: boolean;
  pinned?: boolean;
}

@Component({})
export default class ActionMenu extends Vue {
  @Prop({type: Array, default: () => []}) protected items!: ActionMenuItem[];
  @Prop(String) protected buttonClasses!: string;
  @Prop({type: Number, default: ActionMenuDirection.Bottom}) protected direction!: ActionMenuDirection;
  @Prop({type: Boolean, default: false}) protected disabled!: boolean;
  @Prop({type: Boolean, default: false}) protected searchable!: boolean;
  @Prop({type: Boolean, default: false}) protected variableHeight!: boolean;

  private open: boolean = false;
  private top: number | null = null;
  private bottom: number | null = null;
  private left: number | null = null;
  private right: number | null = null;
  private height: number | null = null;
  private query: string | null = null;

  private get classes() {
    return {
      'menu-top': this.direction === ActionMenuDirection.Top,
      'menu-right': this.direction === ActionMenuDirection.Right,
      'menu-bottom': this.direction === ActionMenuDirection.Bottom,
      'menu-left': this.direction === ActionMenuDirection.Left,
      'menu-top-left': this.direction === ActionMenuDirection.TopLeft,
      'menu-top-right': this.direction === ActionMenuDirection.TopRight,
      'menu-bottom-left': this.direction === ActionMenuDirection.BottomLeft,
      'menu-bottom-right': this.direction === ActionMenuDirection.BottomRight,
    };
  }

  private get menuStyle() {
    const style: any = {};

    if (this.top !== null) {
      style.top = this.top + 'px';
    }
    if (this.bottom !== null) {
      style.bottom = this.bottom + 'px';
    }
    if (this.left !== null) {
      style.left = this.left + 'px';
    }
    if (this.right !== null) {
      style.right = this.right + 'px';
    }
    if (!this.variableHeight && this.height !== null) {
      style.height = this.height + 'px';
    }

    return style;
  }

  private get pinned(): ActionMenuItem[] {
    return this.items.filter((i) => i.pinned);
  }

  private get visible(): ActionMenuItem[] {
    // Not pinned items
    const items = this.items.filter((i) => !i.pinned);

    // Do we have a query?
    const query = this.query && this.query.length > 0 ? this.query : null;
    if (query === null) {
      return items;
    }

    return items.filter((i) => i.type === ActionMenuType.Header
      || fuzzysearch(query.toLowerCase(), i.label.toLowerCase()));
  }

  public close() {
    this.open = false;
  }

  private position(event: Event) {
    const padding = 6;
    const target = (this.$refs.actionToggle as HTMLElement);
    const buttonTop = target.offsetTop;
    const buttonLeft = target.offsetLeft;
    const buttonWidth = target.offsetWidth;
    const buttonHeight = target.offsetHeight;
    const menuWidth = (this.$refs.actionMenu as HTMLElement).offsetWidth;
    const menuHeight = (this.$refs.actionMenu as HTMLElement).offsetHeight;

    this.top = null;
    this.bottom = null;
    this.left = null;
    this.right = null;
    this.height = null;

    switch (this.direction) {
      case ActionMenuDirection.Top:
        this.top = buttonTop - menuHeight - padding;
        this.left = buttonLeft + (buttonWidth / 2) - (menuWidth / 2);
        break;

      case ActionMenuDirection.Right:
        this.top = buttonTop + (buttonHeight / 2) - (menuHeight / 2);
        this.left = buttonLeft + buttonWidth;
        break;

      case ActionMenuDirection.Bottom:
        this.top = buttonTop + buttonHeight + (padding * 2);
        this.left = buttonLeft + (buttonWidth / 2) - (menuWidth / 2);
        break;

      case ActionMenuDirection.Left:
        this.top = buttonTop + (buttonHeight / 2) - (menuHeight / 2);
        this.left = buttonLeft - menuWidth;
        break;

      case ActionMenuDirection.TopLeft:
        this.top = buttonTop - menuHeight - padding;
        this.left = 0;
        break;

      case ActionMenuDirection.TopRight:
        this.top = buttonTop - menuHeight - padding;
        this.right = 0;
        break;

      case ActionMenuDirection.BottomLeft:
        this.top = buttonTop + buttonHeight + (padding * 2);
        this.left = 0;
        break;

      case ActionMenuDirection.BottomRight:
        this.top = buttonTop + buttonHeight + (padding * 2);
        this.right = 0;
        break;
    }

    this.$nextTick(() => {
      this.height = menuHeight;
    });
  }

  private toggle(event: Event) {
    this.open = !this.open;
    this.$nextTick(() => {
      if (this.open) {
        this.position(event);

        if (this.searchable) {
          (this.$refs.search as HTMLInputElement).focus();
        }
      }
    });
  }

  private click(action: () => void) {
    this.close();

    if (action) {
      action();
    }
  }
}
