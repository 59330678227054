import Vue from 'vue';
import Vuex from 'vuex';

import Authentication from './modules/Authentication';
import Header from './modules/Header';
import Language from './modules/Language';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    Authentication,
    Header,
    Language,
  },
});
