import {RawLocation, Route} from 'vue-router';
import {canProceed} from '@/router/Auth';
import {IntradorRouteConfig, namedRoutes} from '@/router/router';

export function to404(to: Route, next: (to?: RawLocation) => void) {
  const lang = (to.params.lang || localStorage.getItem('lang') || navigator.language).toLowerCase();

  return next({name: '404', params: {lang}, replace: true});
}

async function findHomeRoute(arr: IntradorRouteConfig[], callback: (route: IntradorRouteConfig) => Promise<boolean>):
  Promise<IntradorRouteConfig> {

  const promises = arr.map(callback);
  const results = await Promise.all(promises);
  const index = results.findIndex((result: boolean) => result);
  return arr[index];
}

export async function getHomeRouteName(to: Route|null = null): Promise<string> {
  const homeRoutes = namedRoutes
    .filter((route: IntradorRouteConfig) => typeof route.homeWeight !== 'undefined')
    .sort((a: IntradorRouteConfig, b: IntradorRouteConfig) => a.homeWeight! - b.homeWeight!);

  const homeRoute = await findHomeRoute(homeRoutes, async (route: IntradorRouteConfig) => {
    let permissions: string[] = [];
    if (route.permission) {
      permissions = (typeof route.permission === 'string')
        ? [route.permission]
        : route.permission;
    }
    return await canProceed(permissions, to);
  });

  if (homeRoute) {
    return homeRoute.name!;
  } else {
    return '404';
  }
}

export async function toHome(to: Route, from: Route, next: (to?: RawLocation) => void) {
  const lang = (localStorage.getItem('lang') || navigator.language).toLowerCase();
  const name = await getHomeRouteName(to);

  return next({name, params: {lang}, replace: true});
}

export default function(to: Route, from: Route, next: (to?: RawLocation) => void) {
  if ('lang' in to.params) {
    return to404(to, next);
  }

  return toHome(to, from, next);
}
