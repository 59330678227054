







import {Component, Vue} from 'vue-property-decorator';
import Aside from '@/layouts/back-office/aside/Aside.vue';
import Main from '@/layouts/back-office/main/Main.vue';

@Component({
  components: {
    backOfficeAside: Aside,
    backOfficeMain: Main,
  },
})
export default class BackOffice extends Vue {
}
