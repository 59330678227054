


























import { Component, Mixins } from 'vue-property-decorator';
import UserHelpViewMixin from '@/components/user-help/views/mixins/UserHelpViewMixin';
import UserHelpSearch from '@/components/user-help/views/UserHelpSearch.vue';

@Component({
  components: {
    UserHelpSearch,
  },
})
export default class UserHelpCategories extends Mixins(UserHelpViewMixin) {
  private get helpEmail(): string | undefined {
    return process.env.VUE_APP_HELP_EMAIL_ADDRESS!;
  }
}
