












































import {Component, Vue} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';

@Component({ components: {InputText, Modal, SpinnerButton} })
export default class RequestDemoForUserModal extends Vue {
  private loading: boolean = false;
  private firstName: string = '';
  private lastName: string = '';
  private email: string = '';
  private success: boolean = false;
  private error: string | null = null;

  private emailTestRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  get disabled() {
    return this.firstName.length < 2 || this.lastName.length < 2 || !this.emailTestRegex.test(this.email);
  }

  public open() {
    (this.$refs.modal as Modal).open();
  }

  private reset() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.error = null;
    this.success = false;
    this.loading = false;
  }

  private cancel() {
    (this.$refs.modal as Modal).close();
    this.reset();
  }

  private async save(spinning: (state?: boolean) => boolean) {
    this.loading = spinning();
    await this.$apollo.mutate({
      mutation: require('@/graphql/mutations/RequestDemoForUser.gql'),
      variables: {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
      },
    }).then((success) => {
      if (!success) {
        this.error = (this.$it('global.error', 'Something went wrong, please try again') as string);
      } else {
        this.success = true;
      }
      this.loading = spinning(false);
    }).catch((error) => {
      this.error = error.message;
      this.loading = spinning(false);
    });
  }
}
