







import { Component, Mixins } from 'vue-property-decorator';
import UserHelpView from '@/components/user-help/views/UserHelpView.vue';
import UserHelpViewMixin from '@/components/user-help/views/mixins/UserHelpViewMixin';

@Component({
  components: {
    UserHelpView,
  },
})
export default class UserHelpArticle extends Mixins(UserHelpViewMixin) {
}
