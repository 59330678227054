import '@babel/polyfill';

import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store/store';
import {createProvider} from './store/apollo/apollo';
import {i18n} from './store/i18n';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import slugify from '@/filters/vue/slugify';
import meta from '@/filters/vue/meta';
import dateTime from '@/filters/vue/dateTime';
import language from '@/filters/vue/language';
import countryName from '@/filters/vue/countryName';
import Meta from 'vue-meta';
import VCalendar from 'v-calendar';
import InfiniteLoading from 'vue-infinite-loading';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueGoogleCharts from 'vue-google-charts';
import DatePlugin from '@/plugins/Date';
import Email from '@/plugins/Email';
import Http from '@/plugins/Http';
import Permissions from '@/plugins/Permissions';
import Inspection from '@/plugins/inspection/Inspection';
import Countries from '@/plugins/Countries';
import copyDirective from '@/directive/Copy';

import 'intersection-observer';
import Snapshot from '@/plugins/snapshot/Snapshot';
import vuetify from '@/plugins/vuetify';

if (process.env.VUE_APP_SENTRY_DSN && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: `${process.env.NODE_ENV}-${process.env.BITBUCKET_BUILD_NUMBER || process.env.VUE_APP_BUILD_NUMBER}-${process.env.BITBUCKET_COMMIT || process.env.VUE_APP_COMMIT_HASH}`,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        tracing: true,
      }),
    ],
  });
}

Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VCalendar);
Vue.use(InfiniteLoading);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'drawing,places',
  },
  installComponents: true,
});

Vue.use(VueGoogleCharts);

Vue.use(DatePlugin);
Vue.use(Email);
Vue.use(Http);
Vue.use(Permissions, store);
Vue.use(Inspection);
Vue.use(Snapshot);
Vue.use(Countries);

Vue.directive('copy', copyDirective);

Vue.filter('dateTime', dateTime);
Vue.filter('meta', meta);
Vue.filter('slugify', slugify);
Vue.filter('language', language);
Vue.filter('countryName', countryName);

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
