



















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  SearchUserHelpCategories_searchUserHelp,
  UserHelpArticleVariables,
  UserHelpCategoryVariables,
  UserHelpVideoVariables,
} from '@/types/intrador';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import UserHelpArticleCard from '@/components/user-help/views/cards/UserHelpArticleCard.vue';
import UserHelpFAQCard from '@/components/user-help/views/cards/UserHelpFAQCard.vue';
import UserHelpVideoCard from '@/components/user-help/views/cards/UserHelpVideoCard.vue';
import UserHelpView from '@/components/user-help/views/UserHelpView.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    InputText,
    UserHelpArticleCard,
    UserHelpFAQCard,
    UserHelpVideoCard,
    UserHelpView,
    Spinner,
  },
})
export default class UserHelpCategories extends Vue {
  @Prop(Object) protected query!: any;
  @Prop(Object) protected variables!: UserHelpCategoryVariables | UserHelpArticleVariables | UserHelpVideoVariables;
  @Prop({ type: Object, default: () => ({}) }) protected searchVariables!: object; /* TODO: Add Type */
  @Prop(String) protected dataKey!: string;
  @Prop(Function) protected open!: (id: string, type: string) => void;
  @Prop(Function) protected back?: void;
  @Prop(Function) protected close?: void;

  private results: SearchUserHelpCategories_searchUserHelp[] = [];
  private searchQuery: string | null = null;
  private timer: number | null = null;
  private openFAQ: string | null = null;
  private loading = false;
  private focussed = false;

  /**
   * Whether this modal is in search mode or not
   */
  private get searching() {
    return (this.searchQuery && this.searchQuery.length > 1);
  }

  /**
   * List the articles in from the search results
   */
  private get resultArticles() {
    return this.results.filter((r) => r.__typename === 'UserHelpArticle');
  }

  /**
   * List the videos in from the search results
   */
  private get resultVideos() {
    return this.results.filter((r) => r.__typename === 'UserHelpVideo');
  }

  /**
   * List the FAQs in from the search results
   */
  private get resultFAQs() {
    return this.results.filter((r) => r.__typename === 'UserHelpFAQ');
  }

  /**
   * The title when searching
   */
  private get title() {
    if (this.focussed || this.searching) {
      return this.$it('global.search', 'Search');
    }

    return null;
  }

  /**
   * The cancel button when searching
   */
  private get cancel() {
    if (this.focussed || this.searching) {
      return () => {
        this.searchQuery = null;
      };
    }

    return this.back;
  }

  /**
   * When search input changes
   *
   * @private
   */
  private change() {
    // Clear the timer throttle
    if (this.timer) {
      window.clearTimeout(this.timer);
    }

    // Are we searching at all?
    if (!this.searching) {
      return;
    }

    // Activate loading state
    this.loading = true;

    // Throttle the search
    this.timer = window.setTimeout(() => {
      this.search(this.searchQuery!.trim());
    }, 500);
  }

  /**
   * Search API for user help resources
   *
   * @param query The query string to search for
   *
   * @private
   */
  private async search(query: string) {
    // Search on API
    const { data } = await this.$apollo.query({
      query: require('@/graphql/queries/user-help/SearchUserHelp.gql'),
      fetchPolicy: 'no-cache',
      variables: {
        query,
        ...this.searchVariables,
      },
    });

    if (data && data.searchUserHelp) {
      this.results = data.searchUserHelp;
    }

    this.loading = false;
  }
}
